@import "../../../../styles/shared.scss";

.objectives {
  &__container-title {
    padding: 100px 10px 10px 10px;
    @media (min-width: $breakpoint-tablet) {
      padding: 100px 80px 10px 80px;
    }
    &-title {
      font-family: $font-primary;
      color: $color-secondary-1;
      font-size: 35px;
      line-height: 42px;
      font-weight: 300;
      text-align: center;
      letter-spacing: 1px;
      @media (min-width: $breakpoint-tablet) {
        font-size: 60px;
        line-height: 72px;
      }
    }
    &-text {
      font-family: $font-secondary;
      color: $color-secondary-1;
      font-size: 18px;
      line-height: 30px;
      font-weight: 400;
      text-align: center;
    }
  }
  &__container {
    display: none;

    @media (min-width: $breakpoint-tablet) {
      display: inherit;
      display: flex;
      flex-direction: row;
      margin: 20px;
    }
    &-objectives {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid $color-secondary-4;
      border-radius: 10px;
      background-color: $color-secondary-6;
      margin: 10px;
      padding: 40px;
      &-title {
        font-family: $font-primary;
        color: black;
        font-size: 22px;
        line-height: 29px;
        font-weight: 600;
        text-align: center;
        padding-top: 30px;
        padding-bottom: 20px;
      }
      &-text {
        font-family: $font-secondary;
        color: $color-tertiary-8;
        font-size: 16px;
        line-height: 28px;
        font-weight: 400;
        text-align: center;
      }
    }
  }
  &__container-mobile {
    display: inherit;
    margin: 20px;
    @media (min-width: $breakpoint-tablet) {
      display: none;
    }
    &-objectives {
      display: inline-flex !important;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border: 1px solid #CACACA;
      border-radius: 10px;
      background-color: #F7F7F7;
      padding: 40px;
      min-height: 450px;

      &-title {
        font-family: $font-primary;
        color: black;
        font-size: 22px;
        line-height: 29px;
        font-weight: 600;
        text-align: center;
        padding-top: 30px;
        padding-bottom: 20px;
      }
      &-text {
        font-family: $font-secondary;
        color: $color-tertiary-8;
        font-size: 16px;
        line-height: 28px;
        font-weight: 400;
        text-align: center;
      }
    }
  }

  &__container-carrousel-item {
    .slick-slide > div {
      margin: 0 10px;
    }
  }
}
