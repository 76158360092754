@import "./../../../styles/shared.scss";

.modal-genres {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__title {
    color: $color-secondary-2;
    font-size: 22px;
    line-height: 29px;
    font-family: $font-primary;
    font-weight: 400;
  }
  &__author {
    color: $color-secondary-2;
    font-size: 22px;
    line-height: 29px;
    font-family: $font-primary;
    font-weight: 400;
    margin-bottom: 32px;
  }
  &__container-genres {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    &-genre {
      display: flex;
      box-sizing: content-box;
      align-items: center;
      justify-content: center;
      border-radius: 32px;
      margin-left: 8px;
      padding-top: 6px;
      padding-bottom: 6px;
      padding-left: 20px;
      padding-right: 20px;
      border: 1px solid $color-secondary-3;
      color: $color-secondary-3;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      font-family: $font-secondary;
      width: 54px;
      height: 20px;
    }
  }

  &__button{
    border-radius: 32px;
    padding: 10px 30px 10px 30px;
    color:$color-secondary-7;
    background-color:$color-principal;
    border: none;
    width: 127;
    height: 32;
  }

  .genres {
    @media (max-width: $breakpoint-tablet) {
      width: 100%;
    }
  }
}
