@import "../../styles/shared.scss";

.landing-form {
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  align-self: stretch;

  &__container {
    border-radius: 10px;
    background: #F7F7F7;
    padding: 32px 0 32px 32px;
    justify-content: space-between;
    grid-column: 2 / 3;
    display: flex;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
    color: #656565;
    font-family: $font-primary;

    label {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.84px;
      text-transform: uppercase;
      display: block;
    }

    input, textarea {
      color: #979797;
      font-family: $font-secondary;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    textarea {
      background: #F7F7F7;
      border: 1px solid #656565;
      margin-top: 8px;
      padding: 10px 24px;
      border-radius: 10px;
      width: 100%;
    }

    textarea:focus-visible {
      outline: 0;
    }

    textarea.active {
      border-color: $color-principal;
      color: $color-secondary-1;
    }

    .button {
      align-self: start;
      margin-bottom: 0;
      font-family: $font-secondary;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    gap: 26px;
    max-width: 672px;

    h1 {
      color: #000000;
      font-family: $font-primary;
      font-size: 45px;
      font-weight: 600;
      line-height: 108.5%;
    }

    p {
      margin: 0;
      padding: 0;
      font-family: $font-secondary;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__names {
    display: flex;
    justify-content: stretch;
    gap: 8px;

    div {
      flex-grow: 1;
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .landing-form {
    display: flex;
    flex-direction: column;

    &__container {
      border-radius: 0;
      padding: 80px 16px;

      svg {
        display: none;
      }
    }

    &__wrapper {
      gap: 32px;

      button {
        width: calc(100% - 40px)!important;
        background: #CACACA!important;
        color: white!important;
        border: 0!important;
      }

    }

    &__title {
      gap: 16px;

      h1 {
        font-size: 28px;
        font-weight: 400;
        line-height: normal;
      }
    }

    &__names {
      flex-direction: column;
      gap: 24px;
    }
  }
}
