@import "./../../../styles/shared.scss";


.book {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  height: 528px;
  border-radius: 10px;
  padding: 16px;
  font-family: $font-primary;
  background-color: $color-secondary-6;
  position: relative;
  cursor: pointer;

  @media (max-width: $breakpoint-tablet) {
    margin: 0 .25rem;
  }
  &:hover {
    background-color: $color-secondary-5;
  }

  &__section-header {
    text-align: end;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__section-likes {
    background-color: $color-secondary-7;
    color: $color-secondary-3;
    border-radius: 25px;
    padding: .2rem .5rem .2rem .25rem;
    text-align: end;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: $font-secondary;
    font-size: 12px;
    font-weight: 400;
    gap: .2rem;
    
    span{
      margin-right: 0.3em;
    }
  }

  &__section-download {
    background-color: $color-secondary-7;
    color: $color-secondary-3;
    border-radius: 25px;
    padding: .2rem .5rem .2rem .25rem;
    text-align: end;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: $font-secondary;
    font-size: 12px;
    font-weight: 400;
    gap: .3rem;
  }

  &__section-cover {
    width: 100%;
    max-width: calc(100% - 13rem);
    min-width: 15rem;
    padding-top: calc(max((100% - 13rem), 15rem) * 1.31);
    margin: 0 auto;
    position: relative;
    box-shadow: 0px 0px 10px 4px rgba(126, 126, 126, 0.2);
    @media (max-width: $breakpoint-tablet) {
      margin-top: 1rem;
    }
  }
  &__section-image {
    width: 100%;
    flex: 1;
    &-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &-title {
      font-family: $font-primary;
      color: $color-secondary-2;
      font-size: 22px;
      font-weight: 400;
      line-height: 29px;
      margin-top: 1.5rem;
      margin-bottom: .25rem;
    }
    &-author {
      color: $color-secondary-3;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      margin-bottom: 0px;
    }
  }
  &__section-genres {
    box-sizing: content-box;
    display: flex;
    place-self: flex-start;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: .5rem;

      @media (max-width: $breakpoint-tablet) {
        gap: .25rem;
      }
    }
    &-button {
      padding: 4px 14px;
      border: 1px solid $color-secondary-3;
      border-radius: 25px;
      margin-bottom: 0;
      background-color: transparent;

      &--v3 {
        padding: .25rem 1rem;
      }

      @media (max-width: $breakpoint-tablet) {
        padding: .12rem 1rem;
      }

      img {
        margin: .5rem 0;
      }
    }
    &-price {
      color: $color-secondary-3;
      font-family: $font-secondary;
      font-size: 18px;
      margin-right: 15px;
    }
  }

  &__arrow {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-out;
    opacity: 0;
    height: inherit;
  }

  &:hover > .book__arrow {
    opacity: 1;
    transform: translate(0, -32px);
  }
}
