@import "./../../../styles/shared.scss";

.buy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  width: calc(100% - 1rem);
  position: fixed;
  background-color: $color-secondary-4 ;
  bottom: 24px;
  left: .5rem;
  z-index: 999;
  padding: .5rem .5rem .5rem 1.5rem;

  @media (min-width: $breakpoint-tablet) {
    width: 100%;
    position: sticky;
    bottom: 0;
  }

  &__price {
    font-family: $font-secondary;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: $color-tertiary-1;
    margin-bottom: 0px;
    margin-left: 5px;
  }
  &__button {
    border-radius: 32px;
    padding: 15px 30px 15px 30px;
    color: $color-secondary-7;
    background-color: $color-principal;
    opacity: 100%;
    border: none;
    width: 127;
    height: 32;
  }
}
