@import "../../styles/shared.scss";

.form-container {
  background-color: $color-secondary-6;
  height: 100%;
  padding-top: 8rem;
  padding-bottom: 9rem;
  margin-bottom: -81px;

  @media (max-width: $breakpoint-tablet) {
    background-color: $color-secondary-7;
    padding-top: 6rem;
    padding-bottom: 2rem;
  }

  &__back {
    margin-bottom: 2rem;

    @media (max-width: $breakpoint-tablet) {
      display: none;
    }

    .btn {
      width: unset;
      padding: 0;
      border: 0;
      color: $color-secondary-3;

      .icon {
        path {
          fill: $color-secondary-3;
        }
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.5rem;

    @media (max-width: $breakpoint-tablet) {
      display: none;
    }

    &-mobile {
      display: none;

      @media (max-width: $breakpoint-tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
        position: relative;

        .icon {
          position: absolute;
          right: 1rem;
        }
      }
    }

  }

  &__title {
    color: $color-secondary-2;
    font-family: $font-primary;
    font-size: 2.1875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.1875rem;

    @media (max-width: $breakpoint-tablet) {
      font-family: $font-secondary;
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1.5625rem;
      margin-bottom: 0;
    }
  }

  &__body {
    padding: 2rem;
    background-color: $color-secondary-7;
    border-radius: 0.625rem;

    @media (max-width: $breakpoint-tablet) {
      padding: 1rem;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

    @media (max-width: $breakpoint-tablet) {
      flex-direction: column-reverse;
      align-items: center;
      gap: 1rem
    }
  }

  &__contact {
    color: $color-secondary-3;
    a {
      color: $color-secondary-3;
    }
  }
}
