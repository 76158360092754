@import "./../../../styles/shared.scss";

.book-list {
  display: grid;
  gap: .5rem;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  padding-left: 2px;
  width: 100%;

  @media (min-width: $breakpoint-tablet) {
    padding-right: 8px;
    padding-left: 8px;
  }

  &__container-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 70px;
    &-btn {
      width: 176px;
      height: 56px;
      border-radius: 32px;
      border: 1px solid $color-secondary-1;
      padding: 1rem .5rem;
      background-color: $color-secondary-7;
      font-size: 16px;
    }

    @media (max-width: $breakpoint-tablet) {
      margin-top: 3rem;
      margin-bottom: 0;
    }
  }
}
