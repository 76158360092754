@import "../../../../styles/shared.scss";

.video-home {
  display: none;

  @media (min-width: $breakpoint-tablet) {
    display: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(0, -350px);

    &__container {
      background-color: black;
      width: 80%;
      overflow: hidden;
      box-sizing: content-box;
      border-radius: 10px;
      margin-top: 40px;
      padding: 2px 8px 2px 8px;
      box-shadow: 0px 0px 15px 0px rgba(32, 32, 32, 0.234);
    }

    &__video {
      max-width: 100%;
      max-height: 100%;
      box-sizing: content-box;
      overflow: hidden;
      margin: 6px 0 0 0;
      border-radius: 10px;
    }
  }
}
