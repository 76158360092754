@import "./../../../styles/shared.scss";

.download-book-modal {
  &__title {
    color: #979797;

    font-family: $font-secondary;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 15px;
  }

  &__btn {
    color: #656565;
    font-family: $font-secondary;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    margin-bottom: 40px;
  }
}
