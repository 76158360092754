@import "./../../../styles/shared.scss";

.author-item {
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 2rem;
  cursor: pointer;
  width: calc(100% - .5rem);
  margin: auto;

  &__container-image {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 100%;
    background-color: pink;
    padding-top: 100%;
    overflow: hidden;
    position: relative;
    &-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .avatar {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

  }
  &__container-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // width: 348px;
  }
  &__name-author {
    font-family: $font-primary;
    font-size: 22px;
    font-weight: 400;
    line-height: 25px;
    color: $color-secondary-1;
    margin-top: 15px;
    margin-bottom: 10px;
    text-align: center;

  }
  &__published {
    font-family: $font-secondary;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: $color-secondary-2;
    text-align: center;
  }
}
