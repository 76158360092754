@import "../../styles/shared.scss";

.bundle-review {
  background: white;
  padding: 16px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 8px;

  & > div {
    display: flex;
    gap: 24px;

    & > img {
      border-radius: 50%;
      width: 56px;
      height: 56px;
      object-fit: cover;
      flex-shrink: 0;
    }

    & > div {
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-family: $font-secondary;

      & > h2 {
        color: #323232;
        font-size: 18px;
        font-weight: 600;
        margin: 0;
      }

      & > h3 {
        color: #656565;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: -0.36px;
        margin: 0;
      }
    }

    & > p {
      color: #000;
      font-family: $font-primary;
      font-size: 22px;
      font-weight: 400;
      line-height: 29px;
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .bundle-review {
    width: 75vw;
    align-self: flex-start;
    flex-shrink: 0;

    & > p {
      color: #000;
      font-size: 20px;
      line-height: 27px;
    }
  }
}
