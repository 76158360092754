@import "./../../styles/shared.scss";

.ui-kit{
  margin: 125px 8px 40px 8px;

  h1 {
    font-size: 26px;
    background-color: $color-principal;
    color: $color-secondary-7;
    margin: 40px 5px 24px 5px;
    padding: 8px;
  }
}