@import "./../../styles/shared.scss";

.get-your-title {
  &__container-bg {
    background-image: url(../../../public/images/bg-mobile.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    padding-bottom: 5rem;
    @media (min-width: $breakpoint-tablet) {
      background-image: url(../../../public/images/bg.svg);
      padding-bottom: 10rem;
    }
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__title {
    font-family: $font-primary;
    font-size: 2.1875rem;
    font-weight: 400;
    line-height: normal;
    color: $color-secondary-7;
    padding-top: 10.31rem;
    width: calc(100% - 3rem);
    text-align: center;
    margin: 0 auto 1.5rem;

    span {
      font-style: italic;
    }

    @media (min-width: $breakpoint-tablet) {
      font-size: 5rem;
      line-height: normal;
      padding-top: 10.31rem;
      width: min(47rem, 80%);
    }
  }

  &__subtitle {
    color: $color-secondary-7;
    text-align: center;
    font-family: $font-secondary;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    width: calc(100% - 3rem);
    margin: 0 auto 2rem;

    @media (min-width: $breakpoint-tablet) {
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.875rem;
      width: min(40rem, 80%);
      margin: 0 auto 2rem;
    }
  }

  &__section {
    margin: 5rem auto 0;
    width: calc(100% - 2rem);

    &.visible {
      overflow-x: visible;
    }

    @media (min-width: $breakpoint-tablet) {
      margin: 7.5rem auto 0;
      width: calc(100% - 7.5rem);
    }


    &--darker {
      padding-top: 7.5rem;
      padding-bottom: 5rem;
      background-color: $color-secondary-6;

      .get-your-title__section {
        margin-top: 0;
      }
    }

    &-title {
      font-family: $font-secondary;
      font-size: 1.875rem;
      line-height: 2.3125rem; /* 123.333% */
      letter-spacing: -0.0375rem;
      position: relative;

      @media (min-width: $breakpoint-tablet) {
        font-size: 2.1875rem;
        line-height: 3rem;
        letter-spacing: -0.04375rem;
      }

      &:after {
        content: '';
        display: block;
        width: 2.5rem;
        height: 0.18rem;
        background-color: $color-principal;
        position: absolute;
        left: 0;
        top: -1.5rem;
      }
    }

    &-subtitle {
      color: $color-secondary-2;
      font-family: $font-secondary;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.625rem; /* 162.5% */
      width: min(33rem, 80%);
      margin-top: 1.5rem;
    }
  }

  &__steps {
    margin-top: 4rem;
    margin-bottom: 6rem;
    padding-left: 0;
    overflow-x: visible;

    .slick-track {
      display: flex;
      gap: 1rem;
      &:before, &:after {
        content: none;
      }
    }

    .slick-list {
      overflow: visible;
    }

    @media (min-width: $breakpoint-desktop) {
        margin-top: 6rem;
        margin-bottom: 6rem;
    }

    &-item {
      span {
        display: inline-block;
        color: $color-principal;
        font-family: "Times New Roman";
        font-size: 3rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: relative;
        margin-bottom: .5rem;

        &:after {
          content: '';
          display: block;
          width: 1.1rem;
          height: 0.12rem;
          background-color: $color-principal;
          position: absolute;
          left: 0;
          top: -.75rem;
        }
      }

      h3 {
        color: $color-secondary-1;
        font-family: $font-secondary;
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.01875rem;
        margin-bottom: 1.37rem;
      }

      p {
        color: $color-secondary-2;
        font-family: $font-secondary;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.75rem;
      }
    }
  }

  &__CTA {
    background-color: $color-secondary-1;
    padding: 15rem 0;
    background-image: url(../../../public/images/ISBN-cta.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media (min-height: $breakpoint-tablet) {
      padding: 9rem 0;
    }

    &-title {
      color: $color-secondary-7;
      font-family: $font-primary;
      font-size: 2.1875rem;
      line-height: 2.625rem;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 1.5rem;
      width: calc(100% - 3rem);

      @media (min-width: $breakpoint-tablet) {
        width: min(27rem, 80%);
        font-size: 2.8125rem;
        line-height: 3.25rem;
      }
    }

    &-subtitle {
      color: $color-secondary-7;
      font-family: $font-secondary;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.75rem;
      width: min(27rem, 80%);
      margin-bottom: 2.88rem;
    }

    .btn {
      width: unset;
    }
  }

  &__legal-bases {
    margin-top: 4rem;
    margin-bottom: 7rem;
    padding-left: 0;

    .slick-track {
      display: flex;
      gap: 1rem;
      &:before, &:after {
        content: none;
      }
    }

    .slick-slide, .slick-slide div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
      min-height: 19rem;
    }

    .slick-list {
      overflow: visible;
    }

    &-item {
      display: flex !important;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;

      span {
        display: inline-block;
        color: $color-secondary-4;
        font-family: $font-secondary;
        font-size: 2.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 2rem;
      }

      p {
        flex: 1;
        color: $color-secondary-1;
        font-family: $font-secondary;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5625rem; /* 138.889% */
        margin-bottom: 1.5rem;
        min-height: 12rem;

        @media (min-width: $breakpoint-desktop) {
          min-height: 9rem;
        }
      }

      .btn.btn-simple {
        width: unset;
        background-color: $color-secondary-7;
        height: 2.5rem;
      }
    }
  }
}
