@import "./../../../styles/shared.scss";
.books-author {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  border-top: 1px solid $color-secondary-3;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  @media (min-width: $breakpoint-tablet) {
    flex-direction: row;
    margin-left: 130px;
    margin-right: 130px;
  }
  &__title {
    font-family: $font-primary;
    color: $color-tertiary-1;
    font-size: 22px;
    margin-bottom: .5rem;

    @media (min-width: $breakpoint-tablet) {
      margin-bottom: 0;
      width: 20rem;
    }
  }

  &__books {
    @media (min-width: $breakpoint-tablet) {
      width: calc(100% - 20rem);
    }
  }
}
