@import "../../../../styles/shared.scss";

.how-it-works {
  padding-top: 100px;
  padding-bottom: 30px;
  margin: 0px 16px;
  @media (min-width: $breakpoint-tablet) {
    margin-top: -250px;
    padding: 100px 40px;
  }

  &__container-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 20px;
    @media (min-width: $breakpoint-tablet) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &-title {
      font-family: $font-secondary;
      font-size: 35px;
      font-weight: 600;
      line-height: 48px;
      color: black;
      width: 80%;
      @media (min-width: $breakpoint-tablet) {
        width: 50%;
      }
    }

    &-container-buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      @media (min-width: $breakpoint-tablet) {
        margin-top: 0;
        justify-content: flex-end;
      }
    }

    &-buttons {
      display: flex;
      background-color: $color-secondary-5;
      padding: 10px;
      border-radius: 32px;

      @media (max-width: $breakpoint-tablet) {
        width: 100%;
      }
      @media (min-width: $breakpoint-tablet) {
        grid-template-columns: 1fr 1fr 1fr;
      }

      &-btn {
        margin-right: 10px;
        flex: 1;

        .btn {
          width: 100%;
          white-space: nowrap;
        }
      }
    }
  }

  &__container {
    margin-top: 20px;
    grid-gap: 20px;
    grid-template-columns: 1fr;
    display: none;

    @media (min-width: $breakpoint-tablet) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      display: grid;
    }

    #image-1 {
      order: 1;
    }

    #image-2 {
      order: 3;
      @media (min-width: $breakpoint-tablet) {
        order: 2;
      }
    }

    #image-3 {
      order: 5;
      @media (min-width: $breakpoint-tablet) {
        order: 3;
      }
    }

    #text-1 {
      order: 2;
      @media (min-width: $breakpoint-tablet) {
        order: 4;
      }
    }

    #text-2 {
      order: 4;
      @media (min-width: $breakpoint-tablet) {
        order: 5;
      }
    }

    #text-3 {
      order: 6;
    }

    &-image {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &-number {
      font-family: $font-primary;
      font-size: 50px;
      font-weight: 400;
      line-height: 56px;
      color: $color-principal;
      margin-bottom: 10px;
    }
    &-title {
      font-family: $font-secondary;
      font-size: 35px;
      font-weight: 600;
      line-height: 40px;
      color: $color-secondary-1;
      margin-bottom: 0px;

    }
    &-text {
      font-family: $font-secondary;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      color: $color-secondary-2;

    }
  }

  &__container-carrousel {
    margin-top: 20px;
    display: inherit;

    @media (min-width: $breakpoint-tablet) {
      display: none;
    }


    &-image {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &-number {
      font-family: $font-primary;
      font-size: 50px;
      font-weight: 400;
      line-height: 56px;
      color: $color-principal;
      margin-bottom: 10px;
    }
    &-title {
      font-family: $font-secondary;
      font-size: 35px;
      font-weight: 600;
      line-height: 40px;
      color: $color-secondary-1;
      margin-bottom: 0px;

    }
    &-text {
      font-family: $font-secondary;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      color: $color-secondary-2;
      margin-right: 20px;
    }
  }


  &__border {
    border-top: 3px solid $color-principal;
    width: 10%;
    margin-top: 20px;
    @media (min-width: $breakpoint-tablet) {
    width: 10%;
    }
  }
}
