@import "./../../styles/shared.scss";

.blog-item {
  &__image {
    width: 100%;
    height: 15rem;
    border-radius: .625rem;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__author {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.15rem;
    color: $color-secondary-3;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &__date {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.15rem;
    color: $color-secondary-4;
  }

  &__title {
    font-family: $font-primary;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
    color: $color-secondary-2;
    margin-bottom: 1rem;
  }

  &__readTime {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.015rem;
    color: $color-secondary-3;
  }
}
