@import "../../../styles/shared.scss";

.banner-public {
  margin: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: $breakpoint-tablet) {
    margin: 30px 10px;
  }

  &__container-bg {
    margin: 0;
    background-image: url(../../../../public/images/banner-mobile.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 300px;
    border-radius: 10px;

    @media (min-width: $breakpoint-tablet) {
      margin: 0px 50px;
      background-image: url(../../../../public/images/banner-captacion.png);
      height: 500px;
    }
  }
  &__title {
    font-family: $font-primary;
    font-size: 30px;
    font-weight: 600;
    line-height: 32px;
    color: $color-secondary-7;
    width: 90%;
    padding-left: 30px;
    padding-top: 60px;
    @media (min-width: $breakpoint-tablet) {
      line-height: 56px;
      font-size: 47px;
      width: 55%;
      padding-left: 60px;
      padding-top: 70px;
    }
    @media (min-width: $breakpoint-desktop) {
      padding-top: 130px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    @media (min-width: $breakpoint-tablet) {
      padding-left: 60px;
      padding-top: 40px;
      justify-content: flex-start;
      align-items: flex-start;
    }
    &-one {
      border-radius: 32px;
      border: 1px solid $color-secondary-1;
      background-color: $color-secondary-1;
    }
    &-two {
      margin-left: 6px;
      border-radius: 32px;
      border: 1px solid $color-secondary-1;
      background-color: $color-secondary-1;
      padding-left: 12px;
      padding-right: 12px;
      @media (min-width: $breakpoint-tablet) {
        margin-left: 16px;
      }
    }
  }
}
