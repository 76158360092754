@import "./../../../styles/shared.scss";

.menu {
  background-color: $color-secondary-7;
  border-radius: 10px;
  padding: 40px;
  margin-top: 50px;

  @media (max-width: $breakpoint-tablet) {
    margin-top: 0;
    padding: 1.5rem 1rem;
  }

  &--active {
    color: $color-principal;
    &:hover{
      color: $color-principal;
    }
  }

  &--inactive {
    color: $color-secondary-2;
    &:hover{
      color: $color-secondary-2;
    }
  }

  &__section {
    color: $color-secondary-2;
    font-family: $font-primary;
    font-size: 28px;
    line-height: 34px;
    border-bottom:  1px solid $color-secondary-4;
    margin-bottom: 20px;
    padding-bottom: 20px;
    display: block;
    text-decoration: none;


    &:first-child {
      padding-bottom: 12px;
    }

    &:last-of-type,
    &.no_underline {
      border-bottom: none;
      margin-bottom: 0;
    }

    &-container {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom:  1px solid $color-secondary-4;
    }
  }

  &__sub-section {
    display: block;
    text-decoration: none;
    font-family: $font-primary;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 8px;
  }
}

a.logout {
  color: $color_principal;
  cursor: pointer;
}
