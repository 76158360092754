@import "../../../styles/shared.scss";

.why-container {
  background-color: $color-secondary-6;
  &__reasons {
    margin: 0px;
    @media (min-width: $breakpoint-tablet) {
      margin: 15px;
    }
  }
  padding-bottom: 100px;

  ul {
    li {
      list-style: disc;
    }
  }
}

.why-bookolicos-section {
  padding: 12px;
  margin: 70px 0px;
  display: flex;
  flex-direction: column;
  background-color: $color-secondary-6;
  border-radius: 0 0 0 0%;
  @media (min-width: $breakpoint-tablet) {
    margin: 100px 0px;
    padding: 50px;
  }

  &__section-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__border {
    border-top: 3px solid $color-principal;
    width: 20%;
    padding-bottom: 20px;

    @media (min-width: $breakpoint-tablet) {
      width: 10%;
    }
  }
  &__title {
    color: black;
    font-family: $font-secondary;
    font-size: 35px;
    font-weight: 600;
    line-height: 48px;
    padding-bottom: 20px;
    @media (min-width: $breakpoint-tablet) {
      font-size: 30px;
      line-height: 37px;
      padding-bottom: 20px;
    }
  }

  &__button-faqs {
    display: none;
    @media (min-width: $breakpoint-tablet) {
      display: inherit;
    }
  }

  .faqs-items {
    padding: 0px ;
    margin: 0px !important;

    @media (min-width: $breakpoint-tablet) {
      margin: 100px 0px;
      padding: 50px 0;
    }
  }
}
