@import "./../../../styles/shared.scss";

.book-info {
  background-color: $color-secondary-7;
  border-radius: 10px;
  padding: 0 1rem 1rem;
  ::placeholder {
    color: $color-secondary-3;
  }

  @media (min-width: $breakpoint-tablet) {
    padding: 3.5rem 2.5rem 11rem;
  }

  &__form {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    &-label {
      font-family: $font-primary;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: $color-secondary-2;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      @media (max-width: $breakpoint-tablet) {
        margin-top: 1.5rem;
      }
    }
    &-input {
      outline: none;
      width: 90%;
      border-radius: 32px;
      padding-left: 15px;
      padding-top: 8px;
      padding-bottom: 8px;
      border: none;
      border: 1px solid $color-secondary-2;
      margin-bottom: 10px;

      &::placeholder {
        color: $color-secondary-3
      }
    }
    &-textarea {
      font-family: $font-secondary;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $color-secondary-2;
      border-radius: 10px;
      outline: none;
      border: 1px solid $color-secondary-2;
      margin-top: 10px;
      padding: 8px 10px 8px 15px;
      width: 100%;
      min-height: 150px;
      cursor: pointer;
      overflow: auto;
      box-sizing: border-box;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      resize: none;

      &-info {
        color: $color-secondary-3;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
        margin-top: 1rem;
      }
    }
  }
  &__check {
    &-container-checkbox{
      margin-bottom: 10px;
    }
    &-title {
      margin-top: 30px;
      margin-bottom: 10px;
      font-family: $font-primary;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: $color-secondary-2;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      &-icon{
        margin-left: 5px;
      }
    }
    &-input {
      border-radius: 25px;
      outline: none;
      border: 1px solid $color-secondary-3;
      margin-bottom: 10px;
      margin-top: 10px;
      padding: 10px;
      width: 90%;
      box-sizing: content-box;
      cursor: pointer;
    }
    &-text {
      font-family: $font-secondary;
      color: $color-secondary-3;
      font-size: 12px;
      line-height: 15px;
    }
  }
}
