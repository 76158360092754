.home{
    &__genres{
        padding: 60px;

        @media (max-width: 768px) {
            padding: 60px 0;
            overflow: auto;
            position: relative;
            scroll-snap-align: center;

            .genres {
                gap: 0;
            }
        }
    }
}
