@import "./../../../styles/shared.scss";

.profile-author {
  background-color: $color-secondary-7;
  border-radius: 10px;
  margin-top: 50px;
  padding: 20px 40px 40px 40px;
  margin-bottom: 50px;

  @media (max-width: $breakpoint-tablet) {
    padding: .5rem;
  }

  &__container-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: -50px;
    position: relative;
    .wrapper-cover {
      width: 105%;
      height: 124px;
      overflow: hidden;
      position: relative;
      border-radius: 10px;

      @media (max-width: $breakpoint-tablet) {
        width: 100%;
      }

    }
    &-bgimg {
      position: absolute;
      top: 0;
      width: 100%;
    }
    &-img {
      width: 110px;
      height: 110px;
      border-radius: 50%;
      transform: translate(0, -65px);
      cursor: pointer;
      position: relative;
    }
  }
  &__edit-cover {
    position: absolute;
    top: 0.8em;
    right: 0em;
    background-color: rgba(239, 239, 239, 0.5);
    padding: 3px 8px 5px 8px;
    border-radius: 37px;

    @media (max-width: $breakpoint-tablet) {
      top: .5rem;
      right: .5rem;
    }
  }
  &__title {
    font-family: $font-primary;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: $color-secondary-2;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    padding-top: 30px;
    &:last-of-type {
      margin-bottom: 0px;
      padding-top: 40px;
    }
    &:first-of-type {
      margin-bottom: 0px;
    }
  }
  &__help {
    font-family: $font-secondary;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: $color-secondary-3;
    letter-spacing: 0.07em;
    padding-top: .5rem;
  }
  &__container-input {
    width: 55%;

    @media (max-width: $breakpoint-tablet) {
      width: 100%;
    }
    &-text {
      font-family: $font-secondary;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: $color-secondary-3;
      margin-bottom: 6px;
    }
  }
  &__textarea {
    font-family: $font-secondary;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $color-secondary-2;
    overflow-y: scroll;
    border-radius: 10px;
    outline: none;
    border: 1px solid $color-secondary-3;
    padding: 10px;
    width: 100%;
    box-sizing: content-box;
    min-height: 150px;
    cursor: pointer;
    overflow: auto;
    box-sizing: border-box;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
    &::placeholder {
      color: $color-secondary-4;
    }
  }

  &__container-rrss {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  &__input-container {
    display: flex;
    width: 50%;

    @media (max-width: $breakpoint-tablet) {
      width: 100%;

      .icon {
        width: 40px;
      }
    }
    &-input {
      width: 100%;

      &-tw {
        width: 100%;
        margin-right: 16px;
        @media (max-width: $breakpoint-tablet) {
          margin: 0;
        }
      }
      &-li {
        width: 100%;
        margin-right: 16px;

        @media (max-width: $breakpoint-tablet) {
          margin: 0;
        }
      }
      &-ig {
        width: 100%;
        margin-left: 16px;

        @media (max-width: $breakpoint-tablet) {
          margin: 0;
        }
      }
      &-fb {
        width: 100%;
        margin-left: 16px;

        @media (max-width: $breakpoint-tablet) {
          margin: 0;
        }
      }
    }
  }
}

.buttons {
  width: 100%;
  margin-bottom: 50px;
  &__containers {
    display: flex;
    justify-content: flex-end;

    @media (max-width: $breakpoint-tablet) {
      &--fixed {
        position: fixed;
        bottom: 3rem;
        z-index: 100;
        left: .75rem;
        width: calc(100% - 1.5rem);
      }
    }

    &-btn-cancel {
      margin-right: 20px;
      background-color: $color-tertiary-5;
      border: none;
      outline: none;
      font-family: $font-secondary;
      color: $color-secondary-3;
    }
    &-btn-save {
      margin-right: 20px;
      background-color: $color-secondary-3;
      border: none;
      outline: none;
      font-family: $font-secondary;
      color: $color-secondary-7;
      padding: 18px 24px;
      border-radius: 32px;
    }
  }
}
