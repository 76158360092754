@import "../../../styles/shared.scss";

@keyframes slideInFromLeft {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes hideInToLeft {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.header-public {
  position: absolute;
  display: none;
  top: 10px;
  left: 2%;
  z-index: 999;
  width: 96%;

  @media (min-width: $breakpoint-tablet) {
    display: inherit;
  }

  &__full{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 34px;
    padding-top: 111px;
    background-color: $color-secondary-1;
    z-index: 0;

    &__link{
      font-size: 20px;
      color: $color-secondary-7;
      font-weight: 400;
      text-decoration: none;
      -webkit-transition: color 0.1s; /* For Safari 3.0 to 6.0 */
      transition: color 0.1s;
      white-space: nowrap;

      @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
      }

      @keyframes fadeOut {
        0% { opacity: 1; }
        100% { opacity: 0; }
      }

      span{
        margin-left: -24px;
        transition: 0.5s;
      }

      .icon{ 
        animation: fadeOut 0.1s;
        opacity: 0;
        left: -24px;
      }

      &:hover{
        color: $color-principal;

        span { margin-left: 0; }

        .icon{ 
          animation: fadeIn 0.3s;
          opacity: 1;
        }
      }
    }

    @media (min-width: $breakpoint-desktop) {
      &__link{
        font-size: 24px;
      }
    }

    @media (min-width: $breakpoint-desktop-laptop) {
      &__link{
        font-size: 35px;
      }
    }
  }

  &__general-container {
    background-color: transparent;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    margin: 10px auto;
    border-radius: 32px;
    padding-left: 10px;
    padding-right: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
    &-logo {

      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;
      justify-content: space-between;
    }
  }
  &__img {
    width: 60px;
  }
  &__container {
    &-list {
      text-decoration: none;
      color: $color-secondary-7;
      font-family: $font-secondary;
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
      margin-right: 20px;
      margin-left: 30px;
      cursor: pointer;
      &:hover {
        color: $color-secondary-7;
      }
    }
    &-why {
      text-decoration: none;
      color: $color-secondary-7;
      font-family: $font-secondary;
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
      margin-right: 20px;
      cursor: pointer;
      &:hover {
        color: $color-secondary-7;
      }
    }
    &-who {
      text-decoration: none;
      color: $color-secondary-7;
      font-family: $font-secondary;
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
      margin-right: 20px;
      cursor: pointer;
      &:hover {
        color: $color-secondary-7;
      }
    }
  }
  &__input {
    cursor: pointer;
    outline: none;
    border: none;
    background-color: transparent;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-family: $font-secondary;
    color: $color-secondary-7;
    letter-spacing: 1px;
    margin-left: 10px;
    &::placeholder {
      color: $color-secondary-7 !important;
      opacity: 0.6;
    }
  }
}

.header-public-fixed {
  position: fixed;
  display: none;
  top: 8px;
  left: 2%;
  z-index: 999;
  width: 96%;

  @media (min-width: $breakpoint-tablet) {
    animation: 0.5s ease-out 0s 1 slideInFromLeft;
    display: inherit;
  }

  &__full{
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    border-radius: 34px;
    padding-top: 111px;
    background-color: $color-secondary-7;
    z-index: 0;

    &__link{
      font-size: 20px;
      color: $color-secondary-1;
      font-weight: 400;
      text-decoration: none;
      -webkit-transition: color 0.1s; /* For Safari 3.0 to 6.0 */
      transition: color 0.1s;
      white-space: nowrap;

      @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
      }

      @keyframes fadeOut {
        0% { opacity: 1; }
        100% { opacity: 0; }
      }

      span{
        margin-left: -24px;
        transition: 0.5s;
      }

      .icon{ 
        animation: fadeOut 0.1s;
        opacity: 0;
        left: -24px;
      }

      &:hover{
        color: $color-principal;

        span { margin-left: 0; }

        .icon{ 
          animation: fadeIn 0.3s;
          opacity: 1;
        }
      }
    }

    @media (min-width: $breakpoint-desktop) {
      &__link{
        font-size: 24px;
      }
    }

    @media (min-width: $breakpoint-desktop-laptop) {
      &__link{
        font-size: 35px;
      }
    }
  }


  &__general-container {
    background-color: $color-secondary-7 !important;
    box-shadow: 1px 1px 1px 2px $color-secondary-5;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    margin: 10px auto;
    border-radius: 32px;
    padding: 0 .5rem 0 0.25rem;
    position: relative;
    z-index: 1;

    &-logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;
      justify-content: space-between;
    }
  }
  &__img {
    width: 54px;
    padding-left: 3px;
  }
  &__container {
    &-list {
      text-decoration: none;
      color: $color-secondary-1;
      font-family: $font-secondary;
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
      margin-right: 20px;
      margin-left: 30px;
      cursor: pointer;
      &:hover {
        color: $color-principal;
      }
    }
    &-why {
      text-decoration: none;
      color: $color-secondary-1;
      font-family: $font-secondary;
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
      margin-right: 20px;
      cursor: pointer;
      &:hover {
        color: $color-principal;
      }
    }
    &-who {
      text-decoration: none;
      color: $color-secondary-1;
      font-family: $font-secondary;
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
      margin-right: 20px;
      cursor: pointer;
      &:hover {
        color: $color-principal;
      }
    }
  }
  &__input {
    cursor: pointer;
    outline: none;
    border: none;
    background-color: transparent;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-family: $font-secondary;
    color: $color-secondary-2;
    letter-spacing: 1px;
    margin-left: 10px;
    ::placeholder {
      color: $color-secondary-2;
    }
  }
}
.hide-animation {
  animation: 0.5s ease-out 0s 1 hideInToLeft;
  opacity: 0;
  pointer-events: none;
}

.header-public__bg {
  background-color: rgba(94, 94, 94, 0.412);
  border-radius: 32px;
}

.header-public__margin-top {
  margin-top: 10px;
}
