@import "./../../../styles/shared.scss";

.cover-summary {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-secondary-7;
  padding: 40px;
  border-radius: 10px;

  &__container {
    @media (max-width: $breakpoint-tablet) {
      max-height: 19rem;
    }
    &-img {
      width: 22rem;
      height: 512px;
      border-radius: 10px;
      object-fit: cover;

      @media (max-width: $breakpoint-tablet) {
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
