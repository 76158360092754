@import "./../../styles/shared.scss";

.header-landing {
  padding: 0 4rem;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;

  @media (max-width: $breakpoint-tablet) {
    padding: 1rem .5rem;

    &__button {
      display: none;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0;
    width: 100%;
    margin: 0 auto;

    @media (max-width: $breakpoint-tablet) {
      padding: .5rem;
      background-color: $color-secondary-7;
      border-radius: 4rem;
    }
  }

  &__logo-container {
    width: 3.5rem;
    height: 3.5rem;
    cursor: pointer;
    position: relative;

    @media (max-width: $breakpoint-tablet) {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .header-landing__logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: opacity 0.3s ease-in-out;

    &--white {
      opacity: 1;
    }

    &--orange {
      opacity: 0;
      filter: drop-shadow(0px 0px 1px rgba(255, 255, 255, 0.5));
    }

    @media (max-width: $breakpoint-tablet) {
      &--white {
        opacity: 0;
      }

      &--orange {
        opacity: 1;
        filter: none;
      }
    }
  }

  @media (min-width: $breakpoint-tablet) {
    &--scroll {
      .header-landing__logo--white {
        opacity: 0;
      }
      .header-landing__logo--orange {
        opacity: 1;
      }
    }
  }
}
