@import "../../../styles/shared.scss";

.who-container {
  background-color: $color-secondary-6;
  padding-bottom: 100px;

  &__bookolicos {
    margin: 0px;
    @media (min-width: $breakpoint-tablet) {
      margin: 16px;
    }
  }
}
