@import "./../../../styles/shared.scss";

.arrows {
    display: flex;
    flex-direction: row;
    &__separation{
        margin-right: 20px;
    }

}
