@import "./../../../styles/shared.scss";

.section-images {
  &__container-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin: .5rem;
    overflow: hidden;

    @media (max-width: $breakpoint-tablet) {
      margin: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .wrapper-cover{
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
      border-radius: 10px;

      @media (max-width: $breakpoint-tablet) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      .image-bg {
        max-height: 248px;
        height: 100%;

        @media (max-width: $breakpoint-tablet) {
          height: 248px;
        }

      }
    }

    &-bgimg {
      width: 100%;
      border-radius: 10px;
      height: 100%;
      object-fit: cover;

      @media (max-width: $breakpoint-tablet) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    &-img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      margin-top: -5rem;
      z-index: 2;

    }
  }
  &__container-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
    margin-bottom: 5rem;

    &-name {
      font-family: $font-primary;
      color: $color-tertiary-1;
      font-size: 35px;
      margin-bottom: .5rem;
    }
    &-book {
      font-family: $font-secondary;
      color: $color-tertiary-2;
      font-size: 1rem;
      margin-top: 0px;
    }
  }
}
