@import "./../../../styles/shared.scss";

.author-list {
  overflow-x: hidden;
  //padding: 0 .5rem;

  @media (max-width: $breakpoint-tablet) {
    margin: 0;
    padding: 0;
    //width: calc(100% - 1.5rem);
    overflow: visible;
  }

  &__container-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    @media (max-width: $breakpoint-tablet) {
      padding: 0 .75rem;
    }

  }
  &__title {
    font-family: $font-primary;
    font-size: 2.8rem;
    line-height: 2.5rem;
    color: $color-secondary-2;
    padding-left: 2rem;

    @media (max-width: $breakpoint-tablet) {
      padding: 0;
      font-size: 1.75rem;
      line-height: 1.75rem;
      margin-bottom: 1.5rem;
      font-weight: 400;
      color: $color-secondary-1;
    }
  }
  &__arrows {
    margin-right: 20px;
    text-align: end;
    @media (max-width: $breakpoint-tablet) {
      display: none;
    }
  }
  &__actions {
    display: flex;
    justify-content: center;

    @media (min-width: $breakpoint-tablet) {
      display: none;
    }
  }

  .slick-track {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    margin-left: unset;
    margin-right: unset;

    @media (max-width: $breakpoint-tablet) {
      padding: 0 .5rem;
    }

  }
  @media (max-width: $breakpoint-tablet) {
    .slick-list {
      overflow: visible;
    }
  }
}
