@import "./../../../styles/shared.scss";

.modal-book-detail {
  width: 100%;
  right: 0;
  position: absolute;
  //transform: translated(25%, 0, 0);
  @media (min-width: $breakpoint-tablet) {
    width: 75%;
  }
  .modal-body {
    padding: 0;

    @media (min-width: $breakpoint-tablet) {
      overflow-y: hidden;
    }
  }
}

.modal.fade .modal-dialog.modal-book-detail {
  transform: translate3d(25%, 0, 0);
  @media (max-width: $breakpoint-tablet) {
    transform: translate3d(0, 25%, 0);
  }
}

.modal.show .modal-dialog.modal-book-detail {
  transform: none;
}
