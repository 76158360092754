@import "./../../../styles/shared.scss";

.book-list-genre-public {
  &__title {
    font-family: $font-primary;
    font-size: 28px;
    line-height: 33px;
    font-weight: 400;
    color: $color-secondary-2;
    padding-top: 70px;
    padding-bottom: 25px;
    padding-left: 30px;
    @media (min-width: $breakpoint-tablet) {
      font-size: 45px;
      line-height: 54px;
    }

  }


  &__genres {
    padding: 10px 60px 60px 60px;
  }
  &__footer {
    padding-top: 50px;
  }
}
