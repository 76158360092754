@import "../../../styles/shared.scss";

.authors {
  margin: 10px;

  @media (max-width: $breakpoint-tablet) {
    margin: 0;
  }

  &__genres {
    margin-top: 100px;
    margin-left: 50px;
    margin-right: 50px;
    display: flex;
    align-items: center;

    &-filter{
      color: black;
      font-family: $font-primary;
      font-size: 22px;
      font-weight: 400;
      line-height: 25px;
      padding-top: 10px;
      padding-right: 50px;
    }
  }
  &__button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
}
}
