@import "../../../../styles/shared.scss";

.first-bookolicos {

  padding: 70px 10px;
  display: flex;
  flex-direction: column;
  background-color: $color-secondary-7;

  @media (min-width: $breakpoint-tablet) {
    padding: 100px 60px;
    margin: 70px 0px;
    margin: 70px 0px;
    border-radius: 10px;
  }

  &__border {
    border-top: 3px solid $color-principal;
    width: 15%;
    padding-bottom: 20px;
    @media (min-width: $breakpoint-tablet) {
      width: 10%;
    }
  }
  &__title {
    color: black;
    font-family: $font-secondary;
    font-size: 30px;
    font-weight: 600;
    line-height: 48px;
    padding-bottom: 20px;
    padding-left: 5px;
    width: 75%;
    @media (min-width: $breakpoint-tablet) {
      font-size: 35px;  
      width: 100%;
    }
  }

  &__container-containers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;

    @media (min-width: $breakpoint-tablet) {
      padding-left: 60px;
      padding-right: 60px;
    }
  }

  &__container-monse {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    @media (min-width: $breakpoint-tablet) {
      display: flex;
      flex-direction: row;
    }

    &-section-text {

      padding: 40px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (min-width: $breakpoint-tablet) {
        margin-left: 80px;
        padding: 40px;
      }
    }
    &-title {
      color: $color-secondary-1;
      font-family: $font-secondary;
      font-size: 35px;
      font-weight: 600;
      line-height: 42px;
    }
    &-text {
      color: $color-secondary-1;
      font-family: $font-secondary;
      font-size: 18px;
      font-weight: 300;
      line-height: 30px;
    }
    &-section-image {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px;
      &-img {
        max-width: 323px;
        max-height: 323px;
        @media (min-width: $breakpoint-tablet) {
          max-width: 423px;
          max-height: 423px;
        }
      }
    }
    &-icons-list {
      display: flex;
      flex-direction: row;
      padding-left: 0px;
      &-item {
        margin-right: 10px;
      }
    }
  }

  &__container-nestor {
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: $breakpoint-tablet) {
      display: flex;
      flex-direction: row;
    }
    &-section-text {

      padding: 40px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (min-width: $breakpoint-tablet) {
        margin-right: 80px;
        padding: 40px;
      }
    }
    &-title {
      color: $color-secondary-1;
      font-family: $font-secondary;
      font-size: 35px;
      font-weight: 600;
      line-height: 42px;
    }
    &-text {
      color: $color-secondary-1;
      font-family: $font-secondary;
      font-size: 18px;
      font-weight: 300;
      line-height: 30px;
    }
    &-section-image {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px;
      &-img {
        max-width: 323px;
        max-height: 323px;
        @media (min-width: $breakpoint-tablet) {
          max-width: 423px;
          max-height: 423px;
        }
      }
    }
    &-icons-list {
      display: flex;
      flex-direction: row;
      padding-left: 0px;
      &-item {
        margin-right: 10px;
      }
    }
  }
}
