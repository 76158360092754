@import "./../../../styles/shared.scss";

.book-news-item {
  box-sizing: content-box;
  display: flex;
  flex-direction: column-reverse;
  border-radius: 10px;
  width: 100%;
  font-family: $font-primary;
  background-color: $color-secondary-6;
  position: relative;
  cursor: pointer;

  @media (min-width: $breakpoint-tablet) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media (max-width: $breakpoint-tablet) {
    height: 100%;
  }

  &:hover {
    background-color: $color-secondary-5;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 2rem 1rem 1rem;
    width: 100%;

    @media (max-width: $breakpoint-tablet) {
      padding-top: 0;
    }

    &-title {
      font-family: $font-primary;
      color: $color-secondary-2;
      font-weight: 400;
      font-size: 2rem;
      line-height: 2.5rem;
      margin-top: 10px;
      margin-bottom: 16px;

      @media (min-width: $breakpoint-tablet) {
        font-size: 35px;
        line-height: 41px;
      }

      @media (max-width: $breakpoint-tablet) {
         margin-top: 0;
        margin-bottom: 1rem;
        color: $color-secondary-1;
      }
    }

    &-author {
      color: $color-secondary-2;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1rem;
      margin-bottom: 0;

      @media (min-width: $breakpoint-tablet) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    &-sinopsis {
      color: $color-secondary-2;
      font-weight: 300;
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 0px;
      font-family: $font-secondary;
      display: none;
      margin-top: 20px;

      @media (min-width: $breakpoint-tablet) {
        display: inherit;
      }
    }

    &-genres {
      box-sizing: content-box;
      display: flex;
      place-self: flex-start;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      margin-top: 1.5rem;

      @media (max-width: $breakpoint-tablet) {
        margin-top: 1rem;
      }

      &-container {
        display: flex;
        justify-content: flex-start;
        gap: .5rem;
        align-items: center;
      }

      &-button {
        padding: 6px 14px;
        border: 1px solid $color-secondary-3;
        border-radius: 25px;
        margin-bottom: 0;
        background-color: transparent;

        img {
          margin: .5rem 0;
        }
      }

      &-price {
        color: $color-secondary-3;
        font-family: $font-secondary;
        font-size: 18px;
        margin-right: .5rem;
      }
    }
  }

  &__cover {
    width: 100%;
    height: 26rem;
    position: relative;
    padding: 1rem .5rem;

    @media (min-width: $breakpoint-tablet) {
      height: 32rem;
      max-width: calc(32rem / 1.5);
    }

    &-download {
      background-color: rgba($color-secondary-7, 1);
      color: $color-secondary-3;
      border-radius: 25px;
      margin-top: 0px;
      font-family: $font-secondary;
      font-size: 12px;
      font-weight: 400;
      position: absolute;
      padding: .25rem .5rem .25rem .35rem;
      top: 1.5rem;
      right: 1.5rem;
      transform: none;
      display: inline-block;
      color: $color-secondary-3;

      &-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .3rem;
        color: $color-secondary-3;

        div {
          display: flex;
        }
      }

      @media (max-width: $breakpoint-tablet) {
        background-color: rgba($color-secondary-7, .5);
        padding: .25rem .5rem .25rem .35rem;
        top: 1.5rem;
        right: 1.5rem;
        transform: none;
        display: inline-block;

        &-container {
          gap: .5rem;

          div {
            display: flex;
            align-items: center;
            gap: .5rem;
          }
        }
      }

      &-text {
        color: $color-secondary-3;
        margin-left: 4px;
        font-size: .75rem;
        line-height: 1.25rem;

        @media (max-width: $breakpoint-tablet) {
          margin: 0;
        }
      }
    }

    &-image {
      border-radius: 10px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      float: right;

      @media (min-width: $breakpoint-tablet) {
        // max-width: 350px;
        height: 100%;
      }
    }
  }

  &__arrow {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-out;
    opacity: 0;
    height: inherit;
  }

  &:hover > .book__arrow {
    opacity: 1;
    transform: translate(0, -32px);
  }
}
