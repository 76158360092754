@import "../../../../styles/shared.scss";

.why-bookolicos {
  padding: 20px 20px;
  margin: 0;
  display: flex;
  flex-direction: column;
  background-color: $color-secondary-6;
  border-radius: 0 20% 0 0%;
  @media (min-width: $breakpoint-tablet) {
    margin: 100px 0px;
    margin-bottom: 0px;
    padding: 20px 40px;
  }

  &__border {
    border-top: 3px solid $color-principal;
    width: 10%;
    padding-bottom: 20px;
    @media (min-width: $breakpoint-tablet) {
      width: 4%;
    }
  }
  &__title {
    color: black;
    font-family: $font-secondary;
    font-size: 35px;
    font-weight: 600;
    line-height: 50px;
    padding-bottom: 20px;
    width: 70%;
    @media (min-width: $breakpoint-tablet) {
      width: 100%;
    }
  }

  &__container-containers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 60px;

    @media (min-width: $breakpoint-tablet) {
    }
  }

  &__container-impar {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    @media (min-width: $breakpoint-tablet) {
      display: flex;
      flex-direction: row;
    }

    &-section-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 5px;
      @media (min-width: $breakpoint-tablet) {
        margin-left: 50px;
        padding: 40px;
        margin-left: 20px;
        width: 75%;
      }
    }
    &-title {
      color: $color-secondary-1;
      font-family: $font-secondary;
      font-size: 35px;
      font-weight: 600;
      line-height: 42px;
      @media (min-width: $breakpoint-tablet) {
        font-size: 46px;
        line-height: 42px;
      }
    }
    &-text {
      color: $color-secondary-1;
      font-family: $font-secondary;
      font-size: 18px;
      font-weight: 300;
      line-height: 28px;
      padding-bottom: 20px;
    }
    &-section-image {
      margin: 5px;
      &-img {
        border-radius: 10px;
        width: 100%;
      }
      &-img-one {
        width: 100%;
        border-radius: 10px;
        margin-top: 40px;
        @media (min-width: $breakpoint-tablet) {
          width: 550px;
          margin-top: 0px;
        }
      }
    }
  }

  &__container-par {
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: $breakpoint-tablet) {
      display: flex;
      flex-direction: row;
    }
    &-section-text {
      margin: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (min-width: $breakpoint-tablet) {
        margin-right: 50px;
        padding: 40px;
        margin-right: 30px;
        width: 75%;
      }
    }
    &-title {
      color: $color-secondary-1;
      font-family: $font-secondary;
      font-size: 35px;
      font-weight: 600;
      line-height: 42px;
      @media (min-width: $breakpoint-tablet) {
        font-size: 46px;
        line-height: 42px;
      }
    }
    &-text {
      color: $color-secondary-1;
      font-family: $font-secondary;
      font-size: 18px;
      font-weight: 300;
      line-height: 28px;
      padding-bottom: 20px;
    }
    &-section-image {
      margin: 5px;
      &-img {
        border-radius: 10px;
        width: 100%;
      }
      &-img-two {
        width: 100%;
        border-radius: 10px;
        margin-top: 40px;
        @media (min-width: $breakpoint-tablet) {
          width: 550px;
          margin-top: 0px;
        }
      }
    }
    &-icons-list {
      display: flex;
      flex-direction: row;
      padding-left: 0px;
      &-item {
        margin-right: 10px;
      }
    }
  }

  .btn-white {
    background-color: $color-secondary-7;
    border: 1px solid transparent;
    width: 60%;
    @media (min-width: $breakpoint-tablet) {
      width: 40%;
    }
  }
}
