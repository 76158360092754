@import "./../../../styles/shared.scss";

.edit-book-published {
  background-color: $color-tertiary-5;
  width: 100%;
  margin-top: -100px;
  margin-bottom: -160px;

  &__container-buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;


}
&__position-bar{
  position: fixed;
  bottom: 20px;
  left: 40px;
  right: 40px;
  background-color:$color-tertiary-4;
  border-radius: 32px;
  padding: 10px;

}
&__section-book{
padding-bottom: 170px;
padding-top: 50px;

}

  &__exit-page {
    text-align: end;
    color: $color-principal;
    margin-top: 100px;
    cursor: pointer;
  }

  &__edit {
    font-family: $font-primary;
    color: $color-secondary-2;
    font-size: 22px;
    line-height: 29px;
    margin-bottom: 0;
    padding-left: 15px;
  }

  &__cancel {
    font-family: $font-secondary;
    color: $color-secondary-2;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0px;
    cursor: pointer;

  }

  &__button {
    border-radius: 32px;
    background-color: $color-secondary-1;
    padding: 10px 25px 10px 25px;
    color: $color-secondary-7;
    cursor: pointer;
    outline: none;
    border: none;
    margin-left: 10px;
  }
}
