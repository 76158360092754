@import "./../../../styles/shared.scss";

.book-for-you {
  overflow: hidden;
  @media (max-width: $breakpoint-tablet) {
    padding: 0 .25rem;
  }

  &__container-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 140px 15px 20px 15px;

    @media (max-width: $breakpoint-tablet) {
      font-size: 1.75rem;
      padding: 0 .75rem;
      margin-top: 5rem;
      // margin-bottom: 1.5rem;
    }

  }
  &__title {
    font-family: $font-primary;
    color: $color-secondary-2;
    font-size: 45px;
    font-weight: 400;
    line-height: 41px;
    padding-left: 25px;
    margin-bottom: 1.5rem;

    @media (max-width: $breakpoint-tablet) {
      font-size: 1.75rem;
      padding: 0;
      color: $color-tertiary-1;
    }

  }

  &__button {
    @media (max-width: $breakpoint-tablet) {
      display: none;
    }
  }

  &__list {
    margin: 0;
    padding: 0;

    @media (max-width: $breakpoint-tablet) {
      margin: 0;
      padding: 0;
      gap: 0;
      width: calc(100% - 1.5rem);
    }
  }

  &__slider-item {
    padding-right: 0;
    @media (max-width: $breakpoint-tablet) {
      .slick-list {
        overflow: visible;
      }
    }

    .slick-track {
      display: flex;
      gap: .5rem;
    }

    .slick-list {
      overflow: visible;
    }

  }

  &__mobile-actions {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: $breakpoint-tablet) {
      display: none;
    }
  }
}
