@import "./../../styles/shared.scss";

.profile-container {
    background-color: $color-tertiary-5;
    width: 100%;
    min-height: 100vh;
    padding-top: 100px;
    padding-bottom: 80px;

    @media (max-width: $breakpoint-tablet) {
        padding: 5rem .5rem 0;
    }
}
