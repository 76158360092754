@import "./../../../styles/shared.scss";

.book-detail-component {
  background-color: $color-secondary-6;
  display: flex;
  flex-direction: column;
  position: relative;
  @media (min-width: $breakpoint-tablet) {
    flex-direction: row;
    height: 100vh;
  }

  @media (max-width: $breakpoint-tablet) {
    margin-top: 3rem;
    .arrows {
      display: none;
    }
  }

  &__header-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .75rem 1rem;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: $color-secondary-6;
    z-index: 100;

    @media (min-width: $breakpoint-tablet) {
      display: none;
    }

    &-title {
      line-height: 1.5rem;
      font-size: 1.12rem;
      flex: 1;
      text-align: center;
      margin-left: 1.5rem;
      margin-bottom: 0;
    }
  }
  
  &__section-likes {
    background-color: $color-secondary-7;
    color: $color-secondary-3;
    border-radius: 25px;
    padding: .2rem .5rem .2rem .25rem;
    text-align: end;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    font-family: $font-secondary;
    font-size: 12px;
    font-weight: 400;
    gap: .2rem;
    
    span{
      margin-right: 0.3em;
    }
  }

  &__container-image {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;


    @media (max-width: $breakpoint-tablet) {
      &-icon {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 1.5rem;
        right: 1.5rem;
        gap: 0.8rem;

        .icon, svg {
          width: 1rem;
          height: 1rem;
        }
      }
    }

    @media (min-width: $breakpoint-tablet) {
      width: 55%;
      padding: 2.5rem 1rem 1.5rem;

      &-icon{
      width: 100%;
      text-align: right;
      margin-bottom: 40px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      }

      .icon {
        cursor: default;
      }
    }

    &-cover {
      width: 100%;
      max-width: calc(100% - 10rem);
      padding-top: calc((100% - 10rem) * 1.31);
      position: relative;
      @media (max-width: $breakpoint-tablet) {
        margin-top: 1rem;
      }
    }


    &-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;

    }
    &-text {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-top: 80px;

      @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
        text-align: center;
        padding: 2.5rem 1.5rem;
      }

      &-justify{
        display: flex;

        @media (max-width: $breakpoint-tablet) {
          flex-direction: column;
          text-align: center;
        }
      }
      &-title {
        font-family: $font-primary;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: $color-tertiary-1;
        margin-left: 20px;

        @media (max-width: $breakpoint-tablet) {
          margin-left: 0;
          font-size: 2rem;
          line-height: 2.5rem;
          margin-bottom: 1.5rem;
        }
      }
      &-author {
        font-family: $font-primary;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #B0B0B0;
        margin-left: 5px;

        @media (max-width: $breakpoint-tablet) {
          margin-left: 0;
        }
      }
    }
  }

  &__container-sinopsis {
    background-color: $color-secondary-7;
    border-radius: 10px;
    width: 100%;
    overflow-x: hidden;
    padding: 3rem 1rem 5rem;

    @media (min-width: $breakpoint-tablet) {
      padding: 32px;
      width: 45%;
      min-height: 100vh;
      overflow-y: auto;
    }
  }

  &__container-genres {
    padding-top: 24px;

    &-title {
      font-family: $font-primary;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: $color-secondary-3;
      letter-spacing: 0.07em;
      text-transform: uppercase;
    }

    @media (max-width: $breakpoint-tablet) {
      .genres {
        width: 100%;
      }
    }
  }

  &__container-other-books {
    padding-bottom: 48px;
    margin-left: -1rem;
    margin-right: -1rem;
    @media (min-width: $breakpoint-tablet) {
      padding-bottom: 16px;
    }

    &-title {
      font-family: $font-primary;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: $color-secondary-3;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      padding: 0 1rem;
    }
    &-img {
      width: 100%;
      height: 100%;
    }
  }
  
  &__product-security{
    color: $color-principal;
    text-decoration: underline;
    margin-top: 1.5em;
    display: flex;
    justify-content: right;
    
    &:hover{
      color: $color-principal-80;
    }
  }
}

.share-result{
  color: $color-principal;
  display: block;
  margin-top: 3px;
}
