@import "./../../../styles/shared.scss";

.publications-item {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $color-secondary-4;
    margin-top: 20px;
    padding-bottom: 20px;

    &:last-of-type{
        border-bottom:none;
        padding-bottom: 0px;
    }
    &:first-of-type{
        margin-top: 0px;
    }

    &__image{
        width: 90px;
        height: 120px;
        border-radius: 10px;
        object-fit: contain;
    }

    &__container{
        padding-left: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @media (max-width: $breakpoint-tablet) {
            .btn {
                width: unset;
            }
        }
    }

    &__title{
        font-family: $font-primary;
        font-size: 22px;
        font-weight: 400;
        color: $color-secondary-2;
        line-height: 29px;
        font-weight: 300;
    }

    &__section-sales{
        display: flex;
        flex-direction: row;
    }

    &__sales{
        color: $color-secondary-3;
        font-family: $font-secondary;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
    }
    &__date{
        color: $color-secondary-4;
        font-family: $font-secondary;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        margin-left: 5px;
    }
    &__button{
        background-color: $color-secondary-7;
        border-radius: 32px;
        border: none;
        border: 1px solid $color-secondary-1;
        color: $color-secondary-2;
        font-size: 16px;
        line-height: 20px;
        margin-left: 5px;
        width: 40%;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 20px;
        padding-right: 25px;
        display: flex;
        flex-direction: row;
        align-items: center;

        &-icon{
            margin-left: 5px;
            font-size: 8px;
        }
    }
}
