@import "./../../styles/shared.scss";

.error-handler {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__img {
    width: 100%;
    height: 100vh;
  }
  &__section-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 1rem;
    background-image: url('../../../public/images/404.svg');
    background-position: center center;
    background-size: auto 100%;
    background-repeat: no-repeat;

    @media (max-width: $breakpoint-tablet) {
      background-size: calc(100% - 2rem) auto;
    }
  }
  &__title {
    font-family: $font-primary;
    font-size: 2.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.25rem;
    color: $color-secondary-1;
    padding-top: 20px;
  }
  &__text {
    font-family: $font-primary;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $color-secondary-2;
    padding-top: 3rem;
    padding-bottom: 2.5rem;
    max-width: 32rem;
  }
}
