@import "../../../styles/shared.scss";

.footer-public {
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  bottom: 0;
  background-color: $color-secondary-6;
  color: $color-secondary-2;
  padding: 20px 20px 10px 20px;

  @media (min-width: $breakpoint-tablet) {
    flex-direction: row;
    text-align: left;
    grid-template-columns: repeat(2, minmax(0, 2fr));
    padding: 50px 50px 10px 50px;
  }

  #logo {
    order: 1;
    @media (min-width: $breakpoint-tablet) {
      order: 1;
    }
  }

  #newsletter {
    order: 2;
    @media (min-width: $breakpoint-tablet) {
      order: 4;
    }
  }

  #input {
    order: 3;
    @media (min-width: $breakpoint-tablet) {
      order: 2;
    }
  }

  #rrss {
    order: 4;
    @media (min-width: $breakpoint-tablet) {
      order: 3;
    }
  }

  #copy {
    order: 5;
    @media (min-width: $breakpoint-tablet) {
      order: 6;
    }
  }

  #legality {
    order: 6;
    @media (min-width: $breakpoint-tablet) {
      order: 5;
    }
  }

  &__section-logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    img {
      height: 100%;
      max-height: 80px;
    }
  }

  &__section-rsss {
    &-list {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding-left: 0;
      padding-top: 20px;
      height: 100%;

      @media (min-width: $breakpoint-tablet) {
        align-items: flex-end;
        padding-top: 0px;
      }

      &-item {
        margin-right: 10px;
      }
    }
  }

  &__section-legality {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;

    @media (min-width: $breakpoint-tablet) {
      justify-content: flex-start;
      padding-top: 40px;
    }

    a{ text-decoration: none; }

    &-text {
      font-family: $font-secondary;
      color: $color-secondary-3;
      font-size: .75rem;
      cursor: pointer;
      &:hover {
        color: $color-secondary-1;
      }

      @media (min-width: $breakpoint-tablet) {
        margin-right: 20px;
      }
    }
  }

  &__section-input {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 40px;
    @media (min-width: $breakpoint-tablet) {
      justify-content: flex-end;
      padding-top: 0px;
    }
    &-up{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      @media (min-width: $breakpoint-tablet) {
        justify-content: flex-end;
        padding-top: 0px;
      }
    }
    &-down{
      display: flex;
      justify-content: flex-start;
      @media (min-width: $breakpoint-tablet) {
        justify-content: flex-end;
        padding-top: 0px;
      }

    }

    &-text {
      font-family: $font-secondary;
      color: $color-secondary-1;
      font-size: 1rem;
      line-height: 1.25rem;
      white-space: nowrap;
      font-weight: 400;
      text-align: center;
      margin: .25rem;
      border-radius: 25px;
      border: 1px solid $color-secondary-1;
      padding: .5rem 1.5rem;
      outline: none;
      text-align: center;
      cursor: pointer;

      &:hover {
        background-color: $color-secondary-7;
        color: $color-secondary-1;
      }

      @media (min-width: $breakpoint-tablet) {
        font-size: 16px;
      }
    }
  }

  &__section-newsletter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 60px;

    @media (min-width: $breakpoint-tablet) {
      padding-top: 40px;
      align-items: flex-end;
    }

    &-input {
      width: 100%;

      @media (min-width: $breakpoint-tablet) {
        width: 350px;
      }
    }

    &-text {
      font-family: $font-secondary;
      color: $color-secondary-1;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 0px;
      padding-right: 10px;
    }
  }

  &__section-copy {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;

    @media (min-width: $breakpoint-tablet) {
      align-items: flex-end;
      padding-top: 40px;
    }

    &-text {
      font-family: $font-secondary;
      color: $color-secondary-3;
    }
  }

  &__section-cards {
    order: 999;
    background-color: $color-secondary-6;
    width: 100% !important;
    padding: .5rem 0 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid $color-secondary-6;
    gap:48px;
    flex-direction: column;

    @media (min-width: $breakpoint-tablet) {
      justify-content: flex-start;
      padding: 1rem 2.5rem;
      border-top: 1px solid $color-secondary-2;
      /*flex: 1;
      flex-direction: row;*/
    }
    
    &__kit-digital-wrapper{
      width: 100% !important;
    }
    
    &__kit-digital{
      margin: 0 auto;
      width: 100%;
      
      @media (min-width: $breakpoint-desktop) {
        max-width: 980px;
      }
    }
  }
}
