@import "../../../styles/shared.scss";

.more-genres-private {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $color-secondary-7;
  overflow: hidden;
  margin: 70px 0 0;

  @media (min-width: $breakpoint-tablet) {
    margin: 70px;
    background-color: $color-secondary-7;
  }

  &__title {
    font-family: $font-primary;
    font-size: 35px;
    font-weight: 400;
    line-height: 42px;
    color: $color-secondary-1;
    padding-top: 20px;
    text-align: center;
    @media (min-width: $breakpoint-tablet) {
      font-size: 60px;
      line-height: 72px;
      padding-top: 30px;
    }
  }

  &__subtitle {
    margin-bottom: 60px;
    text-align: center;
    font-family: $font-secondary;
    font-size: 18px;
    font-weight: 300;
    line-height: 26px;
    color: $color-secondary-1;
    @media (min-width: $breakpoint-tablet) {
      font-size: 18px;
      line-height: 30px;
    }
  }

  &__genres {
    padding-left: 10%;
    padding-right: 10%;

    @media (max-width: $breakpoint-tablet) {
      display: none;
    }
  }

  &__genres-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;

    @media (min-width: $breakpoint-tablet) {
      display: none;
    }

    &-left {
      animation: leftToRight 60s linear infinite;
      animation-delay: .5s;
    }

    &-right {
      animation: rightToLeft 60s linear infinite;
      animation-delay: .5s;
    }
  }
}

@keyframes leftToRight {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
}

@keyframes rightToLeft {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}
