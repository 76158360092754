@import "../../../../styles/shared.scss";

.writers-phrase {
  background-color: $color-secondary-6;

  padding: 80px 30px 50px 30px;
  @media (min-width: $breakpoint-tablet) {
    margin: 60px 100px;
  }
  &__text {
    font-family: $font-primary;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: $color-secondary-1;
    text-align: left;
    margin-bottom: 40px;
    @media (min-width: $breakpoint-tablet) {
      font-size: 2.1875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.4;
    }
  }
  &__container-writer {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
  }
  &__name {
    font-family: $font-secondary;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    color: $color-principal;
    margin-bottom: 5px;
    margin-right: 10px;
    @media (min-width: $breakpoint-tablet) {
      font-size: 18px;
      line-height: 21px;
    }
  }
  &__book {
    font-family: $font-secondary;
    font-size: 18px;
    font-weight: 300;
    line-height: 21px;
    color: $color-secondary-1;
    @media (min-width: $breakpoint-tablet) {
      font-size: 18px;
      line-height: 21px;
    }
  }
}
