@import "./../../../styles/shared.scss";

.welcome {
  width: 100%;

  &__title {
    margin: 20px 0px;
    font-family: $font-primary;
    font-size: 35px;
    line-height: 42px;
    font-weight: 400;
    color: $color-secondary-2;
  }
  &__input {
    font-family: $font-secondary;
    border-radius: 32px;
    outline: none;
    border: 1px solid $color-secondary-2;
    padding: 16px 24px;
    margin: 16px 0;
    width: 100%;
    font-size: 14px;
    line-height: 20px;

    &::placeholder {
      color: $color-secondary-3
    }
  }
  &__container-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  &__btn {
    width: 100%;
    border-radius: 32px;
    outline: none;
    border: 1px solid $color-secondary-3;
    background-color: $color-secondary-3;
    color: $color-secondary-7;
    padding: 10px 35px;
    margin-bottom: 10px;
    margin-top: 10px;
    cursor: pointer;
    &--orange {
      background-color: $color-principal;
      color: $color-secondary-7;
      border: 1px solid $color-principal;
    }
  }
  &__btn-return {
    outline: none;
    border: none;
    background-color: $color-secondary-7;
    color: $color-secondary-2;
    margin-bottom: 30px;
    margin-top: 16px;
    cursor: pointer;
  }
}
