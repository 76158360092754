@import "./../../../styles/shared.scss";

.blog-detail {
  padding: 0 .5rem;
  margin-top: 8rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: $breakpoint-tablet) {
    padding: 0;
  }

  &__info {
    padding-right: 3rem;
    // Flex
    order: 2;
    flex: 50%;
    max-width: 50%;

    @media (max-width: $breakpoint-tablet) {
      order: 1;
      flex: 100%;
      max-width: 100%;
      padding: 0 .5rem 1rem;
    }
  }

  &__title {
    font-family: $font-primary;
    font-size: 2.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $color-tertiary-1;
    margin-bottom: 2rem;

    @media (max-width: $breakpoint-tablet) {
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 1rem;
    }
  }

  &__author {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.15625rem;
    color: $color-secondary-3;
  }

  &__date {
    color: $color-secondary-4;
  }

  &__image {
    width: 100%;
    height: 22rem;
    border-radius: .625rem;
    overflow: hidden;
    // Flex
    order: 1;
    margin-bottom: 5rem;

    @media (max-width: $breakpoint-tablet) {
      order: 2;
      flex: 100%;
      max-width: 100%;
      margin-bottom: 2rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__readTime {
    display: inline-block;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: 0.015rem;
    color: $color-secondary-3;
    margin-bottom: 1.25rem;
  }

  &__data {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5625rem;
    // Flex
    order: 3;
    flex: 50%;
    max-width: 50%;

    @media (max-width: $breakpoint-tablet) {
      flex: 100%;
      max-width: 100%;
      padding: 0 .5rem;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.75rem;
    }

    &-content img{
      width: 100%;
      height: auto;
    }
  }

  &__share {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: 0.0175rem;
    color: $color-secondary-3;
    // Flex
    order: 4;
    margin-top: -3rem;

    @media (max-width: $breakpoint-tablet) {
      margin-top: 1rem;
      padding: 0 .5rem;
    }

    .link {
      text-decoration: underline;
    }
  }

  &__news {
    margin-top: 9rem;
    overflow: hidden;

    @media (max-width: $breakpoint-tablet) {
      margin-top: 6rem;
    }
  }

  &__news-title {
    font-family: $font-primary;
    font-size: 2.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $color-tertiary-1;
    padding: 0 1.5rem !important;
    margin-bottom: 2rem;

    @media (max-width: $breakpoint-tablet) {
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 1rem;
    }
  }

  &__list {
    margin: 0 0 3rem;
    @media (max-width: $breakpoint-tablet) {
      padding: 0;
      gap: 0;
      width: calc(100% - 1.5rem);
    }
  }

  &__slider-item {
    padding-right: 0;

    .slick-track {
      display: flex;
      gap: .5rem;
    }

    .slick-slide {
      width: 100%;
    }

    @media (max-width: $breakpoint-tablet) {
      .slick-list {
        overflow: visible;
      }
    }
  }
}
