@import "./../../styles/shared.scss";

.write-review {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: $breakpoint-tablet) {
    padding-top: .5rem;
  }

  &__title {
    font-family: $font-primary;
    font-size: 18px;
    color: $color-secondary-2;
  }
  &__subtitle {
    font-family: $font-primary;
    font-size: 16px;
    color: $color-secondary-2;
  }
  &__buttons-wrapper{
    border-radius: 55px;
    background-color: $color-secondary-6;
    padding: 8px;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
  }
  &__button{
    padding: 8px;
    border-radius: 55px;
      
    &.active{
      background-color: $color-secondary-7;
      path{  
        fill: $color-principal;
      }
    }
  }
  &__container-card {
    display: flex;
    width: 100%;
    justify-content: center;
    cursor: pointer;

    @media (max-width: $breakpoint-tablet) {
      gap: 1rem;
    }

    &-item {
      width: 20%;
      padding: 4px 10px 10px 10px;
      margin: 20px;
      border: 1px solid $color-secondary-3;
      border-radius: 15px;
      text-align: center;
      opacity: 0.5;

      @media (max-width: $breakpoint-tablet) {
        width: 100%;
        max-width: max(30dvw, 150px);
        margin: 1.5rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
      }

      &:hover {
        opacity: 1;
        transition: opacity 0.5s ease-out;
        -webkit-transition: opacity 0.5s ease-out;
        -moz-transition: opacity 0.5s ease-in-out;
      }

      &.selected {
        border: 1px solid $color-principal;
        background-color: $color-principal-05;
        opacity: 1;
      }

      &-text {
        font-family: $font-secondary;
        font-size: 12px;
        color: $color-secondary-3;
        margin-bottom: 0px;
      }
    }
  }
  &__container-card-form {
    display: flex;
    width: 100%;
    justify-content: center;

    form {
      width: 100% !important;
      padding-left: 50px;
      padding-right: 50px;

      @media (max-width: $breakpoint-tablet) {
          padding-left: 0px;
          padding-right: 0px;
      }
    }
  }
  &__form {
    width: 100%;
    margin-left: 15px;

    &-label {
      font-family: $font-primary;
      font-size: 12px;
      text-transform: uppercase;
      color: $color-secondary-2;
      letter-spacing: 7%;
    }
    &-input{
      font-family: $font-secondary;
      font-size: 14px;
      color: $color-secondary-2;
      border: 1px solid $color-secondary-3;
      border-radius: 32px;
      width: 95%;
      padding: 10px;

    }
    &-container-security{
      display: flex;
      margin-bottom: 40px;
      margin-top: 20px;

    }
    
    
  }
  &__text-download {
    font-family: $font-secondary;
    font-size: 16px;
    color: $color-secondary-3;
    margin-bottom: 0px;
    text-align: center;
    padding: 20px 50px;
  }
  &__container-button{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__date{
    font-size: 12px;
    color: $color-secondary-3;
  }
  
  &__input, &__textarea {
    color: #979797;
    font-family: $font-secondary;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  
  &__textarea {
    border: 1px solid #979797;
    margin-top: 8px;
    padding: 16px;
    border-radius: 10px;
    width: 100%;
  }
  
  &__textarea:focus-visible {
    outline: 0;
  }
  
  &__textarea.active {
    border-color: $color-principal;
    color: $color-secondary-1;
  }
  
  &__button {
    padding: 8px;
    border: 0;
    border-radius: 22px;
    background: #FFFFFF;
    color: $color-secondary-3;
    margin-left: 8px;
    text-decoration: underline;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
  }
}

.write-review-backdrop{
  z-index: 1055;
}

.modal {
  &.write-review-modal {
    max-height: 100dvh;
    &.show {
      .modal-content {
        background-color: rgba(255, 255, 255, 1);
      }
    }
  }
}

.not-card{
  font-family: $font-secondary;
  font-size: 16px;
  color: $color-secondary-3;
}

.books-customer-item {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.01px solid $color-secondary-4;

  @media (max-width: $breakpoint-tablet) {
    border: none;
  }

  &:last-child {
    border-bottom: none;
  }

  &__cover {
    width: 7rem;
    padding-top: calc(7rem * 1.31);
    background-color: pink;
    margin-right: 1.5rem;
    position: relative;
    border-radius: .625rem;
    overflow: hidden;

    &-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__container {
    display: flex;

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0;
    }
    &-title {
      font-family: $font-primary;
      color: $color-tertiary-1;
      font-size: 22px;
      font-weight: 300;
    }
    &-author {
      font-family: $font-primary;
      color: $color-secondary-3;
      font-size: 16px;
      font-weight: 300;
    }
  }

  &__genres-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: .25rem;

    .genre-item {
      margin: .5rem 0;
    }

    &-button {
      padding: 4px 14px;
      border: 1px solid $color-secondary-3;
      border-radius: 25px;
      margin-bottom: 0;
      background-color: transparent;

      &--v3 {
        padding: .25rem 1rem;

        @media (max-width: $breakpoint-tablet) {
          padding: .12rem .5rem;
        }
      }

      img {
        margin: .5rem 0;
      }
    }
  }

  &__extra-info {
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 0;
    align-items: start;
  }

  &__download {
    background-color: $color-secondary-7;
    color: $color-secondary-3;
    border-radius: 25px;
    padding: .2rem .5rem .2rem .25rem;
    text-align: end;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: $font-secondary;
    font-size: 12px;
    font-weight: 400;
    gap: .5rem;
    
    .icon{
      cursor:default !important;
    }

    @media (max-width: $breakpoint-tablet) {
      visibility: hidden;
    }

  }
  &__price {
    font-family: $font-secondary;
    color: $color-secondary-3;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
  }
}