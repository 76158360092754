@import "./../../../styles/shared.scss";

.genres-author {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  border-top: 1px solid $color-secondary-4;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  .genres {
    width: 100%;
  }

  @media (min-width: $breakpoint-tablet) {
    margin-left: 130px;
    margin-right: 130px;
    padding-top: 4rem;
    padding-bottom: 4rem;
    flex-direction: row;
  }

  &__title {
    font-family: $font-primary;
    color: $color-tertiary-1;
    font-size: 22px;
    margin-bottom: 1rem;

    @media (min-width: $breakpoint-tablet) {
      width: 20rem;
    }
  }

  &__genres {
    margin: 0;

    @media (min-width: $breakpoint-tablet) {
      max-width: calc(100% - 20rem);
    }
  }
}
