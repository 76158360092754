@import "./../../../styles/shared.scss";

.is-registered {
  width: 100%;
  &__form {
    width: 100%;
    align-items: center;
  }
  &__btn {
    font-family: $font-secondary;
    border-radius: 32px;
    outline: none;
    border: 1px solid $color-secondary-3;
    background-color: $color-secondary-3;
    color: $color-secondary-7;
    margin: 16px 0;
    padding: 10px;
    width: 100%;
    box-sizing: content-box;
    cursor: pointer;
    &--orange{
      background-color: $color-principal;
      color: $color-secondary-7;
      border: 1px solid $color-principal;
      font-family: $font-secondary;
      border-radius: 32px;
      outline: none;
      margin: 16px 0;
      padding: 10px;
      width: 90%;
      cursor: pointer;
    }
  }
}


// maqueta para el separador.

.divider {
  display: flex;
  &:before,
  &:after {
    content: "";
    flex: 1;
  }
}
.line {
  align-items: center;
  margin: 3rem -1.5em;
  color: $color-secondary-3;
  font-size: 1.12rem;
  line-height: 1rem;

  &:before,
  &:after {
    height: 1px;
    margin: 0 1.5em;
  }
}
.razor {
  &:before,
  &:after {
    box-shadow: 0 1px 0 $color-secondary-3; // Chrome
    // border-bottom: 1px solid black; // Safari
  }
}
