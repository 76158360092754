@import "./../../../styles/shared.scss";

.upload-book {
  background-color: $color-secondary-7;
  padding: 1rem;
  border-radius: 10px;

  ::placeholder {
    color: $color-secondary-3;
  }
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1.5rem;

  @media (min-width: $breakpoint-tablet) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0rem;
    height: 100%;
  }

  &__icon {
    width: 5.5rem;
    border-radius: .625rem;
    background-color: $color-secondary-6;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: $breakpoint-tablet) {
      background-color: $color-secondary-7;
    }
  }

  &__content {
    margin-top: 1rem;
    flex: 1;

    @media (min-width: $breakpoint-tablet) {
      flex: unset;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }

  &__action {
    max-width: 11rem;
  }

  &__title {
    font-family: $font-primary;
    color: $color-secondary-2;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6875rem;
  }

  &__text {
    font-family: $font-secondary;
    color: $color-secondary-3;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }

  &__text-error {
    font-style: italic;
    color: red;
  }
}
