@import "./../../styles/shared.scss";

.legal-bases {
  background-color: $color-secondary-7;
  padding-bottom: 4.5rem;

  @media (min-width: $breakpoint-tablet) {
    background-color: $color-secondary-6;
  }

  &__container {
    width: 100%;
    padding: 5rem max(calc((100% - 70rem)/2), 1rem);
    background-color: $color-secondary-7;
    border-radius: 0.625rem;
    column-count: 1;
    column-gap: 1.5rem;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: -5rem;
      left: 0;
      width: 100%;
      height: 5rem;
      background-color: $color-secondary-6;
    }

    @media (min-width: $breakpoint-tablet) {
      margin: 4.5rem auto 0;
      width: calc(100% - 2rem);
      column-count: 2;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    break-inside: avoid-column;

    span {
      display: inline-block;
      font-family: $font-secondary;
      font-size: 2.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: $color-secondary-4;
      margin-bottom: 1.5rem;
    }

    p {
      font-family: $color-secondary-1;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5625rem;
      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem;
      border-bottom: 1px solid $color-secondary-4;

    }
    &:last-child p {
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: $color-secondary-4;
    margin: 1.5rem 0 1.5rem;
  }
}
