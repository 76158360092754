@import "./../../styles/shared.scss";

.faqs-container{
  .faqs-items{
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 0 64px;

    @media (max-width: $breakpoint-tablet) {
      margin-top: 5rem;
      padding: 0 1rem;
      margin-bottom: 5rem;
    }
  }

  ul {
    li {
      list-style: disc;
    }
  }
}

.faqs {
  margin-left: 60px;
  margin-right: 60px;
  margin-top: 0px;
  margin-bottom: 100px;
  border-top: 1px solid $color-secondary-4;

  @media (max-width: $breakpoint-tablet) {
    margin-top: 1.5rem;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  &__container-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 80px 15px 20px 15px;

    @media (max-width: $breakpoint-tablet) {
      padding: 1.5rem;
      flex-direction: column;
    }
  }

  &__title {
    font-family: $font-primary;
    color: $color-secondary-1;
    font-size: 45px;
    font-weight: 400;
    line-height: 41px;
    padding-left: 25px;
    margin-bottom: 1.5rem;

    @media (max-width: $breakpoint-tablet) {
      font-size: 2.1875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.4;
      padding-left: 0;
    }
  }

  &__arrows {
    @media (min-width: $breakpoint-tablet) {
      display: inherit;
      margin-top: 7px;
    }
  }
}

.faqs-answer{
  max-width: 850px;
  font-family: $font-secondary;
  color: $color-secondary-2;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}
