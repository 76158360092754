@import "../../styles/shared.scss";

.modal-filter {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__container {
    .modal-body {
      padding: 0;
    }

    @media (max-width: $breakpoint-tablet) {
      .modal-dialog {
        padding: 0;
        margin: 0;
      }
      .modal-content {
        height: 100dvh;
        max-height: 100vh;
        border-radius: 0;
      }
    }
  }
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: $font-primary;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6875rem; /* 135% */
    color: $color-secondary-2;
    position: relative;
    padding: 1.5rem;
    border-bottom: 1px solid $color-secondary-5;
  }

  &__close {
    position: absolute;
    left: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  &__content {
    padding: 2.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    flex: 1;
  }

  &__filter {
    &-title {
      font-family: $font-primary;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.4375rem;
      margin-bottom: 1rem;
    }

    &-items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: .5rem;
    }

    &-item {
      color: $color-secondary-3;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.75rem; /* 175% */
      padding: .5rem 1.25rem;
      border: 1px solid $color-secondary-3;
      border-radius: 2rem;
      cursor: pointer;
      transition: all .1s ease;

      &--selected {
        border-color: $color-secondary-1;
        background-color: $color-secondary-1;
        color: $color-secondary-7;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    border-top: 1px solid $color-secondary-5;
  }
}
