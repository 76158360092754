@import "./../../../styles/shared.scss";

.sales-item {
  border-bottom: 1px solid $color-secondary-4;
  &:last-of-type {
    border-bottom: none;

  }
  &__container {
    background-color: $color-secondary-7;
    margin-bottom: 15px;
    margin-top: 25px;
    &-component {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &-image {
        width: 90px;
        height: 120px;
        border-radius: 10px;
      }
      &-component {
        padding-left: 25px;
        width: calc(100% - 90px);
      }
      &-title {
        font-family: $font-primary;
        font-size: 22px;
        font-weight: 400;
        color: $color-secondary-2;
        line-height: 29px;
      }
      &-section {
        display: flex;
        flex-direction: row;
        &-sales {
          color: $color-secondary-3;
          font-family: $font-secondary;
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
        }
        &-date {
          color: $color-secondary-4;
          font-family: $font-secondary;
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
          margin-left: 5px;
        }
      }
      &-info-component {
        display: flex;
        flex-direction: row;
        width: calc(100% - 90px);
        margin-left: 90px;
        padding-left: 25px;
        @media (max-width: $breakpoint-tablet) {
          flex-wrap: wrap;
          width: 100%;
          padding-left: 0;
          margin-left: 0;
          margin-top: 1rem;
          margin-bottom: 1.5rem;
        }
      }
      &-info {
        background-color: $color-secondary-7;
        border-radius: 32px;
        border: 1px solid $color-secondary-1;
        color: $color-secondary-2;
        font-size: 14px;
        line-height: 20px;
        margin-left: 5px;
        margin-bottom: 0;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 20px;
        padding-right: 25px;
        display: flex;
        flex-direction: row;
        align-items: center;
        transform: translateY(-2rem);

        @media (max-width: $breakpoint-tablet) {
          transform: none;
          padding: .5rem .7rem;
        }
      }
    }
  }
}
