@import "./../../../styles/shared.scss";

.modal-publish-book-container {
  .modal-content {
    border-radius: 20px;
    background-color: $color-secondary-1 !important;
  }
}

.modal-edit-book {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  &__title{
    font-family: $font-primary;
    font-size: 55px;
    font-weight: 400;
    color: $color-secondary-7;
    padding-top: 20px;
    margin-bottom: 0;
  }
  &__subtitle{
    font-family: $font-primary;
    font-size: 35px;
    font-weight: 400;
    color: $color-secondary-7;
    padding-top: 20px;
    margin-bottom: 0;
  }
  &__text{
    width: 72%;
    font-family: $font-secondary;
    font-size: 16px;
    font-weight: 400;
    color: $color-secondary-7;
    padding-top: 24px;
    text-align: center;
  }
  &__buttons {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
  }
  &__btn {
    margin: 10px 4px;
  }
}
