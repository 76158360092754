@import "../../styles/shared.scss";

.filter {
  padding: 0 .5rem;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;

    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
      align-items: flex-start;
      padding: 2.5rem 0 0rem;
    }
  }

  &__title {
    padding: 0 1rem;
    color: $color-tertiary-1;
    font-family: $font-primary;
    font-size: 2.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (max-width: $breakpoint-tablet) {
      font-size: 1.75rem;
      margin-bottom: 1.5rem;
      padding: 0 0.5rem;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0 1rem;
    gap: .5rem;

    @media (max-width: $breakpoint-tablet) {
      padding: 0 0.5rem;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__sort {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.62rem;
    padding: 0.5rem 1.25rem;
    border: 1px solid $color-secondary-2;
    border-radius: 2rem;
    position: relative;
    cursor: pointer;

    &>span {
      white-space: nowrap;
    }


    &--active {
      &>.icon {
        transform: rotate(180deg);
      }
    }

    &-options {
      display: none;
      position: absolute;
      top: 1rem;
      right: 0.5rem;
      background-color: #fff;
      border-radius: .625rem;
      box-shadow: 0px 0px 14px 0px rgba(101, 101, 101, 0.10);
      padding: 1rem;
      z-index: 1;

      @media (max-width: $breakpoint-tablet) {
        right: unset;
        left: .5rem;
      }

      &--active {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 1rem;
      }
    }

    &-option {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 0.62rem;
      cursor: pointer;
      white-space: nowrap;
      padding: 0 1rem 0 2rem;
      line-height: 1.75rem;
      position: relative;

      &>.icon {
        position: absolute;
        left: .5rem;

      }
    }
  }

  &__filter {
    border: 1px solid $color-secondary-2;
    border-radius: 2rem;
    padding: .75rem;
    position: relative;
    cursor: pointer;

    &-badge {
      position: absolute;
      bottom: -.25rem;
      right: -.25rem;
      background-color: $color-principal;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 0.625rem;
      font-weight: 600;
    }
  }
}
