@font-face {
    font-family: "novela";
    src: url("./fonts/Novela-Regular.otf") format("truetype");
  }
  @font-face {
    font-family: "inter-tight";
    src: url("./fonts/InterTight-VariableFont_wght.ttf") format("truetype");
  }

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-weight: normal;
    list-style: none;
  }


