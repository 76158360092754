@import "./../../../styles/shared.scss";

.input-container {
  display: flex;
  flex-direction: column;

  &__label {
    margin-bottom: .5rem;
    margin-top: 1rem;
    display: block;
    color: $color-secondary-2;
    font-family: $font-primary;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.0525rem;
    text-transform: uppercase;
  }
}


.input-wrapper {
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-top: .5rem;
  border: 1px solid $color-secondary-2;
  border-radius: 32px;
  overflow: hidden;

  &.error{
    border: 1px solid red;
  }

  &.active {
    border-color: $color-principal;
  }

  &.icon-left {
    flex-direction: row-reverse;

    & .input-icon {
      margin-left: 8px;
      margin-right: 0;
      right: .5rem;
      width: 40px;
      height: 40px;
    }
  }

  &.icon-right {
    flex-direction: row;

    & .input-icon {
      margin-left: 8px;
      margin-right: 0;
      left: 2px;
    }
  }
}

.input-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &.show {
    display: block;
  }
}
.input-text {
  background-color: transparent;
  cursor: pointer;
  transition: border-color 0.3s ease;
  border-radius: 32px;
  outline: none;
  border: none;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-family: $font-secondary;
  color: $color-secondary-1;
  letter-spacing: 1px;
  padding: 1rem 1.5rem;

  &.input-text--small {
    padding: 0.5rem 1.5rem;
  }

  &::placeholder {
    color: $color-secondary-3;

  }

  &.error {
    border-color: red;
  }

  &.active {
    border-color: $color-principal;
    color: $color-secondary-1;
  }

  &:disabled {
    background-color: $color-secondary-7;
    color: $color-secondary-4;
    cursor: not-allowed;
  }
}

.input {
  &__right-item {
    background-color: $color-secondary-5;
    display: inline-block;
    height: 100%;
    padding: 1.12rem 1.5rem 1.12rem 1rem;
  }
}
