@import "../../../../styles/shared.scss";

.part-subtitle-who {
  display: flex;
  flex-direction: column;
  background-color: $color-secondary-6;
  @media (min-width: $breakpoint-tablet) {
    display: flex;
    flex-direction: row;
  }

  &__title {
    font-family: $font-secondary;
    color: $color-secondary-1;
    font-size: 35px;
    font-weight: 600;
    line-height: 42px;
    padding: 80px 12px 12px 12px;
    @media (min-width: $breakpoint-tablet) {
      padding: 60px;
      font-size: 30px;
      line-height: 40px;
    }
  }
  &__text {
    font-family: $font-secondary;
    color: $color-tertiary-1;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    padding: 12px;

    @media (min-width: $breakpoint-tablet) {
      padding: 60px;
      padding-left: 100px;
    }
  }
}
