@import "../../../styles/shared.scss";

.avatar {
  text-align: center;
  color: $color-secondary-2;
  border: none;
  background-color: $color-secondary-4;
  cursor: pointer;
  outline: none;
  align-items: center;
  display: flex;
  text-decoration: none;
  margin: 0 auto;

  span {
    text-decoration: none;
    font-family: $font-secondary;
    font-weight: 700;
    margin: 0 auto;
  }

  &__s {
    font-size: 15px;
    width: 56px;
    height: 56px;
    border-radius: 32px;
  }

  &__m {
    font-size: 20px;
    width: 80px;
    height: 80px;
    border-radius: 64px;
  }

  &__l {
    font-size: 30px;
    width: 112px;
    height: 112px;
    border-radius: 96px;
  }

  &__xl {
    font-size: 40px;
    width: 152px;
    height: 152px;
    border-radius: 128px;
  }

  &__xxl {
    font-size: 54px;
    width: 300px;
    height: 300px;
    border-radius: 128px;
  }
  &__xxl-authors {
    font-size: 54px;
    width: 348px;
    height: 358px;
    border-radius: 128px;
  }

  &__squared{
    border-radius: 10px !important
  }

  &:hover {
    color: $color-principal;
  }
}