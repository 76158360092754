@import "../src/styles/shared.scss";

* {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

body {
  $font-primary: "novela";
  @media (max-width: $breakpoint-tablet) {
    max-width: 100%;
    width: 100%;
    overflow-x: hidden;
  }
}

#wrapper {
  position: relative;

}

// TODO: Esto está afectando a la parte pública.
//  Tenemos que controlarlo dentro de cada página, no se puede dejar como algo global
//.cuerpo {
//  padding-bottom: 81px;
//
//  @media (max-width: $breakpoint-tablet) {
//    padding-bottom: 4rem;
//  }
//}

.pie {
  width: 100%;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $color-secondary-3;
  border: 4px solid transparent;
  background-clip: content-box;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  border-radius: 20px;
  background: $color-secondary-1;
}

.success{
  font-size: 14px;
  line-height: 1.2em;
  color: green;
}

.error{
  font-size: 14px;
  line-height: 1.2em;
  color: red;
}

.pointer{
  cursor: pointer !important;
}

.powered-by-stripe {
  width: 100px;
}
