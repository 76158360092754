@import "./../../../styles/shared.scss";

.book-list-public {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 0px;

  @media (min-width: $breakpoint-tablet) {
    display: grid;
    gap: 2px;
    grid-template-columns: repeat(auto-fill, minmax(425px, 1fr));
    padding-left: 2px;
    padding-right: 8px;
    padding-left: 8px;
  }

  &__container-button {
    display: none;
    @media (min-width: $breakpoint-tablet) {
      display: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-top: 100px;
      margin-bottom: 100px;
    }
  }
  &__container-button-mobile {
    display: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 100px;
    @media (min-width: $breakpoint-tablet) {
      display: none;
    }
  }
}
