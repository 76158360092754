@import "./../../../styles/shared.scss";

.information {
    margin-top: 2.5rem;
    &__title {
        font-family: $font-primary;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 0.9375rem;
        color: $color-secondary-3;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        margin-bottom: .5rem;
    }
    &__container {
        display: flex;
        flex-direction: row;
        justify-content:space-between;
        font-family: $font-secondary;
        font-size: 14px;
        line-height: 24px;
        color: $color-secondary-2;
        border-bottom: 1px solid $color-secondary-4 ;
        padding-top: 1rem;

        &-text{
            font-family: $font-secondary;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.75rem;
            color: $color-secondary-2;
            margin-bottom: 1rem;
        }
    }

}

.capitalize { text-transform:capitalize; }
