@import "./../../styles/shared.scss";

.publish-book {
  background-color: $color-tertiary-5;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}
