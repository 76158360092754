@import "./../../../styles/shared.scss";


.books-author-item {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.01px solid $color-secondary-4;
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;

  @media (max-width: $breakpoint-tablet) {
    border: none;
  }

  &:last-child {
    border-bottom: none;
  }

  &__cover {
    width: 7rem;
    padding-top: calc(7rem * 1.31);
    background-color: pink;
    margin-right: 1.5rem;
    position: relative;
    border-radius: .625rem;
    overflow: hidden;

    &-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__container {
    display: flex;

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1.5rem 0;
    }
    &-title {
      font-family: $font-primary;
      color: $color-tertiary-1;
      font-size: 22px;
      font-weight: 300;
    }
    &-author {
      font-family: $font-primary;
      color: $color-secondary-3;
      font-size: 16px;
      font-weight: 300;
    }
  }

  &__genres-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: .25rem;

    .genre-item {
      margin: .5rem 0;
    }

    &-button {
      padding: 4px 14px;
      border: 1px solid $color-secondary-3;
      border-radius: 25px;
      margin-bottom: 0;
      background-color: transparent;

      &--v3 {
        padding: .25rem 1rem;

        @media (max-width: $breakpoint-tablet) {
          padding: .12rem .5rem;
        }
      }

      img {
        margin: .5rem 0;
      }
    }
  }

  &__extra-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.5rem 0;
    align-items: end;
  }

  &__download {
    background-color: $color-secondary-7;
    color: $color-secondary-3;
    border-radius: 25px;
    padding: .2rem .5rem .2rem .25rem;
    text-align: end;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: $font-secondary;
    font-size: 12px;
    font-weight: 400;
    gap: .3rem;

    @media (max-width: $breakpoint-tablet) {
      visibility: hidden;
    }

  }
  &__price {
    font-family: $font-secondary;
    color: $color-secondary-3;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
  }
}
