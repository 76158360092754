@import "./../../../styles/shared.scss";

.reviews {
  padding-bottom: 2.5rem;
  &__title {
    font-family: $font-primary;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 0.9375rem;
    color: $color-secondary-3;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    padding-top: 35px;
    padding-bottom: 5px;
  }
  &__more-reviews-wrapper{
    text-align: center;
    position: relative;
    
    &:after{
      width: 100%;
      top:50%;
      display: block;
      content: '';
      height: 1px;
      background-color: $color-principal;
      position: absolute;
      z-index:0;
    }
  }
  &__more-reviews-btn{
    border:0;
    position:relative;
    display: inline-block;
    background-color: $color-secondary-7;
    color: $color-principal;
    padding: 3px 25px;
    z-index: 1;
  }
  &__container {
    display: flex;
    justify-content: flex-start;
    border-bottom: 0.5px solid $color-secondary-4;
    margin-bottom: 16px;
  }
  &__container-image {
    display: flex;
    align-items: top;
    justify-content: center;
    padding-right: 1.5rem;
    &-img {
      width: 4rem;
      height: 4rem;
      border-radius: 120%;
    }
    
    .avatar{
      cursor: default !important;
    }
  }
  &__name {
    font-family: $font-secondary;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: $color-secondary-2;
    margin-bottom: 0.5rem;
  }
  
  &__section-likes {
    background-color: $color-secondary-7;
    color: $color-secondary-3;
    border-radius: 25px;
    padding: .2rem .5rem .2rem .25rem;
    text-align: end;
    display: inline-flex;
    justify-content: flex-end;
    align-items: top;
    font-family: $font-secondary;
    font-size: 12px;
    font-weight: 400;
    gap: .2rem;
    
    span{
      margin-right: 0.3em;
    }
    
    .icon{
      cursor: default !important;
    }
  }
  
  &__info {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  &__info-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: top;
  }
  
  &__message{
    font-family: $font-secondary;
    font-size: 14px;
    font-weight: 400;
    color: $color-secondary-2;
    
    p{
      display: inline-block;
      height: 3em;
      overflow-y: hidden;
      transition: 2s ease;
      word-break: break-all;
    }
    
    &.expanded p{
      height: auto;
    }
  }
  
  &__buttons{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    
    &-read-more{
      border: 0;
      background-color: transparent;
      text-decoration: underline;
      font-family: $font-secondary;
      font-size: 14px;
      font-weight: 500;
      color: $color-secondary-2;
      margin: 0;
      padding: 0
    }
    
    &-date{
      font-family: $font-secondary;
      font-size: 14px;
      font-weight: 400;
      color: $color-secondary-2;
      margin: 0;
    }
  }

  &__container-info {
    display: flex;
    &-works {
      font-family: $font-secondary;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      color: $color-secondary-2;
      margin-bottom: 0;
    }
    &-publication {
      font-family: $font-secondary;
      font-weight: 300;
      font-size: 14px;
      line-height: 23px;
      color: $color-tertiary-4;
      margin-bottom: 0;
    }
  }
}
