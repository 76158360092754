@import "./../../../styles/shared.scss";

.slider-books {
  padding: 0 1rem;
  width: 100%;
  overflow-x: hidden;

  .slick-track {
    display: flex;
    gap: .5rem;
  }

  .slick-list {
    overflow: visible;
  }

  &__item {
    padding-right: 8px;
    max-width: 232px;

    @media (min-width: $breakpoint-tablet) {
      padding-right: 8px;
    }
    cursor: pointer;
    &-container {
      position: relative;
      max-width: 232px;

      @media (min-width: $breakpoint-tablet) {
        width: 232px;
      }
    }
    &-image {
      width: 100%;
      height: 303px;
      border-radius: 10px;
    }

    &-content {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 1rem;
      border-radius: 0px 0px 10px 10px;
      background: linear-gradient(to top, #000000, rgba(0, 0, 0, 0));
      width: 100%;
      &-title {
        color: $color-secondary-6;
        font-size: 1.37rem;
        line-height: 1.5rem;
        font-family: $font-primary;
        margin-bottom: .5rem;
      }
      &-section {
        display: flex;
        &-genre {
          color: $color-secondary-6;
          font-size: 14px;
          font-family: $font-secondary;
          margin-bottom: 0;
        }
        &-sales {
          color: $color-secondary-3;
          font-size: 14px;
          font-family: $font-secondary;
          margin-left: 5px;
          margin-bottom: 0;
        }
      }
    }
  }
  /*
  &__arrow {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease-out;
    opacity: 0;
    height: inherit;
  }
  &:hover > .book-slider__arrow {
    opacity: 1;
    transform: translate(0, -32px);
  }
  */
}
