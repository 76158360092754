@import "./../../../styles/shared.scss";

.select-container {
  display: flex;
  flex-direction: column;
  position: relative;

  &__select-caret {
    position: absolute;
    right: 1.5em;
    top: calc(50% + .5rem);
    margin-top: auto;
    margin-bottom: auto;
    z-index:0;
    transform: translateY(calc(-50% - .25rem)) scale(.7);
  }
}


.select-container__select {
  cursor: pointer;
  transition: border-color 0.3s ease;
  border-radius: 2rem;
  outline: none;
  border: 1px solid $color-secondary-2;
  background-color: transparent !important;
  margin: .5rem 0 0;
  padding: 1rem 1.5rem;
  width: 100%;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  font-family: $font-secondary;
  color: $color-secondary-3;
  letter-spacing: 1px;
  z-index: 99;
  appearance: none;
  ::placeholder{
    color: $color-secondary-3;
  }


  &.error {
    border-color: red;
  }

  &.active {
    border-color: $color-principal;
    color: $color-secondary-3;
  }
}
