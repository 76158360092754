@import "./../../../styles/shared.scss";

.modal-publish-book-container {
  .modal-content {
    border-radius: 20px;
    background-color: $color-secondary-1 !important;
  }
  @media (max-width: $breakpoint-tablet) {
    .modal-body {
      padding: 2.5rem 1rem 1rem;
    }
  }

  display: flex;
  flex-direction: column;
  @media (min-width: $breakpoint-tablet) {
    display: inherit;
  }

  .modal-dialog-centered {
    @media (max-width: $breakpoint-tablet) {
      margin: 0;
    }
  }

  .modal-content {
    @media (max-width: $breakpoint-tablet) {
      min-height: 100vh;
      border-radius: 0;
    }
  }

  .modal-body {
    position: relative;
    flex: 1 1 auto;
    @media (min-width: $breakpoint-tablet) {
      padding: 8rem;
    }
  }
}

.modal-publish-book {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: $breakpoint-tablet) {
    height: calc(100vh - 5rem);
  }

  &__title{
    font-family: $font-primary;
    font-size: 55px;
    font-weight: 400;
    color: $color-secondary-7;
    padding-top: 3rem;
    margin-bottom: 0;

    @media (max-width: $breakpoint-tablet) {
      font-size: 2.18rem;
    }

  }
  &__subtitle{
    font-family: $font-primary;
    font-size: 35px;
    font-weight: 400;
    color: $color-secondary-7;
    padding-top: 20px;
    margin-bottom: 0;

    @media (max-width: $breakpoint-tablet) {
      font-size: 1.37rem;
    }
  }
  &__text{
    width: 72%;
    font-family: $font-secondary;
    font-size: 16px;
    font-weight: 400;
    color: $color-secondary-7;
    padding-top: 24px;
    text-align: center;
  }

  &__message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    gap: .5rem;

    .btn {
      flex: 1;
      padding: 1rem;
    }
  }

  .icon {
    width: 40px;
  }

}
