@import "./../../../styles/shared.scss";

.check-box {
  align-items: center;
  display: flex;

  &__input{
    border-radius: .25rem;
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
    cursor: pointer;
    appearance: none;
    border: 1px solid $color-secondary-2;
    transition: background-color .2s ease, border-color .2s ease;


    &:after {
      content: '';
      width: 1rem;
      height: 1rem;
      background-image: url("../../../styles/icons/tick.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      filter: invert(1);
      transform: rotate(20deg);
      transition: transform .2s ease-in;

      @media (max-width: $breakpoint-tablet) {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    @media (max-width: $breakpoint-tablet) {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  &__text{
    font-family: $font-secondary;
    color: $color-secondary-2;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;

    a{
      color: $color-secondary-2;
      text-decoration: underline;
    }
  }

  .link-terms {
    text-decoration: underline;
    cursor: pointer;
  }
}

.check-box__input:checked {
  accent-color: $color-principal;
  border-color: $color-principal;
  background-color: $color-principal;
  position: relative;

  &:after {
    transform: rotate(0);
  }

}
