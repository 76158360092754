@import "../../../../styles/shared.scss";

.authors-public {
    display: grid;
    gap: .5rem;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    padding-bottom: 70px;
    width: 100%;
    margin-top: 20px;
    padding: 0 .5rem;
    @media (min-width: $breakpoint-tablet) {
        padding-right: 8px;
        padding-left: 8px;
      }
}
