@import "./../../styles/shared.scss";

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  box-shadow: 2px 2px 20px 5px #efefef;
  border-radius: 15px;
  min-width: 250px;
  max-width: 720px;
  margin: 5% auto;

  @media (max-width: $breakpoint-tablet) {
    box-shadow: 0 0 0 0;
    margin: 0;
    max-width: 100vw;
    min-width: 100%;
  }

  &__icon {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: -20px;
  }
  &__logo {
    width: 56px;
    margin-top: 50px;

    @media (max-width: $breakpoint-tablet) {
      margin-top: 17px;
    }
  }
  &__title {
    margin: 1rem auto 2rem;
    max-width: 300px;
    text-align: center;
    font-family: $font-primary;
    font-size: 35px;
    line-height: 42px;
    font-weight: 400;
    color: $color-secondary-2;

    @media (min-width: $breakpoint-tablet) {
      font-size: 30px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 10px;
    }
  }


  &__top-actions {
    @media (max-width: $breakpoint-tablet) {
      margin: 0;
      padding: 12px;
    }
  }

  &__close-icon {
    @media (max-width: $breakpoint-tablet) {
      margin: 0;
    }
  }
}
