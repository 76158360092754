@import "./../../../styles/shared.scss";

.shopping-item {
  border-bottom:  1px solid $color-secondary-4;
  &:last-of-type {
    border-bottom: none;
  }
  &__container {
    background-color: $color-secondary-7;
    padding-bottom: 15px;
    margin-top: 25px;
    &-component {
      display: flex;
      flex-direction: row;
      &-image {
        width: 160px;
        height: auto;
        border-radius: 10px;
      }
      &-component {
        padding-left: 25px;

        @media (max-width: $breakpoint-tablet) {
          padding-left: 1rem;
        }
      }
      &-title {
        font-family: $font-primary;
        font-size: 22px;
        font-weight: 400;
        color: $color-secondary-2;
        line-height: 29px;
      }
      &-section {
        display: flex;
        flex-direction: row;
        &-sales {
          color: $color-secondary-3;
          font-family: $font-secondary;
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
        }
        &-date {
          color: $color-secondary-4;
          font-family: $font-secondary;
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
          margin-left: 5px;
        }
      }
      &-info-component {
        display: flex;
        flex-direction: column;
        @media (min-width: $breakpoint-tablet) {
          flex-direction: row;
        }
      }
      &-info {
        color: $color-secondary-3;
        font-size: 14px;
        line-height: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    &-btn-colocation{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      @media (max-width: $breakpoint-tablet) {
        align-items: flex-start;

        .btn {
          margin-left: 1rem;
        }
      }
      @media (min-width: $breakpoint-tablet) {
        flex-direction: row;
      }

      &-buttons {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @media (max-width: $breakpoint-tablet) {
          .download-btn {
             display: none;
          }
        }

        &-likes {
          display: flex;
          justify-content: flex-end;

          button {
            padding: 8px;
            border: 0;
            border-radius: 22px;
            background: #FFFFFF;
            color: $color-secondary-3;
            margin-left: 8px;
            text-decoration: underline;
            display: inline-flex;
            flex-direction: row;
            align-items: center;
          }
        }
      }
    }

    &-dot-menu {
      display: none;
    }

    @media (max-width: $breakpoint-tablet) {
      &-dot-menu {
        display: block;

        button {
          border: 0;
          background: none;
        }
      }
    }

    &-component-button{
      background-color: $color-secondary-7;
      border-radius: 32px;
      border: none;
      border: 1px solid $color-principal;
      color: $color-principal;
      font-size: 14px;
      line-height: 20px;
      margin-left: 5px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 20px;
      padding-right: 25px;
      margin-right: 20px;
    }
  }
}
