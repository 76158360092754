@import "./../../../styles/shared.scss";

.modal-recover-password {
  @media (max-width: $breakpoint-tablet) {
    &__modal {
      margin: 0;
      align-items: flex-end;
      min-height: 100%;

      .modal-content {
        border-radius: 0;
        border: none;
      }

      .modal-body {
        padding: 1rem 1rem 2rem;
      }

      .btn-orange {
        background-color: $color-principal;
        color: $color-secondary-7;
      }
    }
  }

  &__title {
    font-family: $font-primary;
    color: $color-secondary-1;
    font-size: 26px;
    line-height: 38px;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @media (max-width: $breakpoint-tablet) {
      margin-bottom: .5rem;
    }
  }
  &__text {
    font-family: $font-secondary;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: $color-secondary-3;
    margin-bottom: 30px;
    text-align: center;

    @media (max-width: $breakpoint-tablet) {
      margin-bottom: 1.5rem;
    }

    a{ color: $color-principal; }
  }
  &__container-button {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;

    @media (max-width: $breakpoint-tablet) {
      .btn-border-orange {
        max-width: 12rem;
      }
    }
  }
}
