@import "./../../styles/shared.scss";

.events {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  margin-top: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }

  &__load-more {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 9rem;

    @media (max-width: $breakpoint-tablet) {
      margin-bottom: 5rem;
    }

    .btn {
      width: unset;

      @media (max-width: $breakpoint-tablet) {
        background-color: $color-secondary-1;
        color: $color-secondary-7;
      }
    }
  }
}
