@import "./../../styles/shared.scss";

.blog-list {
  &__title {
    font-size: 2.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: $font-primary;
    color: $color-tertiary-1;
    margin-top: 9rem;
    margin-bottom: 3.5rem;

    @media (max-width: $breakpoint-tablet) {
      font-size: 1.75rem;
      margin-top: 3rem;
      margin-bottom: 1.5rem;
      padding: 0 .5rem;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;

    .blog-item {
      flex: calc(100%/3 - .5rem);
      max-width: calc(100%/3 - .5rem);
      margin-bottom: 2rem;

      @media (max-width: $breakpoint-desktop) {
        flex: calc(100%/2 - .5rem);
        max-width: calc(100%/2 - .5rem);
      }

      @media (max-width: $breakpoint-tablet) {
        flex: 100%;
        max-width: 100%;
      }
    }
  }

  &__item {
    flex: calc(100%/3 - .5rem);
    max-width: calc(100%/3 - .5rem);
    margin-bottom: 2rem;

    @media (max-width: $breakpoint-desktop) {
      flex: calc(100%/2 - .5rem);
      max-width: calc(100%/2 - .5rem);
    }

    @media (max-width: $breakpoint-tablet) {
      flex: 100%;
      max-width: 100%;
    }
  }

  &__load {
    margin-top: 2rem;
    display: flex;
    justify-content: center;

    @media (max-width: $breakpoint-tablet) {
      margin-top: 1rem;
      .btn {
        width: auto;
      }
    }
  }
}
