@import "./../../../styles/shared.scss";

.book-info-tag {
  background-color: $color-secondary-7;
  padding: 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: 500px;
  ::placeholder {
    color: $color-secondary-3;
  }

  @media (max-width: $breakpoint-tablet) {
    padding: 0 1rem 3rem 1rem;
  }
  &__label {
    margin-top: 20px;
    font-family: $font-primary;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: $color-secondary-2;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  &__form-input {
    border-radius: 20px;
    outline: none;
    border: 1px solid $color-secondary-3;
    margin-top: 10px;
    padding: 10px;
    width: 95%;
    box-sizing: content-box;
    cursor: pointer;
  }
  &__button {
    border-radius: 20px;
    outline: none;
    border: 1px solid $color-secondary-1;
    color: $color-secondary-1;
    margin-top: 10px;
    padding: 10px;
    background-color: $color-secondary-7;
    box-sizing: content-box;
    cursor: pointer;
    margin-bottom: 50px;
    width: 130px;
  }

  &__section-checkbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 70px;

    .check-box {
      margin-top: .12rem;
    }

    &-text{
      font-size: 12px;
      color: $color-secondary-1;
      font-family: $font-secondary;
    }

    &-link {
      color: $color-principal;
    }
  }

  .genres {
    width: 100%;
  }

  .btn {
    max-width: 6.5rem;
  }
}
