@import "../../../../styles/shared.scss";

.mission-vision {
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top:50px;
  @media (min-width: $breakpoint-tablet) {
    margin-top:0px;
  }

  &__container-mission {
    display: flex;
    flex-direction: column-reverse;
    @media (min-width: $breakpoint-tablet) {
      display: flex;
      flex-direction: row;
    }

    &-section-text {
      margin: 0px 0px 70px 0px;
      padding: 40px 20px ;
      border-radius: 0px 0px 10px 10px;
      background-color: $color-secondary-1;
      color: $color-secondary-7;
      font-family: $font-secondary;
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height: 400px;
      @media (min-width: $breakpoint-tablet) {
        width: 60%;
        margin: 5px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 600px;
        padding: 40px;
      }
    }
    &-title {
      font-size: 40px;
      font-weight: 500;
      line-height: 45px;
    }
    &-text {
      font-size: 20px;
      font-weight: 300;
      line-height: 30px;
    }
    &-section-image {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      border-radius: 10px 10px 0px 0px;
      margin: 0px;
      background-color: $color-principal;
      width: 100%;
      @media (min-width: $breakpoint-tablet) {
        margin: 5px;
        border-radius: 10px;
      }
      &-img {
        width: 50%;
        min-width: 400px;
      }
    }
  }

  &__container-vision {
    display: flex;
    flex-direction: column;
    @media (min-width: $breakpoint-tablet) {
      display: flex;
      flex-direction: row;
    }

    &-section-text {
      margin: 0px;
      padding: 40px 20px ;
      border-radius: 0px 0px 10px 10px;
      background-color: $color-secondary-1;
      color: $color-secondary-7;
      font-family: $font-secondary;
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height: 600px;
      @media (min-width: $breakpoint-tablet) {
        width: 60%;
        margin: 5px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 40px;
      }
    }
    &-title {
      font-size: 40px;
      font-weight: 500;
      line-height: 45px;
    }
    &-text {
      font-size: 20px;
      font-weight: 300;
      line-height: 30px;
    }
    &-section-image {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px 10px 0px 0px;
      margin: 0px;
      background-color: $color-principal;
      width: 100%;
      @media (min-width: $breakpoint-tablet) {
        margin: 5px;
      }
      &-img {
        width: 50%;
        min-width: 400px;
      }
    }
  }
}
