@import "./../../../styles/shared.scss";

.modal-isbn-explanation {
  padding: 4.5rem 5.5rem;

  &-container {
    .modal-body {
      padding: 0;
    }
  }

  @media (max-width: $breakpoint-tablet) {
    padding: 2rem 1rem 1rem;
  }

  &__title {
    font-family: $font-primary;
    color: $color-secondary-2;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  &__text {
    color: $color-secondary-3;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
  }
}
