@import "./../../../styles/shared.scss";

.info-author {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  border-top: 1px solid $color-secondary-4;
  padding: 2.5rem 0;

  @media (min-width: $breakpoint-tablet) {
    margin-left: 130px;
    margin-right: 130px;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__title{
    font-family: $font-primary;
    color: $color-tertiary-1;
    font-size: 22px;
    font-weight: 300;
    margin-bottom: .5rem;

    @media (min-width: $breakpoint-tablet) {
      width: 20rem;
      margin-bottom: 0;
    }

  }

  &__list{
    display: flex;
    flex-direction: row;
    padding-left: 0;
    gap: 1rem;
    margin-bottom: 0;
    margin-left: -.5rem;

    @media (min-width: $breakpoint-tablet) {
      width: 20rem;
      margin-top: -2rem;
    }
    li {
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-item{
      color: $color-secondary-2;
      &:hover{
        color: $color-principal;
      }
    }
  }

  &__text{
    font-family: $font-secondary;
    color: $color-tertiary-1;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.75rem;

    @media (min-width: $breakpoint-tablet) {
      width: calc(100% - 20rem);
    }
  }
}
