@import "./../../styles/shared.scss";

.book-list-all {
  min-height: 300px;
  margin-bottom: 100px;

  @media (max-width: $breakpoint-tablet) {
    margin-bottom: 0;
  }


  &__title {
    font-family: $font-primary;
    font-size: 45px;
    line-height: 54px;
    font-weight: 400;
    color: $color-secondary-2;
    padding-top: 150px;
    padding-bottom: 40px;
    padding-left: 30px;
  }
}
