@import "./../../../styles/shared.scss";

.register {
  width: 90%;
  display: flex;
  justify-content: center;

  @media (max-width: $breakpoint-tablet) {
    width: calc(100vw - 2rem);
  }

  &__form {
    width: 100%;
    align-items: center;
    &-input {
      border-radius: 32px;
      outline: none;
      border: 1px solid $color-secondary-2;
      margin: 10px;
      padding: 10px;
      width: 90%;
      box-sizing: content-box;
    }
  }
  &__btn {
    border-radius: 20px;
    outline: none;
    border: 1px solid $color-secondary-2;
    background-color: $color-secondary-2;
    color: $color-secondary-7;
    margin: 10px;
    padding: 10px;
    width: 90%;
    box-sizing: content-box;
    cursor: pointer;
  }
}
