@import "./../../styles/shared.scss";

.genres {
  display: flex;
  flex-wrap: wrap;
  margin: 15px 15px 30px 0px;

  @media (max-width: $breakpoint-tablet) {
    margin: 0 auto;
    gap: .5rem;

    width: 3400px;
  }

  &--center {
    justify-content: center;
  }

  &--left {
    justify-content: left;
  }
}
