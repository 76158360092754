@import "./../../styles/shared.scss";

.contact-container{

  &__row-title {
    margin-top: 56px;
    margin-bottom: 40px;

    @media (min-width: $breakpoint-tablet) {
      margin-top: 105px;
      margin-bottom: 64px;
    }
  }

  &__row-options {
    margin-bottom: 90px;
    display: flex;
    justify-content: center;

    @media (min-width: $breakpoint-tablet) {
      margin-bottom: 128px;
    }
  }

  &__title, &__subtitle {
    font-family: $font-primary;
    font-size: 35px;
    text-align: center;

    @media (min-width: $breakpoint-tablet) {
      font-size: 60px;
    }
  }

  &__subtitle {
    @media (min-width: $breakpoint-tablet) {
      font-size: 35px;
    }
  }

  &__text {
    font-family: $font-secondary;
    font-size: 16px;
    color: $color-secondary-2;

    @media (min-width: $breakpoint-tablet) {
      font-size: 16px;
    }

    a{
      color: $color-secondary-2;
      text-decoration: none;
    }
  }

  &__option {
    background-color: $color-secondary-6;
    border-radius: 10px;
    &:hover {    
      background-color: $color-secondary-5;

      .icon path {
        fill: $color-principal;
      }
    }
  }
}
