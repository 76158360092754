
//FONTS 
$font-primary: "novela";
$font-secondary: "inter-tight";

//HEADING 1
$heading-1-font: $font-primary;
$heading-1-size: 80px; 

//HEADING 2
$heading-2-font: $font-primary;
$heading-2-size: 45px;
$heading-2-line_height: 52px;

//HEADING 3
$heading-3-font: $font-primary;
$heading-3-size: 35px;
$heading-3-line_height: 42px;

// HEADING 4
$heading-4-font: $font-primary;
$heading-4-size: 22px;
$heading-4-line_height: 29px;

// HEADING 5
$heading-5-font: $font-secondary;
$heading-5-size: 18px;
$heading-5-line_height: 25px;

// SUBHEADING 1
$sub-heading-1-font: $font-primary;
$sub-heading-1-size: 20px;
$sub-heading-1-line_height: 27px;


// SUBHEADING 2
$sub-heading-2-font: $font-primary;
$sub-heading-2-size: 16px;
$sub-heading-1-line_height: 23px;


// PARAGRAPH 1
$paragraph-1-font: $font-secondary;
$paragraph-1-size: 16px;
$paragraph-1-size-line_height: 25px;

// PARAGRAPH 2
$paragraph-2-font: $font-secondary;
$paragraph-2-size: 14px;
$paragraph-2-size-line_height: 24px;

// PARAGRAPH 3
$paragraph-3-font: $font-secondary;
$paragraph-3-size: 12px;
$paragraph-3-size-line_height: 20px;
