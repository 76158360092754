@import "./../../../styles/shared.scss";

.publications {
    background-color: $color-secondary-7;
    border-radius: 10px;
    margin-top: 50px;
    padding: 40px;
    margin-bottom: 50px;

    @media (max-width: $breakpoint-tablet) {
        padding: 1.5rem 1rem;
    }
}
