@import "../../styles/shared.scss";

.event-form {
  p.error { 
    margin-top: 0.5em; 
    margin-left: 0.5em;
  }
  
  &__section {
    margin-bottom: 2.5rem;
    padding-bottom: 2.5rem;

    &-title {
      color: $color-secondary-2;
      font-family: $font-primary;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.6875rem;
    }

    &-body {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem 1.5rem;

      .input__right-item {
        padding: .60rem 1.25rem .60rem 1rem;
      }

      @media (max-width: $breakpoint-tablet) {
        grid-template-columns: 1fr;
        grid-gap: .5rem;
      }

      &-item {
        &--full {
          @media (min-width: $breakpoint-tablet) {
            grid-column: 1 / 3;
          }
        }
      }
    }

  }
  &__input-label {
    font-family: $font-primary;
    color: $color-secondary-2;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.0525rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  &__checkbox {
    margin-bottom: .5rem;
  }

  &__textarea {
    width: 100%;
    padding: .65rem 1.5rem;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    border-radius: .625rem;
    border-color: $color-secondary-2;

    &::placeholder {
      color: $color-secondary-3;
    }
  }

  &__bordered {
    border-bottom: 1px solid $color-secondary-4;
  }
}
