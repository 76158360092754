@import "./../../../styles/shared.scss";
.sales {
  &__container {
    background-color: $color-secondary-7;
    border-radius: 10px;
    padding: 40px;
    margin-top: 50px;

    @media (max-width: $breakpoint-tablet) {
      padding: 1.5rem 1rem;
    }

    &-title {
      color: $color-secondary-2;
      font-family: $font-primary;
      font-size: 22px;
      line-height: 28px;
      font-weight: 400;
      margin-bottom: 25px;
    }
    &-data {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      @media (min-width: $breakpoint-tablet) {
        flex-direction: row;
      }

      @media (max-width: $breakpoint-tablet) {
        flex-direction: row;
        flex-wrap: wrap;
        gap: .5rem
      }
      &-section {
        background-color: $color-secondary-6;
        margin-right: 10px;
        border-radius: 10px;
        padding: 15px;
        text-align: center;
        width: 100%;
        height: 100%;

        @media (max-width: $breakpoint-tablet) {
          margin-right: 0;
          width: calc(50% - .25rem);
        }

        &-number{
          color: $color-secondary-2;
          font-family: $font-secondary;
          font-size: 40px;
          font-weight: 500;
          line-height: 52px;
          margin-bottom: 0px;
        }
        &-text {
          color: $color-secondary-2;
          font-family: $font-secondary;
          font-size: 16px;
          font-weight: 300;
          line-height: 28px;
          margin-bottom: 0px;

        }
      }
    }
  }
  &__component {
    background-color: $color-secondary-7;
    border-radius: 10px;
    padding: 40px;
    margin-bottom:50px;
    margin-top: 10px;

    @media (max-width: $breakpoint-tablet) {
      padding: 1.5rem 1rem;
    }
    &-title{
      color: $color-secondary-2;
      font-family: $font-primary;
      font-size: 22px;
      line-height: 28px;
      font-weight: 400;
      margin-bottom: 25px;
    }
  }
}
