@import "../../styles/shared.scss";

.bundle-course {
  padding: 0 0 80px 0;
  display: flex;

  &__content {
    flex: 1;

    &-title {
      font-family: $font-secondary;
      font-size: 14px;
      color: #979797;
      font-weight: 600;
      line-height: 20px;
    }

    &-heading {
      color: #323232;
      font-family: $font-primary;
      font-size: 35px;
      font-weight: 400;
      line-height: 42px;
      margin: 25px 0 0;
    }

    &-description {
      color: #656565;
      font-family: $font-secondary;
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
      margin: 17px 0 0;
      max-height: 150px;
      overflow: hidden;
    }

    &-items {
      padding: 0;
      margin: 24px 0 0;

      & > li {
        margin-bottom: 8px;
        display: flex;
        gap: 8px;
        align-items: center;
        color: #656565;
        font-family: $font-secondary;
        font-size: 16px;
        line-height: 28px;
        font-weight: 400;

        & > span:first-child {
          display: inline-block;
          background: #f7f7f7;
          border-radius: 25px;
          width: 24px;
          height: 24px;
          padding: 4px;

          & > svg {
            vertical-align: top;
            width: 16px;
            height: 16px;

            & > path {
              stroke: #F05A24;
              fill: #F05A42;
            }
          }
        }
      }
    }

    &-pricing {
      display: flex;
      justify-content: space-between;
      margin: 24px 0 0;
      font-family: $font-secondary;
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
      color: #323232;

      & > span:last-child {
        color: #656565;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        display: flex;
        align-items: center;
        align-content:flex-end;
        flex-wrap: wrap;
        gap: 8px;

        & > svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  &__image {
    flex: 1;
    display: flex;
    flex-direction: row-reverse;

    & > img {
      align-self: flex-start;
      border-radius: 10px;
      max-width: 80%;
    }
  }

  &__title-mobile {
    display: none;
  }
}

@media (max-width: $breakpoint-tablet) {
  .bundle-course {
    flex-direction: column-reverse;
    gap: 16px;

    &__content {
      &-title {
        display: none;
      }

      &-heading {
        margin-top: 8px;
        font-size: 25px;
        line-height: 29.374px;
      }

      &-description {
        margin-top: 16px;
      }

      &-items {
        margin-top: 16px;
      }

      &-pricing {
        & > span:last-child {
          display: none;
        }
      }
    }

    &__image {
      display: block;

      & > img {
        max-width: 100%;
      }
    }

    &__title-mobile {
      display: flex;
      justify-content: space-between;

      & > span {
        font-family: $font-secondary;
        color: #979797;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
      }

      & > span:last-child {
        line-height: 23.5px;
        display: flex;
        align-items: center;
        align-content:flex-end;
        flex-wrap: wrap;
        gap: 4px;

        & > svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
