@import "./../../../styles/shared.scss";

.upload-cover {
  background-color: $color-secondary-7;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1.5rem;
  position: relative;

  ::placeholder {
    color: $color-secondary-3;
  }

  @media (min-width: $breakpoint-tablet) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3.5rem 1rem;
    gap: 0;
    height: 100%;
  }

  &__icon {
    width: 5.5rem;
    border-radius: .625rem;
    background-color: $color-secondary-6;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: $breakpoint-tablet) {
      background-color: $color-secondary-7;
    }
  }

  &__content {
    margin-top: 1rem;
    flex: 1;

    @media (min-width: $breakpoint-tablet) {
      flex: unset;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }

  &__title {
    font-family: $font-primary;
    color: $color-secondary-2;
    font-size: 25px;

    &.ellipsis {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__text {
    font-family: $font-secondary;
    color: $color-secondary-3;
    font-size: 13px;
  }

  &__text-error {
    font-style: italic;
    color: red;
  }

  &__action {
    max-width: 11rem;
  }

  &__cover-info {
    // width: 5.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    flex: 1;
    @media (max-width: $breakpoint-tablet) {
      .btn.btn-white-transparent {
        color: $color-secondary-7;
        border-color: $color-secondary-1;
        background-color: $color-secondary-1;
        &:hover {
          color: $color-secondary-7;
          border-color: $color-secondary-1;
        }
      }
    }

    @media (min-width: $breakpoint-tablet) {
      align-items: center;
      max-width: calc(100% - 2rem);
      width: 22rem;
      margin: 0 auto;
      text-align: center;
      flex: unset;
    }
  }


  @media (min-width: $breakpoint-tablet) {
    .white {
      color: $color-secondary-7;
    }
  }

  &__cover-preview{
    width: 5.5rem;
    max-width: 100%;
    border-radius: .625rem;
    overflow: hidden;

    @media (min-width: $breakpoint-tablet) {
      max-width: calc(100% - 2rem);
      width: 22rem;
      position: absolute;
      height: calc(100% - 7rem);
      z-index: 1;
      opacity: 1;
    }

    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
      filter: brightness(60%);
    }
  }
}
