@import "./../../../styles/shared.scss";

.button {
    background-color: white;
    outline: none;
    border: 1px solid $color-secondary-1;
    border-radius: 32px;
    margin: 6px 0 50px;
    padding: 10px;
    width: calc(100% - 20px);
    box-sizing: content-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
        margin-right: .5rem;
    }
}
