@import "./../../../styles/shared.scss";

.btn {
  font-size: 16px;
  align-items: center;

  a.btn-white{ 
    text-decoration: none; 
    color: $color-secondary-7;
  }

  &.btn-white {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    border-radius: 32px;
    border: 1px solid $color-secondary-1;
    background-color: transparent;
    padding: 12px 24px 12px 24px;
    font-family: $font-secondary;

    .icon,
    svg {
      width: 16px;
      height: 16px;
    }

    &.big {
      align-items: center;
      padding: 16px 20px 16px 20px;

      .icon,
      svg {
        width: 20px;
        height: 20px;
      }
    }

    &:hover {
      background-color: $color-secondary-1;
      border: 1px solid $color-secondary-1;
      color: $color-secondary-7;

      .btn-icon {
        fill: $color-secondary-7;

        path {
          fill: $color-secondary-7;
        }
      }
    }

    .btn-icon {
      fill: $color-secondary-1;
      display: inline-block;

      &.left {
        margin-right: 8px;
      }
      &.right {
        margin-left: 8px;
      }

      path {
        fill: $color-secondary-1;
      }
    }


    //@media (max-width: $breakpoint-tablet) {
    //  background-color: $color-secondary-1;
    //  border: 1px solid $color-secondary-1;
    //  color: $color-secondary-7;
    //
    //  .btn-icon {
    //    fill: $color-secondary-7;
    //
    //    path {
    //      fill: $color-secondary-7;
    //    }
    //  }
    //}
  }

  @media (min-width: $breakpoint-tablet) {
    &.btn-white {
      width: auto;
    }
  }

  &.btn-white-transparent {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    border-radius: 32px;
    border: 1px solid $color-secondary-7;
    background-color: transparent;
    color: $color-secondary-7;
    padding: 12px 24px 12px 24px;

    .icon,
    svg {
      width: 16px;
      height: 16px;
    }

    &.big {
      align-items: center;
      padding: 16px 20px 16px 20px;

      .icon,
      svg {
        width: 20px;
        height: 20px;
      }
    }

    &:hover {
      background-color: $color-secondary-7;
      border: 1px solid $color-secondary-7;
      color: $color-secondary-1;

      .btn-icon {
        fill: $color-secondary-1;

        path {
          fill: $color-secondary-1;
        }
      }
    }

    .btn-icon {
      fill: $color-secondary-7;
      display: inline-block;

      &.left {
        margin-right: 8px;
      }
      &.right {
        margin-left: 8px;
      }

      path {
        fill: $color-secondary-7;
      }
    }
  }

  @media (min-width: $breakpoint-tablet) {
    &.btn-white-transparent {
      width: auto;
    }
  }

  &.btn-simple {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    border-radius: 32px;
    background-color: transparent;
    outline: none;
    color: $color-secondary-1;
    padding: 12px 24px 12px 24px;

    .btn-icon path {
      fill: $color-secondary-1;
    }
  }

  @media (min-width: $breakpoint-tablet) {
    &.btn-orange {
      width: auto;
    }
  }


  &.btn-orange {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    border-radius: 32px;
    border: 1px solid $color-principal;
    background-color: transparent;
    color: $color-principal;
    padding: 12px 24px 12px 24px;

    .icon,
    svg {
      width: 16px;
      height: 16px;
    }

    &.big {
      align-items: center;
      padding: 16px 20px 16px 20px;

      .icon,
      svg {
        width: 20px;
        height: 20px;
      }
    }

    &:hover {
      background-color: $color-principal;
      border: 1px solid $color-principal;
      color: $color-secondary-7;

      .btn-icon {
        fill: $color-secondary-7;

        path {
          fill: $color-secondary-7;
        }
      }
    }

    .btn-icon {
      fill: $color-principal;

      &.left {
        margin-right: 8px;
      }
      &.right {
        margin-left: 8px;
      }

      path {
        fill: $color-principal;
      }
    }
  }

  @media (min-width: $breakpoint-tablet) {
    &.btn-orange {
      width: auto;
    }
  }

  &.btn-orangebg {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    border-radius: 32px;
    border: 1px solid $color-principal;
    background-color: $color-principal;
    color: $color-secondary-7;
    padding: 12px 24px 12px 24px;

    .icon,
    svg {
      width: 16px;
      height: 16px;
    }

    &.big {
      align-items: center;
      padding: 16px 20px 16px 20px;

      .icon,
      svg {
        width: 20px;
        height: 20px;
      }
    }

    &:hover {
      background-color: $color-principal;
      border: 1px solid $color-principal;
      color: $color-secondary-7;

      .btn-icon {
        fill: $color-secondary-7;

        path {
          fill: $color-secondary-7;
        }
      }
    }

    .btn-icon {
      fill: $color-secondary-7;

      &.left {
        margin-right: 8px;
      }
      &.right {
        margin-left: 8px;
      }

      path {
        fill: $color-secondary-7;
      }
    }
  }

  @media (min-width: $breakpoint-tablet) {
    &.btn-orangebg {
      width: auto;
    }
  }

  &.btn-gray {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    border-radius: 32px;
    border: 1px solid $color-secondary-3;
    background-color: transparent;
    color: $color-secondary-3;
    padding: 12px 24px 12px 24px;

    .icon,
    svg {
      width: 16px;
      height: 16px;
    }

    &.big {
      align-items: center;
      padding: 16px 20px 16px 20px;

      .icon,
      svg {
        width: 20px;
        height: 20px;
      }
    }

    &:hover {
      background-color: $color-secondary-3;
      border: 1px solid $color-secondary-3;
      color: $color-secondary-7;

      .btn-icon {
        fill: $color-secondary-7;

        path {
          fill: $color-secondary-7;
        }
      }
    }

    .btn-icon {
      fill: $color-secondary-3;

      &.left {
        margin-top: 2px;
        margin-right: 8px;
      }
      &.right {
        margin-top: 2px;
        margin-left: 8px;
      }

      path {
        fill: $color-secondary-3;
      }
    }
  }

  @media (min-width: $breakpoint-tablet) {
    &.btn-gray {
      width: auto;
    }
  }

  &.btn-graybg {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    border-radius: 32px;
    border: 1px solid $color-secondary-3;
    background-color: $color-secondary-3;
    color: $color-secondary-7;
    padding: 12px 24px 12px 24px;

    .icon,
    svg {
      width: 16px;
      height: 16px;
    }

    &.big {
      align-items: center;
      padding: 16px 20px 16px 20px;

      .icon,
      svg {
        width: 20px;
        height: 20px;
      }
    }

    &:hover {
      background-color: $color-secondary-3;
      border: 1px solid $color-secondary-3;
      color: $color-secondary-7;

      .btn-icon {
        fill: $color-secondary-7;

        path {
          fill: $color-secondary-7;
        }
      }
    }

    .btn-icon {
      fill: $color-secondary-7;

      &.left {
        margin-right: 8px;
      }
      &.right {
        margin-left: 8px;
      }

      path {
        fill: $color-secondary-7;
      }
    }
  }

  @media (min-width: $breakpoint-tablet) {
    &.btn-graybg {
      width: auto;
    }
  }

  &.btn-blackbg {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    border-radius: 32px;
    border: 1px solid $color-secondary-1;
    background-color: $color-secondary-1;
    color: $color-secondary-7;
    padding: 12px 24px 12px 24px;

    a{ 
      text-decoration: none; 
      color: $color-secondary-7;
    }

    .icon,
    svg {
      width: 16px;
      height: 16px;
    }

    &.big {
      align-items: center;
      padding: 16px 20px 16px 20px;

      @media (max-width: 400px) {
        padding: 1rem;
      }

      .icon,
      svg {
        width: 20px;
        height: 20px;
      }
    }

    &:hover {
      background-color: $color-secondary-1;
      border: 1px solid $color-secondary-1;
      color: $color-secondary-7;

      .btn-icon {
        fill: $color-secondary-7;

        path {
          fill: $color-secondary-7;
        }
      }
    }

    .btn-icon {
      fill: $color-secondary-7;

      &.left {
        margin-right: 8px;
      }
      &.right {
        margin-left: 8px;
      }

      path {
        fill: $color-secondary-7;
      }
    }
  }


  &.btn-black-border {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 32px;
    border: 1px solid $color-secondary-1;
    background-color: transparent;
    color: $color-secondary-1;
    padding: 12px 24px 12px 24px;

    .icon,
    svg {
      width: 16px;
      height: 16px;
    }

    &.big {
      align-items: center;
      padding: 16px 20px 16px 20px;

      .icon,
      svg {
        width: 20px;
        height: 20px;
      }
    }

    &:hover {
      background-color: $color-secondary-1;
      border: 1px solid $color-secondary-1;
      color: $color-secondary-7;

      .btn-icon {
        fill: $color-secondary-7;

        path {
          fill: $color-secondary-7;
        }
      }
    }

    .btn-icon {
      fill: $color-secondary-7;

      &.left {
        margin-right: 8px;
      }
      &.right {
        margin-left: 8px;
      }

      path {
        fill: $color-secondary-7;
      }
    }
  }



  @media (min-width: $breakpoint-tablet) {
    &.btn-graybg {
      width: auto;
    }
  }

  &.btn-small {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    border-radius: 32px;
    border: 1px solid $color-secondary-1;
    background-color: transparent;
    padding: 10px 30px 10px 30px;
    &:hover {
      background-color: $color-secondary-1;
      border: 1px solid $color-secondary-1;
      color: $color-secondary-7;
    }
  }
}
.btn-border-orange {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  border-radius: 32px;
  border: 1px solid $color-principal;
  background-color: transparent;
  color: $color-principal;
  padding: 12px 24px 12px 24px;
  &:hover {
    background-color: $color-principal;
    border: 1px solid $color-principal;
    color: $color-secondary-7;
  }
  .btn-icon {
    fill: $color-principal;

    &.left {
      margin-top: 2px;
      margin-right: 8px;
    }
    &.right {
      margin-top: 2px;
      margin-left: 8px;
    }

    path {
      fill: $color-principal;
    }
  }
  .icon,
  svg {
    width: 16px;
    height: 16px;
  }
}

.button-delete{
  background-color: $color-tertiary-5;
  border: 1px solid $color-secondary-3;
  outline: none;
  font-family: $font-secondary;
  color: $color-secondary-3;
  padding: 15px 20px;
  border-radius: 32px;
  width: 50%;
}
