@import "../../../../styles/shared.scss";

.faq-wrapper{
  border: 1px solid $color-secondary-4;
  border-radius: 10px;
  margin-bottom: 24px;

  @media (max-width: $breakpoint-tablet) {
    margin-bottom: 1rem;
  }
}

.faq-question {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  padding: 26px;
  z-index: 999;

  .icon {
    margin-top: .4rem;
  }

  @media (max-width: $breakpoint-tablet) {
    padding: 1rem;
  }

  h2{
    font-family: $font-primary;
    font-size: 22px;
    line-height: 1.25em;
    margin: 0;
  }
}

.faq-answer {
  font-family: $font-secondary;
  font-size: 16px;
  line-height: 1.6em;
  color: $color-secondary-2;
  padding: 0 26px 26px 26px;

  &.closed{
    transform: translateY(-2000px) translateZ(0);
    opacity:0;
    height: 0;
    padding: 0;
    transition: opacity .1s ease 0s, transform 0s linear .9s;
  }

  &.open{
    transform: translateY(0px);
    opacity:1;
    transition: top 0s linear 0s, opacity 1s ease .01s;
  }
}
