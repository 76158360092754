@import "./../../../../styles/shared.scss";

.slider-books-public {
  width: 100%;
  

    &__section-image{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: $color-secondary-6;
      border-radius: 10px;
      padding: 30px;
      margin: 10px;
      &:hover {
        background-color: $color-secondary-5;
      }
      &:hover .slider-books-public__arrow {
        opacity: 1;
      }

      &-title {
        font-family: $font-primary;
        color: $color-secondary-1;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        margin-top: 20px;
        margin-bottom: 10px;
  
        @media (min-width: $breakpoint-tablet) {
          font-size: 22px;
          line-height: 29px;
        }
      }
  
      &-author {
        color: $color-secondary-2;
        font-weight: 300;
        font-size: 12px;
        line-height: 20px;
        text-align: center;

  
        @media (min-width: $breakpoint-tablet) {
          font-size: 14px;
          line-height: 23px;
        }
      }
      &-price {
        color: $color-secondary-3;
        font-family: $font-secondary;
        font-size: 18px;
      }

    }
    &__arrow {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-out;
      opacity: 0;
      height: inherit;
    }
  
  
}
