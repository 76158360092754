@import "./../../../styles/shared.scss";

.form-publish-book {
  padding: 4rem .5rem 0;
  width: 100%;
  max-width: 70rem;
  margin: 0 auto;
  @media (min-width: $breakpoint-tablet) {
    padding: 0 .5rem;
  }

  &__header {
    padding: .75rem .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    width: 100%;
    top: 0;
    left: 0;
    background-color: $color-secondary-6;

    @media (min-width: $breakpoint-tablet) {
      position: relative;
      top: unset;
      left: unset;
      background-color: transparent;
      width: unset;
      justify-content: flex-end;
      padding: 4rem 0 0;
      margin-right: -4rem;
      margin-bottom: -.5rem;

      .back {
        display: none;
      }
    }
  }

  &__header-title {
    margin-bottom: 0;
    text-align: center;
    width: 100%;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5625rem;

    @media (min-width: $breakpoint-tablet) {
      display: none;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-bottom: 8rem;

    @media (min-width: $breakpoint-tablet) {
      flex-direction: row;
      margin-bottom: 1rem;
    }
  }

  @media (min-width: $breakpoint-tablet) {
    &__content-item {
      flex: 50%;
    }
  }

  &__position-bar {
    position: fixed;
    bottom: 1.5rem;
    padding: .5rem .5rem .5rem 2rem;
    display: flex;
    background-color: $color-secondary-4;
    border-radius: 3rem;
    width: calc(100% - 1rem);

    @media (min-width: $breakpoint-tablet) {
      position: relative;
      width: 100%;
      bottom: 0;
    }
  }

  &__step {
    display: flex;
    align-items: center;
    width: 100%;

    .btn {
      width: unset;
    }
  }

  &__step-text {
    font-family: $font-primary;
    white-space: nowrap;
    margin-bottom: 0;
    flex: 1;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4375rem;
  }

  &__button-return {
    background-color: $color-secondary-4;
    padding: 10px 15px 10px 15px;
    color: $color-secondary-2;
    cursor: pointer;
    outline: none;
    border: none;
    display: none;
    margin-right: 2rem;

    @media (min-width: $breakpoint-tablet) {
      display: inline-block;
    }
  }
}
