@import "./../../styles/shared.scss";

.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__img {
    width: 100%;
    height: 100vh;
  }
  &__section-text {
    position: absolute;
    top: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__title {
    font-family: $font-primary;
    font-size: 45px;
    color: $color-secondary-1;
    padding-top: 20px;
  }
  &__text {
    font-family: $font-primary;
    font-size: 28px;
    color: $color-secondary-2;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
