@import "./../../../styles/shared.scss";

.book-public {
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  height: 528px;
  width: 330px;
  border-radius: 10px;
  margin: 5px;
  padding: 16px;
  font-family: $font-primary;
  background-color: $color-secondary-6;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: $color-secondary-5;
  }
  &:hover .book-public__arrow {
    opacity: 1;
  }
  @media (min-width: $breakpoint-tablet) {
    width: auto;
  }

  &__section-header {
    text-align: end;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }

  &__section-download {
    background-color: $color-secondary-7;
    color: $color-secondary-3;
    border-radius: 25px;
    padding: 8px 13px;
    text-align: end;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: $font-secondary;
    font-size: 12px;
    font-weight: 400;

    &-text {
      margin-left: 5px;
    }
  }
  &__section-image {
    &-img {
      width: 232px;
      height: 304px;
      box-shadow: 0px 0px 10px 4px rgba(126, 126, 126, 0.2);
    }
    &-title {
      font-family: $font-primary;
      color: $color-secondary-2;
      font-size: 22px;
      font-weight: 400;
      line-height: 29px;
      margin-top: 10px;
      margin-bottom: 3px;
    }
    &-author {
      color: $color-secondary-3;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      margin-bottom: 0px;
    }
  }
  &__section-genres {
    box-sizing: content-box;
    display: flex;
    place-self: flex-start;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-top: 24px;

    &-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    &-button {
      padding: 2px 20px 8px 20px;
      margin: 5px 5px;
      border: 1.3px solid $color-secondary-3;
      border-radius: 25px;
      font-family: $font-secondary;
      font-weight: 800;
      font-size: 14px;
      line-height: 24px;
      color: $color-secondary-3;
    }
    &-price {
      color: $color-secondary-3;
      font-family: $font-secondary;
      font-size: 18px;
      margin-right: 15px;
    }
  }

  &__arrow {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-out;
    opacity: 0;
    height: inherit;
  }

  &:hover > .book__arrow {
    opacity: 1;
    transform: translate(0, -32px);
  }
}
