@import "./../../../styles/shared.scss";

.isbn-selector {
  background-color: $color-secondary-7;
  width: 100%;
  border-radius: .625rem;
  padding: 2.5rem;

  @media (max-width: $breakpoint-tablet) {
    padding: 2rem 1rem;
  }

  &__title {
    font-family: $font-primary;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    color: $color-secondary-2;
    margin-bottom: 2.5rem;

    @media (max-width: $breakpoint-tablet) {
      margin-bottom: 1.5rem;
    }

    &-tooltip {
      color: $color-secondary-3;
      text-decoration: underline;

      .icon {
        display: inline-block;
        transform: translateY(-.625rem) scale(.9);
      }
    }
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }

  &__input {
    max-width: 29rem;
    margin-top: 1.5rem;
  }

  &__input-radio {
    border: 1px solid $color-secondary-5;
    border-radius: .625rem;
    padding: 2rem 2.5rem;
    font-family: $font-primary;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    min-height: 3rem;

    @media (max-width: $breakpoint-tablet) {
      padding: 1rem;
    }

    &:has(input:checked) {
      border: 1px solid $color-secondary-4;
    }

    label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    p {
      margin: 0;
      padding: 0;
      flex: 1;
    }

    input[type="radio"] {
      margin-right: 1rem;
      width: 1rem;
      height: 1rem;
      appearance: none;
      border-radius: .625rem;
      border: 1px solid $color-secondary-3;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: .7rem;
        height: .7rem;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        transition: all .1s ease-in-out;
      }

      &:checked {
        border: 1px solid $color-principal;
        position: relative;

        &::after {
          background-color: $color-principal;
        }
      }
    }
  }

  &__price {
    justify-self: flex-end;
  }
}
