@import "./../../../styles/shared.scss";

.isbn-payment {
  display: flex;
  width: 100%;
  border-radius: .625rem;
  overflow: hidden;
  background-color: $color-secondary-7;

  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
  }

  &__logo-header {
    filter: grayscale(100%) brightness(0) invert(1);
    width: 2.38rem;
    margin-bottom: 2rem;
  }

  &__section {
    flex: 1;
    padding: 4rem;

    @media (max-width: $breakpoint-tablet) {
      padding: 2rem 1rem;
    }

    &--dark {
      background-color: $color-secondary-2;
      color: $color-secondary-7;
    }
  }

  &__title {
    font-size: 1rem;
    margin: 0 0 .5rem;
    color: $color-secondary-5;
  }

  &__title-price {
    font-size: 2.5rem;
    margin: 0 0 2rem;
  }

  &__summary {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: $color-secondary-4;
    padding-bottom: .5rem;
    border-bottom: 1px solid $color-secondary-3;
  }

  &__stripe {
    &-container-card {
      display: flex;
      width: 100%;
      justify-content: center;
      cursor: pointer;
      &-item {
        padding: 4px 10px 10px 10px;
        margin: 20px;
        border: 1px solid $color-secondary-3;
        border-radius: 15px;
        text-align: center;
        opacity: 0.5;

        &:hover {
          opacity: 1;
          transition: opacity 0.5s ease-out;
          -webkit-transition: opacity 0.5s ease-out;
          -moz-transition: opacity 0.5s ease-in-out;
        }

        &.selected {
          border: 1px solid $color-principal;
          background-color: $color-principal-05;
          opacity: 1;
        }

        &-text {
          font-family: $font-secondary;
          font-size: 12px;
          color: $color-secondary-3;
          margin-bottom: 0px;
        }
      }
    }
  }
}
