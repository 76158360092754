@import "./../../styles/shared.scss";

.buy-book {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: $breakpoint-tablet) {
    padding-top: .5rem;
  }

  &__title {
    font-family: $font-primary;
    font-size: 28px;
    color: $color-secondary-2;
  }
  &__container-card {
    display: flex;
    width: 100%;
    justify-content: center;
    cursor: pointer;

    @media (max-width: $breakpoint-tablet) {
      gap: 1rem;
    }

    &-item {
      width: 20%;
      padding: 4px 10px 10px 10px;
      margin: 20px;
      border: 1px solid $color-secondary-3;
      border-radius: 15px;
      text-align: center;
      opacity: 0.5;

      @media (max-width: $breakpoint-tablet) {
        width: 100%;
        max-width: max(30dvw, 150px);
        margin: 1.5rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
      }

      &:hover {
        opacity: 1;
        transition: opacity 0.5s ease-out;
        -webkit-transition: opacity 0.5s ease-out;
        -moz-transition: opacity 0.5s ease-in-out;
      }

      &.selected {
        border: 1px solid $color-principal;
        background-color: $color-principal-05;
        opacity: 1;
      }

      &-text {
        font-family: $font-secondary;
        font-size: 12px;
        color: $color-secondary-3;
        margin-bottom: 0px;
      }
    }
  }
  &__container-card-form {
    display: flex;
    width: 100%;
    justify-content: center;

    form {
      width: 100% !important;
      padding-left: 50px;
      padding-right: 50px;

      @media (max-width: $breakpoint-tablet) {
          padding-left: 0px;
          padding-right: 0px;
      }
    }
  }
  &__form {
    width: 100%;
    margin-left: 15px;

    &-label {
      font-family: $font-primary;
      font-size: 12px;
      text-transform: uppercase;
      color: $color-secondary-2;
      letter-spacing: 7%;
    }
    &-input{
      font-family: $font-secondary;
      font-size: 14px;
      color: $color-secondary-2;
      border: 1px solid $color-secondary-3;
      border-radius: 32px;
      width: 95%;
      padding: 10px;

    }
    &-container-security{
      display: flex;
      margin-bottom: 40px;
      margin-top: 20px;

    }
  }
  &__text-download {
    font-family: $font-secondary;
    font-size: 16px;
    color: $color-secondary-3;
    margin-bottom: 0px;
    text-align: center;
    padding: 20px 50px;
  }
  &__container-button{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.buy-book-backdrop{
  z-index: 1055;
}

.modal {
  &.buy-book-modal {
    max-height: 100dvh;
    &.show {
      .modal-content {
        background-color: rgba(255, 255, 255, 1);
      }
    }
  }
}

.not-card{
  font-family: $font-secondary;
  font-size: 16px;
  color: $color-secondary-3;
}
