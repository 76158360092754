@import "./../../styles/shared.scss";

.politica-privacidad{
  margin: 50px 80px 40px 80px;

  h1 {
    font-size: 26px;
    background-color: $color-principal;
    color: $color-secondary-7;
    margin: 40px 0 24px 0;
    padding: 8px;
  }

  ul{
    margin: 0;

    li{
      margin: 0;
    }
  }

  ol{ 
    padding: 0;
  }

  strong,
  strong span{ 
    font-weight: 700; 

  }
}