@import "./../../../styles/shared.scss";

.edit-summary {
  height: 90%;
  background-color: $color-secondary-7;
  padding: 40px;
  border-radius: 10px;
  margin-bottom: 50px;
  max-height: 600px;
  overflow: hidden;
  overflow-y: scroll;
  ::placeholder {
    color: $color-secondary-3;
  }

  &__name-book {
    margin-bottom: 50px;
    font-family: $font-primary;
    font-size: 45px;
    font-weight: 400;
    color: $color-tertiary-1;
    width: 80%;
  }

  &__form-label {
    font-family: $font-primary;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: $color-secondary-2;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    &:last-of-type {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
  &__form-textarea {
    border-radius: 10px;
    border: 1px solid $color-secondary-2;
    margin-top: 10px;
    padding: 8px 10px 8px 15px;
    width: 100%;
    min-height: 150px;
    cursor: pointer;
    overflow: auto;
    box-sizing: border-box;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
  }
  &__title-tag {
    font-family: $font-primary;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: $color-secondary-2;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    margin-top: 15px;
    margin-bottom: 12px;
  }
  &__container-summary {
    display: flex;
    flex-direction: column;
  }
}

:-webkit-scrollbar {
  height: 12px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}
