@import "./../../styles/shared.scss";

.form-cta {
  background-color: $color-principal;
  padding: 8rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: -81px;
  background-image: url("../../styles/images/formas.svg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% auto;

  @media (max-width: $breakpoint-tablet) {
    padding: 7rem 0 6rem;
    background-size: auto 100%;
    background-position: top right;
  }

  &__title {
    font-family: $font-primary;
    font-size: 2.8125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 108.5%; /* 3.05156rem */
    max-width: 30rem;
    text-align: center;
    color: $color-secondary-7;
    margin-bottom: 2rem;

    @media (max-width: $breakpoint-tablet) {
      font-size: 1.875rem;
    }
  }
}
