@import "./../../styles/shared.scss";

.landing-hero {
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: 1fr 5fr 1fr 4fr 1fr;

  &__description {
    display: flex;
    flex-direction: column;
    grid-column: 2 / 3;

    div:first-child {
      font-family: $font-secondary;
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      padding: 0 0 24px 0;
      border-bottom: 1px solid #979797;
    }

    div:last-child {
      font-family: $font-primary;
      font-weight: 400;
      padding: 24px 0 0 0;

      p {
        margin-bottom: 0;
      }

      p:first-child {
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 8px;
      }

      p:last-child {
        font-size: 16px;
        line-height: 23px;
      }
    }
  }

  &__price {
    grid-column: 4 / 5;
    padding: 24px;
    background: #F7F7F7;
    align-self: start;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    color: #323232;
    font-family: $font-secondary;

    span:first-child {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }

    h1 {
      margin: 8px 0 24px 0;
      font-weight: 600;
    }

    span:last-child {
      color: #656565;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .landing-hero {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    gap: 32px;

    &__description {
      div:first-child {
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        padding: 0 0 16px 0;
      }

      div:last-child {
        padding: 16px 0 0 0;

        p {
          margin-bottom: 16px;
        }
      }
    }

    &__price {
      padding: 24px;
    }
  }
}
