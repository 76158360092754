@import "./../../../styles/shared.scss";

.exit-page {
  // padding: 10px;
  &__title {
    font-family: $font-primary;
    color: $color-secondary-1;
    font-size: 26px;
    line-height: 38px;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  &__container-button {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  &__button-continue {
    margin-top: 20px;
    border-radius: 32px;
    border: 1px solid $color-principal;
    color: $color-principal;
    background-color: $color-secondary-7;
    padding: 15px;
    font-family: $font-secondary;
    cursor: pointer;
  }
  &__button-exit {
    margin-top: 5px;
    border: none;
    color: $color-secondary-3;
    background-color: $color-secondary-7;
    padding: 15px;
    margin-bottom: 0px;
    cursor: pointer;
  }
}
