@import "./../../../styles/shared.scss";

.shopping{
    background-color: $color-secondary-7;
    border-radius: 10px;
    padding: 40px;
    margin-bottom: .5rem;

    @media (max-width: $breakpoint-tablet) {
        padding: 1.5rem 1rem;
    }

    &--margin-top {
        margin-top: 50px;
    }

    &__container {
        &-title{
            font-family: $font-primary;
            font-size: 22px;
            font-weight: 400;
            color: $color-secondary-2;
            line-height: 29px;
            margin-bottom: 20px;
        }

    }
}

.purchased-course {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0;
    border-bottom: 1px solid $color-secondary-4;
    &:last-child {
        border-bottom: none;
    }

    @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
        align-items: flex-start;
        gap: 1.5rem
    }

    &__info {
        flex-grow: 1;
        padding-left: 1.5em;
        
        &-title {
            font-family: $font-primary;
            font-size: 1.375rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.8125rem;
            color: $color-secondary-2;
            margin-bottom: 0.25rem;
        }

        &-professor {
            font-family: $font-secondary;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.75rem;
            color: $color-secondary-3;
            margin-bottom: 0;
        }

        &-dates {
            font-family: $font-secondary;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.25rem;
            color: $color-secondary-2;

            span {
                padding-left: .25rem;
            }
        }
    }

    &__button {
        .btn.btn-white {
            padding: .5rem 1.5rem;

            @media (max-width: $breakpoint-tablet) {
                background-color: $color-secondary-1;
                border: 1px solid $color-secondary-1;
                color: $color-secondary-7;
                display: flex;
                align-items: center;

                .btn-icon {
                    width: .75rem;
                    height: .75rem;
                    fill: $color-secondary-7;

                    path {
                        fill: $color-secondary-7;
                    }

                    svg {
                        width: .75rem;
                        height: .75rem;
                    }
                }
            }


            @media (min-width: $breakpoint-tablet) {
                .icon {
                    display: none;
                }
            }
        }

    }
}
