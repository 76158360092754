@import "./../../../styles/shared.scss";

.tags {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  width: calc(100% - 2rem);

  &__title-content {
    margin-bottom: 5.5rem;

    @media (min-width: $breakpoint-tablet) {
      margin-bottom: 3.5rem
    }
  }
  &__title {
    width: 100%;
    font-family: $font-primary;
    color: $color-secondary-2;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.8125rem;
    text-align: center;

    @media (min-width: $breakpoint-tablet) {
      font-size: 2.1875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.625rem;
    }
  }
  &__text {
    font-family: $font-secondary;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: $color-secondary-3;
    margin-bottom: 30px;
  }
  &__container-tag {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    @media (min-width: $breakpoint-tablet) {
      max-width: calc(100% - 15rem);
      margin: 0 auto;
    }

    &-tag {
      border: 1px solid $color-secondary-2;
      border-radius: 2rem;
      background-color: $color-secondary-7;
      font-family: $font-secondary;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      color: $color-secondary-2;
      cursor: pointer;
      height: 3.5rem;
      width: calc(100% - 1rem);
      margin-bottom: 1rem;

      &--selected {
        background-color: $color-secondary-2;
        color: $color-secondary-7;
      }
    }

    .genres {
      width: 100%;
    }
  }
  &__container-button {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  &__genres-selector {
    flex: 1;
  }

  &__button {
    margin-top: 20px;
    border-radius: 32px;
    border: 1px solid $color-principal;
    color: $color-principal;
    background-color: $color-secondary-7;
    padding: 10px 30px 10px 30px;
    font-family: $font-secondary;
    cursor: pointer;
  }

  &__button-return {
    margin-top: 5px;
    border: none;
    color: $color-secondary-3;
    background-color: $color-secondary-7;
    padding: 15px;
    margin-bottom: 0px;
    cursor: pointer;
  }

  &__modal {
    display: flex;
    flex-direction: column;
    @media (min-width: $breakpoint-tablet) {
      display: inherit;
    }

    .modal-dialog-centered {
      @media (max-width: $breakpoint-tablet) {
        margin: 0;
      }
    }

    .modal-content {
      @media (max-width: $breakpoint-tablet) {
        min-height: 100vh;
        border-radius: 0;
      }
    }

    .modal-body {
      position: relative;
      flex: 1 1 auto;
      padding: 5rem;
      @media (max-width: $breakpoint-tablet) {
        padding: 3rem 1rem;
      }
    }
  }
}
