@import "../../../../styles/shared.scss";

.more-genres {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  background-color: $color-secondary-7;

  @media (min-width: $breakpoint-tablet) {
    margin: 70px;
    background-color: $color-secondary-7;
  }
  &__title {
    font-family: $font-primary;
    font-size: 35px;
    font-weight: 400;
    line-height: 42px;
    color: $color-secondary-1;
    padding-top: 20px;
    text-align: center;
    @media (min-width: $breakpoint-tablet) {
      font-size: 60px;
      line-height: 72px;
      padding-top: 30px;
    }
  }

  &__subtitle {
    margin-bottom: 60px;
    text-align: center;
    font-family: $font-secondary;
    font-size: 18px;
    font-weight: 300;
    line-height: 26px;
    color: $color-secondary-1;
    @media (min-width: $breakpoint-tablet) {
      font-size: 18px;
      line-height: 30px;
    }
  }

  &__genres {
    padding-left: 10%;
    padding-right: 10%;
    display: none;

    @media (min-width: $breakpoint-tablet) {
      display: inherit;
    }
  }

  &__marquee {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 100vw;

  }

  &__genres-mobile {
    display: flex;
    justify-content: flex-start;
    animation-duration: 60s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-delay: 1s;

    @media (min-width: $breakpoint-tablet) {
      display: none;
    }

    &:nth-child(1) {
      animation-name: marquee-row-1;
    }
    &:nth-child(2) {
      align-self: flex-end;
      animation-name: marquee-row-2;
    }
    &:nth-child(3) {
      animation-name: marquee-row-1;
    }

  }
}


@keyframes marquee-row-1 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-50%));
  }
}

@keyframes marquee-row-2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(50%));
  }
}
