@import "../../styles/shared.scss";

.landing-carousel {
  --transition-duration: 0.5s;
  --transition-function: ease;

  align-self: stretch;

  &__link {
    text-decoration: none;
  }

  h1 {
    color: #323232;
    font-family: $font-primary;
    font-size: 45px;
    font-weight: 400;
    margin: 0 0 32px 16px;
  }

  ul {
    margin: 0 8px;
    padding: 0;
    display: flex;
    gap: 8px;

    li {
      background: white;
      flex: 1;
      border-radius: 10px;
      border: 1px solid #323232;
      aspect-ratio: 0.88 / 1;
      display: flex;
      flex-direction: column-reverse;
      padding: 24px;
      position: relative;
      transition: background-color var(--transition-duration) var(--transition-function);

      svg {
        position: absolute;
        right: 40px;
        top: 40px;
        width: 14px;
        height: 14px;
        opacity: 0;
        transition: top var(--transition-duration) var(--transition-function),
                    right var(--transition-duration) var(--transition-function),
                    height var(--transition-duration) var(--transition-function),
                    width var(--transition-duration) var(--transition-function);

        path {
          fill: #323232;
          stroke: #323232;
          transition: all var(--transition-duration) var(--transition-function);
        }
      }

      span {
        word-spacing: 100vw;
        color: #323232;
        font-family: $font-primary;
        font-size: 35px;
        font-weight: 400;
        line-height: 42px;
        transition: all var(--transition-duration) var(--transition-function);
      }
    }

    li:hover {
      background: #323232;

      span {
        color: white;
      }

      svg {
        right: 24px;
        top: 24px;
        height: 24px;
        width: 24px;
        opacity: 1;

        path {
          fill: white;
          stroke: white;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .landing-carousel {
    h1 {
      font-size: 28px;
      margin: 0 16px 16px 16px;
    }

    ul {
      margin: 0 16px;
      overflow-x: scroll;

      li {
        aspect-ratio: 264 / 344;

        svg {
          right: 24px;
          top: 24px;
          width: 24px;
          height: 24px;
          opacity: 1;

          path {
            fill: #323232;
            stroke: #323232;
          }
        }
      }

      li:hover {
        background: white;

        span {
          color: #323232;
        }

        svg {
          path {
            stroke: #323232;
            fill: #323232;
          }
        }
      }
    }
  }
}
