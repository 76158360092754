@import "./../../../styles/shared.scss";

.greetings {
  &__container {
    background-image: url(../../../../public/images/cabecera.svg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 330px;
    margin: 5px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: $breakpoint-tablet) {
      margin: 0;
      background-image: url(../../../../public/images/silla.svg);
      background-position: bottom center;
      background-size: auto 100%;
      background-color: $color-principal;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      width: 100%;
    }

    &-title {
      display: flex;
      align-items: flex-end;
      height: 100%;
      font-family: $font-primary;
      font-size: 80px;
      color: $color-secondary-7;
      line-height: 96px;
      font-weight: 400;
      padding-bottom: 30px;
      padding-left: 30px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 2.8rem;
        padding: 1.5rem;
        line-height: 3.25rem;
      }
    }
    &-img{
      text-align: right;
    }
  }
}
