@import "./../../../styles/shared.scss";

.sinopsis-book {
  &__title {
    font-family: $font-primary;
    font-weight: 400;
    font-size: 45px;
    line-height: 52px;
    color: $color-tertiary-1;

    @media (max-width: $breakpoint-tablet) {
      display: none;
    }
  }
  &__author {
    font-family: $font-primary;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: $color-tertiary-1;
    padding-bottom: 16px;
    padding-top: 8px;
    border-bottom: 1px solid $color-secondary-4;

    @media (max-width: $breakpoint-tablet) {
      display: none;
    }
  }
  &__text {
    font-family: $font-primary;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 0.9375rem;
    color: $color-secondary-3;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    // padding-top: 16px;
  }
  &__sinopsis {
    font-family: $font-secondary;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
    color: $color-secondary-2;
    letter-spacing: 0.02em;
  }
}
