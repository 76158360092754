@import "../../styles/shared.scss";

.course-card {
  margin-bottom: 1.5rem;
  // grid-column: 1;

  &__image {
    width: 100%;
    height: 14rem;
    border-radius: .625rem;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    margin-bottom: 1rem;
    background-color: $color-secondary-1;

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(.9);
      opacity: 0;
      transition: all .1s ease-in-out;
      z-index: 1;
    }

    &-bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: opacity .1s ease-in-out;
    }

    &:hover {
      .course-card__image-bg {
        opacity: .7;
      }
      .icon {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
      }
    }

  }

  &__professor {
    font-family: $font-secondary;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.15625rem;
    color: $color-secondary-2;
    margin-bottom: 1rem;
  }

  &__title {
    font-family: $font-primary;
    font-size: 1.5625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.83588rem;
    color: $color-secondary-1;
    margin-bottom: .5rem;
  }

  &__description {
    font-family: $font-secondary;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5625rem;
    color: $color-secondary-2;
    margin-bottom: 1rem;
  }

  &__info {
    .btn.btn-blackbg {
      display: inline-block;
      padding: .3rem 1.25rem;
      font-weight: bold;

      @media (max-width: $breakpoint-tablet) {
        font-size: 0.875rem;
      }
    }
  }

  &__dates {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-style: normal;
    line-height: 1.25rem;
    color: $color-secondary-2;
    margin-bottom: 1.5rem;

    &-item {
      font-weight: 600;
      margin-right: .25rem;
    }

    &-hours {
      font-weight: 300;
    }

    .icon {
      margin-right: .5rem;
    }
  }
}
