@import "./../../../styles/shared.scss";

.home-public {
  background-color: $color-secondary-6;
  padding-bottom: 100px;
  overflow: hidden;

  &__bg-one{
    background-color: $color-secondary-7;
    @media (min-width: $breakpoint-tablet) {
      margin: 15px;
      background-color: $color-secondary-7;
      border-radius: 10px;
      padding-bottom: 20px;
    }
  }
  &__banner{
    padding-bottom: 100px;
  }


}
