@import "./../../../styles/shared.scss";

.register-user {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: $breakpoint-tablet) {
    width: 100%;
  }
  .input-text {
    margin: 0;
  }

  &__form {
    width: 100%;

    &-label {
      display: block;
      font-family: $font-primary;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.07em;
      color: $color-secondary-2;
      text-transform: uppercase;
      margin-top: 1rem;
      margin-bottom: .5rem;
      &:first-of-type{
        margin-top: 0px;
      }

      @media (max-width: $breakpoint-tablet) {
        margin-bottom: .5rem;
        margin-top: 1rem;
      }
    }

  }
  &__container-btn {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &__btn{
    border-radius: 32px;
    outline: none;
    border: 1px solid $color-secondary-2;
    background-color: $color-secondary-7;
    color: $color-secondary-2;
    padding: 10px 35px;

    cursor: pointer;
  }
  &__btn-return{
    outline: none;
    border: none;
    background-color: $color-secondary-7;
    color: $color-secondary-2;
    margin-bottom: 20px;
    margin-top: 10px;
    cursor: pointer;
  }

  &__error{
    font-family: $font-secondary;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.07em;
    color: red;
    text-align: flex-start;

  }
}
