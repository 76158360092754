@import "./../../styles/shared.scss";

.component-info {
  display: flex;
  font-family: $font-secondary;

  @media (max-width: $breakpoint-tablet) {
    display: none;
  }

  &__title {
    width: calc(100% / 3 * 1);
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.03125rem; /* 162.5% */
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 7rem;
  }

  &__subtitle {
    width: calc(100% / 3 * 2);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.03125rem; /* 162.5% */
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 7rem;
  }

}
