@import "./../../styles/shared.scss";

.course-detail {
  margin-top: 8rem;

  @media (max-width: $breakpoint-tablet) {
    margin-top: 6rem;
    padding: 0 .5rem;
  }

  &__image {
    width: 100%;
    height: 22rem;
    border-radius: .625rem;
    overflow: hidden;
    margin-bottom: 3.5rem;

    @media (max-width: $breakpoint-tablet) {
      flex: 100%;
      max-width: 100%;
      margin-bottom: 2rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 8rem;
    margin-bottom: 8rem;

    @media (max-width: $breakpoint-tablet) {
      display: flex;
      flex-direction: column;
      grid-gap: 2rem;
      margin-bottom: 4rem;
      padding: 0 .5rem;
    }
  }

  &__info {
    grid-column: 1 / 3;
  }

  &__professor {
    margin: 0;
    padding: 0;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.15625rem;
    color: $color-secondary-2;
  }

  &__title {
    margin: 1rem 0 2rem;
    font-family: $font-primary;
    font-size: 2.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.30456rem;

    @media (max-width: $breakpoint-tablet) {
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $color-tertiary-1;
    }
  }

  &__summary {
    background-color: $color-secondary-6;
    height: fit-content;
    padding: 1.5rem;
    border-radius: 0.625rem;
    font-family: $font-secondary;

    &-dates {
      font-size: 2rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 1rem;

      @media (max-width: $breakpoint-tablet) {
        font-size: 2.5rem;
      }

      &-info {
        color: $color-secondary-2;
        font-size: 1rem;
        font-style: normal;
        font-weight: 300;
        line-height: 1.25rem;
        white-space: nowrap;
        margin-bottom: 1.5rem;

        @media (max-width: $breakpoint-tablet) {
          margin-bottom: 0;
        }

        span {
          font-weight: 600;
          white-space: nowrap;
        }
      }
    }

    &-price {
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-top: 1.5rem;
      border-top: 1px solid $color-secondary-4;
      margin-bottom: 1.5rem;

      @media (max-width: $breakpoint-tablet) {
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border-top: none;
        padding-top: 0;
        margin-bottom: 0;
        color: $color-tertiary-1;
      }

      p {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.3125rem;
        color: $color-secondary-2;
        margin-bottom: 0;
      }
    }

    &-btn {
      .btn {
        width: 100%;
      }
    }

    @media (max-width: $breakpoint-tablet) {
      &-buy {
        position: fixed;
        bottom: 1.5rem;
        left: .5rem;
        right: .5rem;
        width: calc(100% - 1rem);
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: rgba(202, 202, 202, 0.50);
        backdrop-filter: blur(10px);
        padding: .5rem .75rem .5rem 1.5rem;
        border-radius: 2rem;
      }
    }
  }

  &__banner {
    margin: 9rem 0;
    padding: 3rem;
    border-radius: .625rem;
    background-color: $color-secondary-6;
    overflow: hidden;
    background-image: url("./../../styles/images/course_contact.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-size: auto 100%;

    @media (max-width: $breakpoint-tablet) {
      background-image: url("./../../styles/images/course_contact_mobile.svg");
      background-size: 100% auto;
      margin: 5rem .5rem;
      padding: 7rem 3rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .btn.btn-orangebg {
        width: unset;
      }
    }

    &-title {
      font-family: $font-primary;
      font-size: 2.8125rem;
      font-style: normal;
      font-weight: 600;
      line-height: 3.05156rem;
      max-width: 30rem;
      margin-bottom: 5rem;

      @media (max-width: $breakpoint-tablet) {
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.03438rem;
        text-align: center;
        margin-bottom: 1.5rem;
      }
    }
  }
}

.related-courses {

  &__header {
    padding: 0 1.5rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $breakpoint-tablet) {
      margin-bottom: 1rem;
      .btn {
        display: none;
      }
    }
  }

  &__title {
    color: $color-tertiary-1;
    font-family: $font-primary;
    font-size: 2.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;

    @media (max-width: $breakpoint-tablet) {
      font-size: 1.75rem;
    }
  }
  
  &__list{
    overflow: hidden;
  }

  .slick-track {
    display: flex;
    gap: .5rem;
  }

  .slick-list {
    overflow: visible;
  }

  &__footer {
    display: none;
    justify-content: center;
    align-items: center;

    @media (max-width: $breakpoint-tablet) {
      display: flex;
      margin-top: 3rem;
    }
  }
}
