@import "./../../styles/shared.scss";

.searcher {
  &__filter {
    display: flex;
    align-items: center;
    margin-top: 6rem;
    margin-bottom: 2rem;
    overflow: hidden;

    @media (max-width: $breakpoint-tablet) {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  }

  &__filter-text {
    margin-bottom: 0;
    font-family: $font-primary;
    font-size: 1.25rem;
    line-height: 1.6875rem;
    font-weight: 400;
    color: $color-secondary-2;
    margin-right: 4rem;

    @media (max-width: $breakpoint-tablet) {
      display: none;
    }
  }

  &__filter-items {
    flex: 1;
    display: flex;

    @media (max-width: $breakpoint-tablet) {
      overflow-x: auto;
    }
  }

  &__filter-item {
    display: flex;
    align-items: center;
    padding: .5rem 1rem;
    border-radius: 3rem;
    white-space: nowrap;

    &.checked {
      background-color: $color-secondary-6;
    }

    input[type=radio] {
      margin-right: .5rem;
      outline: none;
      appearance: none;
      width: 1rem;
      height: 1rem;
      border-radius: 1rem;
      border: 1px solid $color-secondary-3;
      position: relative;

      &:after {
        content: '';
        display: block;
        width: .62rem;
        height: .62rem;
        border-radius: 1rem;
        background-color: $color-principal;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1.1);
        opacity: 0;
        transition: all .2s ease-in;
      }

      &:checked {
        border-color: $color-principal;
        &:after {
          opacity: 1;
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }
  }

  &__info {
    background-color: $color-secondary-6;
    border-radius: 0.625rem;
    padding: 7.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__info-text {
    width: 18rem;
    text-align: center;
    font-family: $font-primary;
  }

  .search-loader{
    margin: 0 auto;
  }
}
