@import "./../../styles/shared.scss";

a:has(.genre-item){
  text-decoration: none;
}
.genre-item {
  padding: 5px 20px;
  margin: 5px 5px 5px 0;
  border: 1px solid  $color-secondary-3;
  border-radius: 25px;
  line-height: 24px;
  color: $color-secondary-3;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &.disabled{
    border-color: $color-secondary-3;
    color: $color-secondary-3;
    cursor: default;
  }

  @media (max-width: $breakpoint-tablet) {
    margin: 0;
    padding: 12px 20px;

    &--v2 {
      padding: 6px 20px;
      line-height: 20px;
      font-size: 0.875rem;
    }

    &--v3 {
      padding: 4px 16px;
      line-height: 20px;
      font-size: 0.875rem;
    }
  }

  &--is-selected {
    background-color: $color-secondary-1;
    border: 1px solid  $color-secondary-1;
    color: $color-secondary-7 !important;
  }

  &--xsmall {
    font-family: $font-secondary;
    font-weight: 400;
    font-size: 14px;

    @media (max-width: $breakpoint-tablet) {
      font-size: .75rem;
    }
  }

  &--small {
    font-family: $font-secondary;
    font-weight: 400;
    font-size: 10px;

    @media (max-width: $breakpoint-tablet) {
      font-size: 1.12rem;
    }
  }

  &--medium {
    font-family: $font-secondary;
    font-weight: 400;
    font-size: 16px;
    color: $color-secondary-2;
  }

  &--large {
    font-family: $font-primary;
    font-weight: 400;
    font-size: 28px;
    color: $color-secondary-1;
    border: 2px solid  black;
    padding: 10px 30px;
    margin: 5px;
    &:hover{
      background-color: $color-secondary-1;
      color: $color-secondary-7;

      &.disabled{
        background-color: transparent;
        color: $color-secondary-3;
      }
    }
  }
}
