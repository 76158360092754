@import "../../styles/shared.scss";

.modal-form-success {
  text-align: center;
  padding: 2rem 3rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-family: $font-primary;
    font-size: 2.1875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.625rem;
    margin-bottom: 1rem;
  }

  &__content {
    font-family: $font-secondary;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
    margin-bottom: 1rem;
  }

  .btn {
    width: unset;
  }
}
