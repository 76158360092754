@import "./../../../styles/shared.scss";

.summary {
  background-color: $color-secondary-7;
  padding: 40px;
  border-radius: 10px;
  max-height: 600px;
  overflow: hidden;
  overflow-y: scroll;
  ::placeholder {
    color: $color-secondary-3;
  }

  @media (max-width: $breakpoint-tablet) {
    max-height: unset;
    padding: 2.5rem 1rem;
  }

  &__name-book {
    margin-bottom: 10px;
    font-family: $font-primary;
    font-size: 45px;
    font-weight: 400;
    color: $color-tertiary-1;
    width: 80%;
    @media (max-width: $breakpoint-tablet) {
      width: 100%;
      text-align: center;
    }
  }

  &__subtitle-book {
    margin-bottom: 50px;
    font-family: $font-primary;
    font-size: 24px;
    font-weight: 200;
    color: $color-tertiary-1;
    width: 80%;
    @media (max-width: $breakpoint-tablet) {
      width: 100%;
      text-align: center;
    }
  }

  &__title {
    font-family: $font-primary;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: $color-secondary-2;
    letter-spacing: 0.07em;
    text-transform: uppercase;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-secondary-3;
    margin-top: 15px;
  }
  &__container-summary {
    margin-top: 40px;
  }

  &__price {
    font-family: $font-secondary;
    color: $color-secondary-2;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
  }
  &__isbn {
    font-family: $font-secondary;
    color: $color-secondary-2;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
  }
  &__summary {
    font-family: $font-secondary;
    color: $color-secondary-2;
    font-size: 14px;
    margin-top: 10px;
    border-bottom: 1px solid $color-secondary-3;
    padding-bottom: 25px;
  }
}


:-webkit-scrollbar {
  height: 12px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}
