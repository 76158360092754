@import "./../../../styles/shared.scss";

.log-off {

  @media (max-width: $breakpoint-tablet) {
    &__modal {
      margin: 0;
      align-items: flex-end;
      min-height: 100%;

      .modal-content {
        border-radius: 0;
        border: none;
      }

      .modal-body {
        padding: 1rem 1rem 2rem;
      }

      .btn-orange {
        background-color: $color-principal;
        color: $color-secondary-7;
      }
    }
  }

  &__title {
    font-family: $font-primary;
    color: $color-secondary-1;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    display: flex;
    flex-direction: column;

    @media (max-width: $breakpoint-tablet) {
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.75rem;
      text-align: left;
      color: $color-secondary-3;
    }
  }
  &__container-button {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;

    @media (max-width: $breakpoint-tablet) {
      text-align: left;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  &__button-logoff {
    margin-top: .5rem;
    border: none;
    color: $color-principal;
    background-color: $color-secondary-7;
    padding: 1rem;
    margin-bottom: 0;
    cursor: pointer;

    @media (max-width: $breakpoint-tablet) {
      padding: 1rem 0;
    }
  }
}
