@import "../../../../styles/shared.scss";

.reason {
  margin: 40px 0px;
  border-radius: 10px;
  padding: 100px 50px;
  display: flex;
  flex-direction: column;
  background-color: $color-secondary-7;

  @media (min-width: $breakpoint-tablet) {
    margin: 40px 0px;
  }

  &__border {
    border-top: 3px solid $color-principal;
    width: 10%;
    padding-bottom: 20px;

    @media (min-width: $breakpoint-tablet) {
      width: 5%;
    }
  }


  &__container-containers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;

    @media (min-width: $breakpoint-tablet) {
      padding-left: 110px;
      padding-right: 110px;
    }
    @media (min-width: 1500px) {
      padding-left: 250px;
      padding-right: 250px;
    }
  }

  &__container-monse {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    @media (min-width: $breakpoint-tablet) {
      display: flex;
      flex-direction: row;
      padding-bottom: 30px;
    }

    &-section-text {
      margin-left: 0px;
      padding: 20px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (min-width: $breakpoint-tablet) {
        margin-left: 80px;
        padding: 40px;
      }
    }
    &-title {
      color: $color-secondary-1;
      font-family: $font-secondary;
      font-size: 35px;
      font-weight: 600;
      line-height: 42px;
      @media (min-width: $breakpoint-tablet) {
        width: 90%;
        font-size: 46px;
        font-weight: 600;
        line-height: 51px;
      }
    }
    &-text {
      color: $color-secondary-1;
      font-family: $font-secondary;
      font-size: 18px;
      font-weight: 300;
      line-height: 30px;
    }
    &-section-image {
      margin: 5px;
      &-img {
        max-width: 423px;
        max-height: 423px;
      }
    }
    &-icons-list {
      display: flex;
      flex-direction: row;
      padding-left: 0px;
      &-item {
        margin-right: 10px;
      }
    }
  }

  &__container-nestor {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 10px;

    @media (min-width: $breakpoint-tablet) {
      display: flex;
      flex-direction: row;
      padding-bottom: 30px;
    }
    &-section-text {
      margin-right: 0px;
      padding: 20px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (min-width: $breakpoint-tablet) {
        margin-right: 80px;
      }
    }
    &-title {
      color: $color-secondary-1;
      font-family: $font-secondary;
      font-size: 35px;
      font-weight: 600;
      line-height: 42px;
      @media (min-width: $breakpoint-tablet) {
        width: 90%;
        font-size: 46px;
        font-weight: 600;
        line-height: 51px;
      }
    }
    &-text {
      color: $color-secondary-1;
      font-family: $font-secondary;
      font-size: 18px;
      font-weight: 300;
      line-height: 30px;
    }
    &-section-image {
      margin: 5px;
      &-img {
        max-width: 423px;
        max-height: 423px;
      }
    }
    &-icons-list {
      display: flex;
      flex-direction: row;
      padding-left: 0px;
      &-item {
        margin-right: 10px;
      }
    }
  }
}
