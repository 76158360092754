@import "./../../styles/shared.scss";

.icon {
  vertical-align: middle;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    display: block;
  }

  &.disabled {
    opacity: 0.3;
  }

  &--xsmall,
  &--xsmall svg {
    width: .5rem;
    height: .5rem;
  }

  &--small,
  &--small svg {
    width: 1rem;
    height: 1rem;
  }

  &--medium,
  &--medium svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  &--medium2,
  &--medium2 svg {
    width: 2rem;
    height: 2rem;
  }

  &--large,
  &--large svg {
    width: 2.5rem;
    height: 2.5rem;
  }

  &--xlarge,
  &--xlarge svg {
    width: 4.5rem;
    height: 4.5rem;
  }

  &--background-1 {
    background-color: $color-secondary-7;
    border: 1px solid $color-secondary-7;
    border-radius: 50px;
    svg {
      transform: scale(.9);
    }
  }

  &--background-2 {
    background-color: $color-secondary-2;
    border-radius: 50%;
    svg {
      transform: scale(0.45);
    }
  }

  &--background-3 {
    background-color: $color-secondary-7;
    border: 2px solid rgba(181, 179, 179, 0.83);
    border-radius: 50%;
    svg {
      transform: scale(0.6);
    }
  }

  &--background-4 {
    background-color: $color-secondary-1;
    border-radius: 50px;
    svg {
      transform: scale(0.6);
    }
  }

  &--background-5 {
    background-color: $color-principal;
    border-radius: 50px;
    svg {
      transform: scale(0.6);
    }
  }

  &--background-6 {
    background-color: $color-secondary-7;
    border-radius: 50%;
    svg {
      transform: scale(0.8);
    }
  }

  &--border-1 {
    &:hover {
      background-color: $color-secondary-7;
      border: 1px solid rgba(181, 179, 179, 0.83);
      border-radius: 50%;
      box-shadow: 0px 0px 6px $color-tertiary-6;
    }
  }

  &--border-2 {
    background-color: rgba(255, 255, 255, 0.373);
    border: 1px solid rgba(140, 140, 140, 0.83);
    border-radius: 50px;
    svg {
      transform: scale(0.8);
    }
  }

  &--color-principal {
    fill: $color-principal;
    stroke: $color-principal;

    path {
      fill: $color-principal;
      stroke: transparent;
    }
  }

  &--color-principal-border {
    fill: $color-principal;
    background-color: $color-secondary-7;
    border: 1px solid $color-principal;
    border-radius: 50%;

    path {
      fill: $color-principal;
    }
  }

  &--color-principal-border-transparent {
    fill: $color-principal;
    background-color: transparent;
    border: 2px solid $color-principal;
    border-radius: 50%;

    path {
      fill: $color-principal;
    }
  }

  &--color-secondary-6 {
    fill: $color-secondary-6;

    path {
      fill: $color-secondary-6;
    }
  }
  &--color-secondary-7 {
    fill: $color-secondary-7;

    path {
      fill: $color-secondary-7;
    }
  }

  &--color-secondary-3 {
    fill: $color-secondary-3;

    path {
      fill: $color-secondary-3;
    }
  }
  &--color-secondary-2 {
    fill: $color-secondary-2;

    path {
      fill: $color-secondary-2;
    }
  }
  &--color-secondary-1 {
    fill: $color-secondary-1;

    path {
      fill: $color-secondary-1;
    }
  }
}
