@import "./../../styles/shared.scss";

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  top: 10px;
  z-index: 999;
  padding-left: 2px;
  padding-right: 2px;
  padding-right: 10px;

  @media (max-width: $breakpoint-tablet) {
    display: none;
  }

  &__input-container {
    position: relative;
    cursor: pointer;
    opacity: 0;
    transform-origin: left;
    width: 0;
    text-align: left;

    .icon:first-child {
      position: absolute;
      box-sizing: border-box;
      top: 50%;
      left: 8px;
      transform: translateY(-47%);
      cursor: pointer;
    }

    .icon:last-child {
      position: absolute;
      box-sizing: border-box;
      top: 50%;
      right: 8px;
      transform: translateY(-47%);
      cursor: pointer;
    }
  }

  &__input-container--show {
    opacity: 1;
    transform: scaleX(1);
    transform: translateX(-8px);
    width: 100%;
  }

  &__icon-search {
    cursor: pointer;
  }

  &__input {
    width: 0; /* Cambiar el valor inicial a 0 */
    transition: width 0.5s ease;
    border-radius: 32px;
    border: none;
    background-color: $color-secondary-7;
    padding: 8px;
    cursor: pointer;
    outline: none;
    box-sizing: border-box;
    padding-left: 42px;
    padding-right: 32px;

    &--show {
      width: 100%;
    }
  }

  &__links {
    padding-left: 25px;
    padding-right: 40px;
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
  }

  &__full{
    position: absolute;
    top: 10px;
    left: 20px;
    width: calc(100% - 40px);
    border-radius: 34px;
    padding-top: 111px;
    background-color: $color-secondary-5;
    z-index: 0;

    &__link{
      font-size: 20px;
      color: $color-secondary-1;
      font-weight: 400;
      text-decoration: none;
      -webkit-transition: color 0.1s; /* For Safari 3.0 to 6.0 */
      transition: color 0.1s;
      white-space: nowrap;

      @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
      }

      @keyframes fadeOut {
        0% { opacity: 1; }
        100% { opacity: 0; }
      }

      span{
        margin-left: -24px;
        transition: 0.5s;
      }

      .icon{ 
        animation: fadeOut 0.1s;
        opacity: 0;
        left: -24px;
      }

      &:hover{
        color: $color-principal;

        span { margin-left: 0; }

        .icon{ 
          animation: fadeIn 0.3s;
          opacity: 1;
        }
      }
    }

    @media (min-width: $breakpoint-desktop) {
      &__link{
        font-size: 24px;
      }
    }

    @media (min-width: $breakpoint-desktop-laptop) {
      &__link{
        font-size: 35px;
      }
    }
  }

  &__left {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    background-color: $color-secondary-5;
    border-radius: 32px;
    margin-top: 10px;
    margin-left: 20px;
    padding-left: 10px;
    padding-right: 20px;
    transform-origin: left;
    transition: transform 0.5s ease;
    z-index: 1;

    &--show {
      padding-right: 5px;
    }

    &-img {
      width: 40px;
    }

    &-text {
      font-family: $font-secondary;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 0px;
      color: $color-secondary-2;
      text-decoration: none;

      &:hover {
        color: $color-principal;
      }

      &--active {
        text-decoration: underline;
      }
    }
  }
  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: $color-secondary-5;
    border-radius: 32px;
    margin-top: 10px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    z-index: 1;
    &-publish {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      background-color: $color-principal;
      border-radius: 32px;
      border: none;
      padding: 10px;
      width: 70%;
      color: $color-secondary-6;
      text-decoration: none;
      &:hover {
        color: $color-secondary-6;
      }
    }
    &-profile {
      text-decoration: none;
      border: none;
      margin-left: 10px;
      cursor: pointer;
      outline: none;
    }
  }
}
