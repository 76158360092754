@import "./../../styles/shared.scss";

.searcher {
  &__input-wrapper {
    display: flex;
    justify-content: center;
    .input-search__container {
      width: calc(100% - 2rem);
      max-width: 42rem;
    }
  }
}

.container {
  padding: 0;
}
