@import "./../../../styles/shared.scss";

.select-genres {
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 60px;
  padding-right: 60px;

  @media (max-width: $breakpoint-tablet) {
    padding-left: 0;
    padding-right: 0;
  }

  &__text {
    font-family: $font-secondary;
    text-align: center;
    font-size: 20px;
    color: $color-tertiary-1;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    @media (min-width: $breakpoint-tablet) {
      font-size: 25px;
      }
  }

  &__container-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }

  &__btn-return{
    outline: none;
    border: none;
    background-color: $color-secondary-7;
    color: $color-secondary-2;
    margin-bottom: 30px;
    margin-top: 0px;
    cursor: pointer;
  }
}

.w-50 {
  width: 37% !important;
}