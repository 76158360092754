@import "../../../../styles/shared.scss";

.last-news {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $color-secondary-7;
  @media (min-width: $breakpoint-tablet) {
    background-color: none;
  }
  &__title {
    font-family: $font-primary;
    font-size: 35px;
    font-weight: 400;
    line-height: 46px;
    color: $color-secondary-1;
    padding-top: 80px;
    @media (min-width: $breakpoint-tablet) {
      font-size: 60px;
      line-height: 72px;
      padding-top: 100px;
    }
  }

  &__subtitle {
    margin-bottom: 40px;
    text-align: center;
    font-family: $font-secondary;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    color: $color-secondary-1;
    @media (min-width: $breakpoint-tablet) {
      font-size: 18px;
      line-height: 30px;
    }
  }
  &__button {
    margin-top: 2.5rem;
    padding-bottom: 80px;
    @media (min-width: $breakpoint-tablet) {
      margin-top: 4rem;
      padding-bottom: 0px;
    }

    &-btn-tabletdesktop {
      display: none;
      @media (min-width: $breakpoint-tablet) {
        display: inherit;
      }
    }
    &-btn-mobile {
      display: inherit;
      @media (min-width: $breakpoint-tablet) {
        display: none;
      }
    }
  }
}
