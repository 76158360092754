@import "./../../styles/shared.scss";

.confirmation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: $breakpoint-tablet) {
    padding-bottom: 3rem;
  }

  &__img {
    height: 100vh;
    padding: 20px;
    width: 100%;
    object-fit: cover;
  }

  &__section-text {
    max-width: 600px;
    position: absolute;
    top: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: $breakpoint-tablet) {
      top: 10rem;
      padding: 0 1rem;
    }
  }

  &__title {
    font-family: $font-primary;
    font-size: 35px;
    color: $color-secondary-1;
    padding-top: 20px;
  }
  &__text {
    font-family: $font-primary;
    font-size: 24px;
    color: $color-secondary-2;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
