@import "../../../styles/shared.scss";

.part-title-catalog {
  margin: 10px;

  @media (max-width: $breakpoint-tablet) {
    margin: 0;
  }

  &__container-bg {
    background-color: $color-principal;
    width: 100%;
    padding-top: min(30%, 20rem);
    height: unset;
    border-radius: 10px;
    position: relative;

    @media (max-width: $breakpoint-tablet) {
      padding-top: min(60%, 17rem);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  &__title {
    font-family: $font-primary;
    font-size: 2.75rem;
    font-weight: 300;
    line-height: 3.25rem;
    color: $color-secondary-7;
    letter-spacing: 1px;
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    padding: 0;
    margin-bottom: 0;

    @media (min-width: $breakpoint-tablet) {
      font-size: 4.5rem;
      bottom: 3.5rem;
      left: 2.5rem;
    }
  }
}
