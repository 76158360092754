@import "../../styles/shared.scss";

.accordion-faqs {
  @media (max-width: $breakpoint-tablet) {
    padding: 0 1rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4rem;

    @media (max-width: $breakpoint-tablet) {
      margin-bottom: 1.5rem;
      .btn {
        display: none;
      }
    }
  }

  &__title {
    font-family: $font-primary;
    font-size: 2.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3rem;
    letter-spacing: -0.05625rem;
    margin-bottom: 0;

    @media (max-width: $breakpoint-tablet) {
      font-size: 1.25rem;
      line-height: 1.6875rem;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__footer {
    display: none;
    justify-content: center;
    align-items: center;

    @media (max-width: $breakpoint-tablet) {
      display: flex;
      margin-top: 3rem;
    }
  }
}
