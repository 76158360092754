@import "./../../../styles/shared.scss";

.input-search {
  &__wrapper {
    background-color: #fff;
    padding: .5rem .5rem .5rem 1.5rem;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    gap: .5rem;

    &--transparent {
      background-color: transparent;

      .input-search__input {
        background-color: transparent;
      }
    }

    &--white {
      svg path {
        fill: $color-secondary-7;
      }

      .input-search__input {
        color: $color-secondary-7;

        &::placeholder {
          color: rgba($color-secondary-7, .5);
        }
      }
    }
    button.btn {
      .icon {
        display: none;
        width: 1.5rem;
        height: 1.5rem;
        svg {
          width: 100%;
          height: 100%;
          path {
              fill: #fff;
          }
        }
      }
    }
    @media (max-width: $breakpoint-tablet) {
      .icon.search {
        display: none;
      }

      button.btn {
        background-color: $color-principal;
        width: unset;
        padding: .5rem;
        span {
          display: none;
        }
        .icon {
          display: inline-block;
        }
      }
    }
  }

  &__input {
    width: 100%;
    flex: 1;
    border: none;
    outline: none;
    padding: .75rem 0;
  }

}
