@import "../../../../styles/shared.scss";

.part-title-home {
  margin: 0px;
  &__container-bg {
    background-image: url(../../../../../public/images/bg-mobile.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (min-width: $breakpoint-tablet) {
      background-image: url(../../../../../public/images/bg.svg);
    }
  }
  &__title {
    font-family: $font-primary;
    font-size: 46px;
    font-weight: 400;
    line-height: 51px;
    color: $color-secondary-7;
    padding-top: 165px;
    width: 80%;
    text-align: center;
    @media (min-width: $breakpoint-tablet) {
      font-size: 80px;
      line-height: 96px;
      padding-top: 150px;
      width: 60%;
    }
  }

  &__subtitle {
    width: 75%;
    text-align: center;
    font-family: $font-secondary;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: $color-secondary-7;
    padding-top: 20px;
    @media (min-width: $breakpoint-tablet) {
      font-size: 18px;
      width: 40%;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    padding-top: 26px;
    margin-bottom: 120px;
    @media (min-width: $breakpoint-tablet) {
      padding-top: 20px;
      margin-bottom: 400px;
    }
    &-item-one {
      border-radius: 32px;
      border: 1px solid $color-secondary-1;
      background-color: $color-secondary-1;
    }
    &-item-two {
      margin-left: 10px;
      border-radius: 32px;
      border: 1px solid $color-secondary-1;
      background-color: $color-secondary-1;
      padding-left: 12px;
      padding-right: 12px;
      @media (min-width: $breakpoint-tablet) {
        margin-left: 20px;
      }
    }
  }
}
