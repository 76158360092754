@import "./../../styles/shared.scss";

.result {
  padding: 1.5rem 1rem;
  border-top: 1px solid $color-secondary-5;
  display: flex;
  flex-direction: row;

  &__tag-container {
    max-width: 7.5rem;
    flex: 1;
  }

  &__tag {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    padding: 0.25rem 0.81rem;
    background-color: $color-secondary-5;
    border-radius: 2rem;
    color: $color-secondary-2;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: row;
    padding-top: 1rem;

    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
      gap: .75rem;
    }
  }

  &__data {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-family: $font-primary;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $color-secondary-1;
    margin-bottom: 1rem;

    @media (max-width: $breakpoint-tablet) {
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.6875rem;
      margin-bottom: 0;
    }
  }

  &__subtitle {
    font-family: $font-primary;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6875rem;
    color: $color-secondary-2;

    @media (max-width: $breakpoint-tablet) {
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.4375rem;
    }
  }

  &__info {
    margin-bottom: 0;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.15625rem;
    color: $color-secondary-3;
    display: flex;
    align-items: center;
    gap: 6rem;

    @media (max-width: $breakpoint-tablet) {
      gap: 0;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 1rem;
    }
  }

  &__info-extra {
    color: $color-secondary-4;
  }

  &__info-readTime {
    margin-bottom: 0;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: 0.015rem;
  }

  &__genres-container {
    display: flex;
    justify-content: flex-start;
    gap: .5rem;
    align-items: center;
  }

  &__genres-button {
    padding: .25rem 1rem;
    border: 1px solid $color-secondary-3;
    border-radius: 25px;
    margin-bottom: 0;
    background-color: transparent;

    img {
      margin: 0;
    }
  }

  &__genres-text {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
    color: $color-secondary-3;
  }

  &__price {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: $color-secondary-3;
    margin-bottom: 0;
  }

  &__image {
    width: 100%;
    max-width: min(14.5rem, 30dvw);
    border-radius: .625rem;
    padding: 1rem;
    background-color: $color-secondary-5;
    display: flex;
    justify-content: center;
    overflow: hidden;

    @media (max-width: $breakpoint-tablet) {
      min-width: 8.25rem;
    }
  }

  &__image--full {
    padding: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
