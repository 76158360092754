@import "./../../../styles/shared.scss";

.book-news {
  margin-top: 50px;
  overflow: hidden;
  .slick-track {
    display: flex;
    justify-content: flex-start;
    gap: .5rem;
  }

  @media (max-width: $breakpoint-tablet) {
    margin-top: 5rem;
    padding: 0 .25rem;
  }

  &__container-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 80px 15px 20px 15px;

    @media (max-width: $breakpoint-tablet) {
      padding: 0 .75rem;
    }
  }

  &__title {
    font-family: $font-primary;
    color: $color-secondary-2;
    font-size: 45px;
    font-weight: 400;
    line-height: 41px;
    padding-left: 25px;

    @media (max-width: $breakpoint-tablet) {
      padding: 0;
      color: $color-tertiary-1;
      font-size: 1.75rem;
      line-height: 1.75rem;
      margin-bottom: 1.5rem;
    }
  }

  &__arrows {
    display: none;
    @media (min-width: $breakpoint-tablet) {
      display: inherit;
      margin-top: 7px;
    }
  }

  &__list {
    @media (max-width: $breakpoint-tablet) {
      margin: 0;
      padding: 0;
      width: 100%;
    }
    @media (min-width: $breakpoint-tablet) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 8px;
    }
  }

  &__slider-item {
    padding-right: 0px;

    @media (min-width: $breakpoint-tablet) {
      padding-right: 8px;
    }

    .slick-track {
      display: flex;
      gap: .5rem;
    }

    .slick-list {
      overflow: visible;
    }

    @media (max-width: $breakpoint-tablet) {
      .slick-list {
        overflow: visible;
      }
    }
  }

}
