@import "../../styles/shared.scss";

.bundle {
  &__hero {
    display: flex;
    margin: -200px auto 0 auto;
    background: white;
    box-shadow: 0px 0px 32px 2px rgba(126, 126, 126, 0.10);
    padding: 0;
    border-radius: 10px;
    position: relative;
    z-index: 10;
    width: 80vw;
    margin-bottom: 56px;

    &-details {
      padding: 80px 0 80px 120px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      flex: 1;
      align-self: flex-start;

      & > h1 {
        font-family: $font-primary;
        font-size: 45px;
        font-weight: 400;
        line-height: 53px;
      }

      & > p {
        margin: 0;
        font-family: $font-secondary;
        font-size: 16px;
        line-height: 28px;
        font-weight: 400;
      }

      & > ul {
        padding: 0;
        margin: 0;

        & > li {
          margin-bottom: 8px;
          display: flex;
          gap: 16px;
          align-items: center;
          color: #656565;
          font-size: 16px;
          line-height: 28px;
          font-weight: 400;

          & > span:first-child {
            display: inline-block;
            background: #f7f7f7;
            border-radius: 25px;
            width: 24px;
            height: 24px;
            padding: 4px;

            & > svg {
              vertical-align: top;
              width: 16px;
              height: 16px;

              & > path {
                stroke: #F05A24;
                fill: #F05A42;
              }
            }
          }
        }
      }

      &-pricing {
        font-family: $font-secondary;
        font-size: 40px;
        font-weight: 400;
        color: #979797;

        & > span:first-child {
          text-decoration-line: line-through;
          margin-right: 9px;
        }

        & > span:nth-child(2) {
          color: #323232;
          margin-right: 25px;
        }

        & > span:last-child {
          font-size: 14px;
          line-height: 21px;
        }
      }

      & > .button {
        margin-top: 8px;
        margin-bottom: 0;
      }
    }

    & > svg {
      align-self: center;
      flex: 1;
    }
  }

  &__main {
    &-menu {
      padding: 20px 32px;
      background: #FFFFFF;
      box-shadow: 0px 6px 6px 0px rgba(151, 151, 151, 0.10);
      z-index: 999;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > div {
        flex: 1;

        & > span, & > a {
          color: #323232;
          font-family: $font-secondary;
          font-size: 16px;
          font-weight: 600;
          margin-right: 24px;
          text-decoration: none;
        }

        & > span:first-child {
          margin-right: 16px;
        }

        & > a.link {
          color: #979797;
          font-weight: 500;
        }
      }

      & > .button {
        flex-grow: 0;
        flex-shrink: 0;
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    &-menu.sticky {
      position: fixed;
      top: 0;
      animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
      width: 100%;
    }

    &-menu.sticky + &-courses {
      padding-top: 176px;
    }

    &-courses {
      padding: 96px 128px 100px 128px;

      & > h1 {
        font-family: $font-primary;
        font-size: 45px;
        font-weight: 400;
        margin-bottom: 56px;
      }

      &-course:not(:nth-child(2)) {
        margin-top: 80px;
      }

      &-course:not(:last-child) {
        border-bottom: 1px solid #EFEFEF;
      }
    }

    &-reviews {
      padding: 104px 128px 100px 128px;
      background: #F7F7F7;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 48px;

      &-heading {
        justify-self: flex-start;
        display: flex;
        flex-direction: column;
        gap: 16px;
        color: #323232;
        grid-column: 1 / 2;

        & > h1 {
          font-family: $font-primary;
          font-size: 45px;
          font-weight: 400;
        }

        & > p {
          font-family: $font-secondary;
          font-size: 16px;
          font-weight: 400;
        }
      }

      &-content {
        grid-column: 1 / 4;
        columns: 2 auto;
        column-gap: 8px;
      }
    }

    &-buy {
      padding: 104px 128px 100px 128px;
      background: #F05A24;
      color: white;
      display: grid;
      grid-template-columns: 5fr 5fr 4fr;
      grid-row-gap: 112px;
      position: relative;

      &-heading {
        display: flex;
        flex-direction: column;
        gap: 16px;
        grid-column: 1 / 2;
        grid-row: 1 / 2;

        & > h1 {
          color: #FFFFFF;
          font-family: $font-primary;
          font-size: 45px;
          font-weight: 400;
        }

        & > p {
          color: #FFFFFF;
          font-family: $font-secondary;
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
        }
      }

      &-ca {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        padding-right: 64px;

        & > div:first-child {
          border-bottom: 1px solid white;
          margin-bottom: 21px;

          & > h1 {
            color: #FFF;
            font-family: $font-primary;
            font-size: 22px;
            font-weight: 400;
            line-height: 29px;
          }
        }

        & > div:nth-child(2) {
          margin-bottom: 40px;

          & > span {
            color: #FFFFFF;
            font-family:$font-secondary;
            font-size: 40px;
            font-weight: 600;
          }

          & > span:first-child {
            font-weight: 400;
            text-decoration-line: line-through;
            margin-right: 20px;
            opacity: 0.5;
          }
        }

        & > .button.btn-blackbg.big {
          width: calc(100% - 40px);
          margin-bottom: 7px;
        }

        & > div:last-child {
          color: #FFFFFF;
          text-align: center;
          font-family: $font-secondary;
          font-size: 12px;
          font-weight: 400;
          line-height: 15.5px;

          & > span {
            margin-right: 5px;
          }

          & > a {
            color: #FFFFFF;
            font-family: $font-secondary;
            font-weight: 600;
          }
        }
      }

      &-items {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        padding-left: 64px;

        & > div:first-child {
          border-bottom: 1px solid white;
          margin-bottom: 26px;

          & > h1 {
            color: #FFF;
            font-family: $font-primary;
            font-size: 22px;
            font-weight: 400;
            line-height: 29px;
          }
        }

        & > ul {
          margin: 0;
          padding: 0;

          & > li {
            margin-bottom: 8px;
            display: flex;
            gap: 16px;
            align-items: center;
            color: #FFFFFF;
            font-family: $font-secondary;
            font-size: 16px;
            font-weight: 400;
            line-height: 28px;

            & > span:first-child {
              display: inline-block;
              background: rgba(255, 255, 255, 0.30);
              border-radius: 25px;
              width: 24px;
              height: 24px;
              padding: 4px;

              & > svg {
                vertical-align: top;
                width: 16px;
                height: 16px;

                & > path {
                  stroke: white;
                  fill: white;
                }
              }
            }
          }
        }
      }

      & > svg {
        position: absolute;
        top: -28px;
        right: 0;
      }
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .bundle {
    &__hero {
      margin: -88px 8px 0 8px;
      padding: 32px 16px 24px 16px;
      width: auto;
      position: relative;

      &-details {
        padding: 0;
        display: grid;
        grid-template-columns: 3fr 1fr;
        gap: 16px;

        & > h1 {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
          font-size: 35px;
          line-height: 42px;
        }

        & > p {
          grid-column: 1 / 3;
          grid-row: 2 / 3;
          color: #4D4D4D;
          font-size: 16px;
          line-height: 28px;
        }

        & > ul {
          grid-column: 1 / 3;
          grid-row: 3 / 4;
          margin-top: 8px 0;
        }

        &-pricing {
          grid-column: 1 / 3;
          grid-row: 4 / 5;
          margin-bottom: 16px;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          justify-items: start;

          & > span {
            grid-row: 1 / 2;
            grid-column: 1 / 2;
          }

          & > span:nth-child(2) {
            grid-column: 2 / 3;
          }

          & > span:last-child {
            grid-column: 1 / -1;
            grid-row: 2 / 3;
          }
        }

        & > .button {
          grid-column: 1 / 3;
          grid-row: 5 / 6;
          width: calc(100% - 40px);
        }
      }

      & > svg {
        position: absolute;
        width: 259px;
        right: -100px;
        top: -250px;
      }
    }

    &__main {
      padding-top: 50px;
      &-menu {
        padding: 15px 16px;
        & > div {

          & > a {
            margin-right: 16px;
          }

          & > span {
            display: none;
          }
        }

        & > .button {
          width: auto;
        }
      }

      &-menu.sticky + &-courses {
        padding-top: 152px;
      }

      &-courses {
        padding: 80px 16px;

        & > h1 {
          color: #4C4C4C;
          font-size: 28px;
          margin-bottom: 41px;
        }
      }

      &-reviews {
        padding: 80px 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &-heading {
          color: #4C4C4C;

          & > h1 {
            font-size: 28px;
          }

          & > p {
            color: #323232;
            line-height: 28px;
          }
        }

        &-content {
          overflow: scroll;
          display: flex;
        }
      }

      &-buy {
        padding: 80px 16px;
        grid-row-gap: 31px;

        &-heading {
          grid-column: 1 / 3;
          & > h1 {
            font-size: 28px;
          }
        }

        &-ca {
          grid-column: 1 / -1;
          grid-row: 3 / 4;
          padding-right: 0;

          & > div:nth-child(2) {
            margin-bottom: 31px;
          }

          & > .button {
            margin-top: 0;
          }
        }

        &-items {
          grid-column: 1 / -1;
          grid-row: 2 / 3;
          padding-left: 0;
        }

        & > svg {
          width: 257px;
          top: -340px;
          right: -75px;
        }
      }
    }
  }
}
