@import "./../../../styles/shared.scss";

.headerPublicMobile {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 1rem);
  position: fixed;
  top: .5rem;
  left: .5rem;
  z-index: 999;
  background-color: rgba($color-secondary-5, 1);
  padding: .5rem;
  border-radius: 2rem;

  @media (min-width: 769px) {
    display: none;
  }

  &__left {
    &-img {
      width: 40px;
    }
  }

  &__right {
    padding: .5rem 1rem .5rem
  }

  // Menu container
  &__menu-container {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    max-height: 100dvh;
    overflow-y: auto;
    background-color: $color-secondary-7;
    transform: translateY(2rem);
  }

  &__menu-header {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    height: unset;
    padding: 1.5rem;
    margin-bottom: 2rem;
  }

  &__menu-content {
    padding: 0 2rem;
    margin-bottom: 4rem;
  }

  &__menu-content-title {
    color: $color-secondary-4;
    font-size: .87rem;
    line-height: 1.25rem;
    margin-bottom: 1rem;
  }

  &__menu-list {
    padding: 0;
    margin: 0;
  }

  &__menu-list-item {
    font-family: $font-primary;
    font-size: 2rem;
    margin-bottom: 1rem;
    line-height: 2.62rem;
    a {
      color: $color-secondary-2;
      text-decoration: none;
    }

    &--small {
      margin-bottom: .5rem;
      a {
        color: $color-secondary-3;
      }
    }
  }

  &__menu-search {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
  }

  &__menu-search-input {
    margin-left: .5rem;
    border: none;
    width: 100%;
  }

  ::placeholder {
    color: $color-secondary-4;
  }
}

.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}
.fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
