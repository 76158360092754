@import "../../styles/shared.scss";

.landing-container {
  padding-top: 80px;
  padding-bottom: 144px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 144px;
}

@media (max-width: $breakpoint-tablet) {
  .landing-container {
    padding: 80px 0;
    gap: 80px;
  }
}
