@import "./../../styles/shared.scss";

.book-list-genre {
  &__list {
    padding-top: 9rem;
    padding-bottom: 3rem;

    @media (max-width: $breakpoint-tablet) {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  }

  &__container-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: $breakpoint-tablet) {
      padding: 0 .75rem;
    }
  }

  &__title {
    font-family: $font-primary;
    font-size: 2.8rem;
    line-height: 2.5rem;
    color: $color-secondary-2;
    padding-left: 2rem;

    @media (max-width: $breakpoint-tablet) {
      padding: 0;
      font-size: 1.75rem;
      line-height: 1.75rem;
      margin-bottom: 1.5rem;
      font-weight: 400;
      color: $color-secondary-1;
    }

  }


  &__genres {
    padding: 10px 60px 60px 60px;
  }

  &__list-actions {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
  }
}
