@import "./../../../styles/shared.scss";

.author {
  padding-bottom: 2.5rem;
  &__title {
    font-family: $font-primary;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 0.9375rem;
    color: $color-secondary-3;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    padding-top: 35px;
    padding-bottom: 5px;
  }
  &__container {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
  }
  &__container-image {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 1.5rem;
    &-img {
      width: 5rem;
      height: 5rem;
      border-radius: 120%;
    }
  }
  &__name {
    font-family: $font-primary;
    font-size: 22px;
    font-weight: 500;
    line-height: 29px;
    color: $color-secondary-2;
    margin-bottom: 0.5rem;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__container-info {
    display: flex;
    &-works {
      font-family: $font-secondary;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      color: $color-secondary-2;
      margin-bottom: 0;
    }
    &-publication {
      font-family: $font-secondary;
      font-weight: 300;
      font-size: 14px;
      line-height: 23px;
      color: $color-tertiary-4;
      margin-bottom: 0;
    }
  }
}
