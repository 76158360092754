@import "./../../../styles/shared.scss";

.general-data {
  &__title {
    font-family: $font-primary;
    color: $color-secondary-2;
    line-height: 27.5px;
    margin-bottom: 20px;
  }
  &__personal-info {
    background-color: $color-secondary-7;
    border-radius: 10px;
    margin-top: 50px;
    padding: 40px;

    @media (max-width: $breakpoint-tablet) {
      padding: 1.5rem 1rem;
    }
    &-form {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
      }
      &-izq {
        margin-bottom: 15px;
        margin-right: 40px;
        width: 80%;

        @media (max-width: $breakpoint-tablet) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 0;
        }
      }
      &-dcha {
        margin-bottom: 15px;
        width: 80%;

        @media (max-width: $breakpoint-tablet) {
          width: 100%;
          margin-bottom: 0;
        }
      }
      &-label {
        margin-top: 1rem;
        display: block;
        font-family: $font-primary;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.07em;
        color: $color-secondary-2;
        text-transform: uppercase;
      }
      &-input {
        outline: none;
        width: 90%;
        border-radius: 32px;
        padding-left: 15px;
        padding-top: 8px;
        padding-bottom: 8px;
        border: none;
        border: 1px solid $color-secondary-2;
        margin-bottom: 10px;

        &::placeholder {
          color: $color-secondary-4
        }
      }
    }
  }

  &__payment {
    background-color: $color-secondary-7;
    border-radius: 10px;
    margin-top: 15px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: $breakpoint-tablet) {
      padding: 1.5rem 1rem;
    }
    &-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    &-input {
      outline: none;
      width: 50%;
      border-radius: 32px;
      padding-left: 15px;
      padding-top: 8px;
      padding-bottom: 8px;
      border: none;
      border: 1px solid $color-secondary-2;
      margin-bottom: 10px;
    }
    &-not-payment{
      display: block;
      font-family: $font-secondary;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.07em;
      color: $color-secondary-2;
    }
  }

  &__cash {
    background-color: $color-secondary-7;
    border-radius: 10px;
    margin-top: 15px;
    margin-bottom: 50px;
    padding: 40px;
    width: 100%;

    @media (max-width: $breakpoint-tablet) {
      padding: 1.5rem 1rem;
    }
    &-container {
      width: 100%;
      &-label {
        margin-top: 10px;
        font-family: $font-primary;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.07em;
        color: $color-secondary-2;
        text-transform: uppercase;
      }
      &-input {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &-input{
          width: 70%;
        }
      }
    }
  }

  &__comments {
    font-family: $font-secondary;
    font-size: 14px;
    color: $color-secondary-2;
    line-height: 1.4em;
  }

  .stripeTos{
    border: 1px solid $color-secondary-4;
    background-color: $color-secondary-6;
    font-family: $font-secondary;
    color: $color-secondary-2;
    font-size: 12px;
    text-align: justify;
    padding: 12px 20px;

    a {
      text-decoration: underline;
      color: $color-secondary-2;
      cursor: pointer;
    }
  }

  p.error{
    margin-top: 0.5em;
    margin-left: 1em;
  }
}
