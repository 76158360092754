@import "./../../../styles/shared.scss";

.name-section {
  &__container {
    background-color:  $color-secondary-1;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: min(30%, 20rem);
    margin: 5px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;

    @media (max-width: $breakpoint-tablet) {
      margin: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding-top: min(60%, 17rem);
    }

    &-title {
      font-family: $font-primary;
      font-size: 3rem;
      color: $color-secondary-7;
      line-height: 3.25rem;
      font-weight: 400;
      position: absolute;
      bottom: 2rem;
      left: 2rem;
      padding: 0;
      margin-bottom: 0;

      @media (min-width: $breakpoint-tablet) {
        font-size: 4.5rem;
        bottom: 3.5rem;
        left: 2.5rem;
      }
    }
    &-img{
      text-align: right;
    }
  }
}
