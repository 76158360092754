@import "./../../styles/shared.scss";

.terminos-condiciones{
  margin: 50px 30px 40px 30px;

  h1 {
    font-size: 26px;
    background-color: $color-principal;
    color: $color-secondary-7;
    margin: 40px 0 24px 0;
    padding: 8px;
  }
}