@import "./../../../styles/shared.scss";

.account {
  &__containers {
    background-color: $color-secondary-7;
    border-radius: 10px;
    margin-top: 50px;
    padding: 40px 40px 30px 40px ;

    @media (max-width: $breakpoint-tablet) {
      padding: 1.5rem 1rem;
    }
    &:last-child {
      margin-top: 15px;
      margin-bottom: 50px;
    }
    &-title-section {
      font-family: $font-primary;
      font-size: 22px;
      font-weight: 400;
      color: $color-secondary-2;
      margin-bottom: 0px;
    }
    &-title {
      margin-bottom: 0px;
      margin-top: 30px;
      display: block;
      font-family: $font-primary;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.07em;
      color: $color-secondary-2;
      text-transform: uppercase;
    }
    &-input {
      width: 70%;
      @media (max-width: $breakpoint-tablet) {
        width: 100%;
      }
    }
    &-input-email {
      outline: none;
      width: 50%;
      border-radius: 32px;
      padding-left: 15px;
      padding-top: 8px;
      padding-bottom: 8px;
      border: none;
      border: 1px solid $color-secondary-2;
      margin-bottom: 10px;
    }
    &-password {
      display: flex;
      width: 100%;
      @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
        .input-text {
          margin-bottom: 0;
        }
      }
      &-space {
        width: 50%;
        margin-right: 0px;

        @media (max-width: $breakpoint-tablet) {
          width: 100%;
        }
        &:first-child {
          margin-right: 32px;
          @media (max-width: $breakpoint-tablet) {
            margin-right: 0px;
          }
        }
      }
    }
    &-forget-password{
      font-size: 14px;
      font-family: $font-secondary;
      line-height: 22px;
      color: $color-secondary-2;
      text-decoration-line: underline;
      margin-top: 0px;
      margin-bottom: 0px;
      cursor: pointer;
      @media (max-width: $breakpoint-tablet) {
        margin-top: 1rem;
      }
    }
    &-section-btn{
      display: flex;
      flex-direction: row;
      @media (max-width: $breakpoint-tablet) {
        gap: 1rem;

        .btn {
          padding: 1rem;
        }
      }
    }
    &-button-space{
      width: 80%;
      @media (max-width: $breakpoint-tablet) {
        width: 100%;
      }
  }
    &-button-delete{
      background-color: $color-tertiary-5;
      border: 1px solid $color-secondary-3;
      outline: none;
      font-family: $font-secondary;
      color: $color-secondary-3;
      padding: 15px 20px;
      border-radius: 32px;
      width: 50%;
  }
  }
}
