@import "./../../../../styles/shared.scss";

.author-item-public {
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  cursor: pointer;


  &__container-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    &-img {
      border-radius: 10px;
      min-width: 348px;
      width: 100%;
      height: 358px;
      object-fit: cover;
    }
  }

  &__container-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  &__name-author {
    font-family: $font-primary;
    font-size: 22px;
    font-weight: 400;
    line-height: 25px;
    color: $color-secondary-1;
    margin-top: 15px;
    margin-bottom: 5px;
    text-align: center;
  }
  &__published {
    font-family: $font-secondary;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: $color-secondary-2;
    text-align: center;
  }
}
